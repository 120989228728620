export const isValidCustomAssignmentFile = (file: File) => {
  const fileSizeLimit = 60000000; // ~60mb
  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.svg|\.pdf)$/i;
  const { size, name } = file;
  if (allowedExtensions.exec(name) && size <= fileSizeLimit) {
    return true;
  }

  return false;
};

export const isValidURL = (value: string = '') => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^((https):\/\/)(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*/;
  if (!regex.test(value)) {
    return false;
  }

  return true;
};
