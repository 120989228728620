import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './LeaveNote.scss';
import Typography from '../../components/Typography';
import { ILeaveNote } from './types';
import TextEditor from '../../components/TextEditor';
import Button from '../../components/Button';

function LeaveNote({ onSubmit, header, subHeader }: ILeaveNote) {
  const { t } = useTranslation();
  const [leaveNoteValue, setLeaveNoteValue] = React.useState<string>('');
  return (
    <motion.article
      className="leave-note-wrapper"
      initial={{ opacity: 0, x: '100px' }}
      animate={{ opacity: 1, x: '0px' }}
      exit={{ opacity: 0, x: '100px' }}
      transition={{ duration: 0.5 }}
    >
      <section className="leave-note-header">
        <Typography size={20} weight="600" color="swamp">
          {header}
        </Typography>
        <Typography size={14} withColor="rgba(26, 38, 49, 0.70)">
          {subHeader}
        </Typography>
      </section>
      <section className="leave-note-editor">
        <TextEditor
          onTextValueChange={(note) => {
            setLeaveNoteValue(note);
          }}
          defaultTemplate=""
          parametersValue={[]}
          placeHolderText="LEAVE_A_MESSAGE"
          maxCharacterLimit={560}
          showMaxCharText={false}
        />
      </section>
      <section>
        <Button
          label={t('SUBMIT')}
          variant="primary"
          onClick={() => onSubmit(leaveNoteValue)}
          height="45px"
          width="100%"
          disabled={!leaveNoteValue.replace(/\s/g, '').length}
        />
      </section>
    </motion.article>
  );
}

export default LeaveNote;
