import * as React from 'react';
import { IGetTraitRes } from '../../utilities/hooks/usePersonalInsights/types';

interface IGraphContext {
  traitData: IGetTraitRes;
  onAssignClick: Function;
  userId: string;
}

const GraphDataContext = React.createContext<IGraphContext>(
  {} as IGraphContext,
);

export default GraphDataContext;
