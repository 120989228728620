import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { variants } from '../../shared/constant/Colors';

interface TagProps {
  variant?: keyof typeof variants;
  className?: string;
  onClick?: () => void;
  tooltipId?: string;
}
export function Tag({
  variant = 'primary',
  className,
  children,
  onClick,
  tooltipId,
}: PropsWithChildren<TagProps>) {
  return (
    <div
      className={twMerge(
        'rounded-full shrink-0 px-2 text-sm',
        variants[variant],
        className,
      )}
      onClick={onClick}
      role="button"
      tabIndex={0}
      data-tooltip-id={tooltipId}
    >
      {children}
    </div>
  );
}
