import { combineReducers } from 'redux';
import provider from './provider';
import chat from './chat';
import app from './app';

export const rootReducer = combineReducers({
  provider,
  chat,
  app,
});

export type RootState = ReturnType<typeof rootReducer>;
