import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from '../../../assets/CheckIcon.svg';
import { riskColorVariants } from '../../../shared/constant/Colors';

interface SelectableRiskLevelCardProps {
  selected?: boolean;
  handleClick: (risk: string) => void;
  variant: keyof typeof riskColorVariants;
}

export default function SelectableRiskLevelCard({
  selected = false,
  handleClick,
  variant,
}: SelectableRiskLevelCardProps) {
  const { t } = useTranslation();
  return (
    <section
      id={variant}
      className={twMerge(
        'box-border w-full p-3 rounded-lg border border-solid flex flex-col gap-1.5 items-center justify-between cursor-pointer',
        selected
          ? riskColorVariants[variant].backgroundColor
          : 'hover:bg-gray-50',
        selected && riskColorVariants[variant].borderColor,
      )}
      role="button"
      tabIndex={0}
      onClick={() => handleClick(riskColorVariants[variant].value)}
    >
      <section
        className={twMerge(
          'w-full flex items-start gap-2',
          selected ? riskColorVariants[variant].textColor : '',
        )}
      >
        <div className="text-[16px] font-semibold">
          {t(riskColorVariants[variant]?.label) ?? ''}
        </div>
      </section>
      <section className="w-full flex items-start justify-between gap-2">
        <div
          className={twMerge(
            'text-sm w-full text-justify',
            selected ? riskColorVariants[variant].textColor : 'text-gray-500',
          )}
        >
          {t(riskColorVariants[variant].description)}
        </div>
        {selected ? (
          <div>
            <CheckIcon stroke={riskColorVariants[variant].iconColor} />
          </div>
        ) : null}
      </section>
    </section>
  );
}
