import * as React from 'react';
import './CaseNotes.scss';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Typography from '../../components/Typography';
import TextArea from '../../components/TextArea';
import {
  getCaseNoteData as _getCaseNoteData,
  saveCaseNoteData as _saveCaseNoteData,
} from '../../../shared/redux/actions/provider';
import {
  ICaseNoteAnswer,
  ICaseNoteQuestion,
  ICaseNoteQuestionAnswer,
  INewCasNote,
} from '../../../shared/types/response/CaseNote';
import { getItemFromLocalStorage } from '../../../utilities/common/Storage';
import { ClientMeetingData } from '../../../shared/types/response/provider';
import {
  displayDateFormat,
  getTruncatedMeetingId,
} from '../../../utilities/common/Display';
import { APIDateFormat } from '../../../utilities/common/Date';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { useCasenoteQuestionQuery } from '../../../utilities/hooks/fetchHooks/provider';
import AccordianDropDown from '../AccordianDropDown';
import useOutsideClickHandler from '../../../utilities/hooks/useOutsideClickHandler';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';

interface ICaseNotesProps {
  getCaseNoteData: (meetingId: string) => Promise<Array<ICaseNoteAnswer>>;
  saveCaseNoteData: (
    meetingId: string,
    userId: number,
    caseNotes: INewCasNote[],
  ) => Promise<ICaseNoteAnswer[]>;
  client: ClientMeetingData;
  mode?: 'read' | 'edit' | 'new';
}

function CaseNotes({
  getCaseNoteData,
  saveCaseNoteData,
  client,
  mode = 'read',
}: ICaseNotesProps) {
  // Custom Hooks
  const { t } = useTranslation();
  const { track } = useTracking();
  // Local state
  const [caseNoteQuestionAnswers, setCaseNoteQuestionAnswers] = React.useState<
    ICaseNoteQuestionAnswer[]
  >([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [parentDimensions, setParentDimensions] = React.useState<DOMRect>();
  const [isDropdownSelected, setIsDropdownSelected] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');

  // React Hooks
  const questionsRef = React.useRef<ICaseNoteQuestion[]>(
    getItemFromLocalStorage(
      'caseNotQuestions',
      'object',
    ) as ICaseNoteQuestion[],
  );
  const hasPreSaved = React.useRef<boolean>(false); // to track pre-saves feature for the new case notes
  const parentRef = React.useRef<HTMLDivElement>(null);

  // Functions
  // * Function to generate the question-answer map
  const generateQuestionAnswerMap = (
    questions: Array<ICaseNoteQuestion>,
    answers: Array<ICaseNoteAnswer>,
  ) => {
    const questionAnswers: Array<ICaseNoteQuestionAnswer> = [];
    questions.forEach((question) => {
      answers.forEach((answer) => {
        if (question.id === answer.questionId) {
          questionAnswers.push({
            questionId: question.id,
            note: answer.note,
            question: question.question,
          });
        }
      });
    });
    setCaseNoteQuestionAnswers(questionAnswers);
  };

  // * Function to save the case notes
  const onCaseNoteSave = () => {
    setLoading(true);
    const newNotes: INewCasNote[] = caseNoteQuestionAnswers.map((caseNote) => ({
      questionId: caseNote.questionId,
      note: caseNote.note,
    }));
    saveCaseNoteData(client.meetingId, Number(client.clientId), newNotes).then(
      () => setLoading(false),
    );
    const trackingData: INewCasNote[] = newNotes.map((note) => ({
      questionId: note.questionId,
      note: `${note.questionId === 2 ? note?.note : note?.note?.length}`,
    }));
    trackingData.forEach((elem) =>
      track(EventNames.view_session_case_notes, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.save,
        featureVersion: 'v1',
        eventLabel: elem.questionId,
        eventValue: elem.note,
      }),
    );
  };

  // * Function to handle the change in the case note answer
  const onCaseNoteAnswerChange = (questionId: number, value: string) => {
    const newCaseNotes = caseNoteQuestionAnswers.map((caseNote) => {
      if (caseNote.questionId === questionId) {
        return {
          ...caseNote,
          note: value,
        };
      }

      return caseNote;
    });

    setCaseNoteQuestionAnswers(newCaseNotes);
  };

  // * Function to get the case note answer
  const parentMeasuredRef = React.useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setParentDimensions(node.getBoundingClientRect());
    }
  }, []);

  // * Function to handle the dropdown selection
  const handleSelectedValueChange = (value: string, questionId: number) => {
    onCaseNoteAnswerChange(questionId, value);
    setSelectedValue(value);
  };

  // Component Lifecycle
  // * React useEffect to get the case notes data
  React.useEffect(() => {
    const emptyAnswers: ICaseNoteAnswer[] = questionsRef.current.map(
      (question) => ({ questionId: question.id, note: '' }),
    );
    if (client) {
      if (mode !== 'new') {
        getCaseNoteData(client.meetingId).then((caseNotesAnswers) => {
          if (caseNotesAnswers.length) {
            // already have case note date
            generateQuestionAnswerMap(questionsRef.current, caseNotesAnswers);
          } else {
            // don't have case note data
            generateQuestionAnswerMap(questionsRef.current, emptyAnswers);
          }
        });
      } else {
        // new case note data (right after completion of a session)
        generateQuestionAnswerMap(questionsRef.current, emptyAnswers);
      }
    }
  }, [client]);

  // * React useEffect to pre-save the case notes
  React.useEffect(() => {
    // for new case notes, we save it with empty answers in case the provider decides to enter case notes in future.
    if (mode === 'new' && !hasPreSaved.current) {
      onCaseNoteSave();
      hasPreSaved.current = true;
    }
  }, [caseNoteQuestionAnswers]);

  React.useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.keyCode === 27 || event.which === 27) {
        setIsDropdownSelected(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useOutsideClickHandler(parentRef, () => setIsDropdownSelected(false));

  // Queries
  // * React Query to get the case notes question data
  const { data: caseNoteQuestions } = useCasenoteQuestionQuery();

  React.useEffect(() => {
    caseNoteQuestionAnswers.map((caseNote) => {
      if (caseNote.questionId === 2) {
        setSelectedValue(caseNote.note);
      }
      return caseNote;
    });
  });
  // React.useEffect(() => {
  //   onCaseNoteSave();
  // }, [selectedValue]);

  return (
    <div
      className={
        isDropdownSelected ? 'container-selected' : 'case-notes-container'
      }
      ref={parentMeasuredRef}
    >
      {isLoading && <Loader useFullPage={false} dimension={parentDimensions} />}
      <div className="controls-container" />
      <div className="case-notes-header-container">
        <Typography color="primary" weight="600" size={24}>
          {getTruncatedMeetingId(client.meetingId)}
        </Typography>
        <Typography color="solarized" weight="400" size={14}>
          {moment(client.meetingDate, APIDateFormat).format(displayDateFormat)}
        </Typography>
      </div>
      <div className="case-note-items">
        {caseNoteQuestionAnswers.map((caseNote) => (
          <div className="case-note-item" key={caseNote.questionId}>
            {caseNote.questionId === 2 ? (
              <div className="dropdown-modal">
                <span className="dropdwon-header">
                  <Typography weight="400" size={14} color="primary">
                    {caseNote.question}
                  </Typography>
                </span>
                <div
                  className="dropdown-container"
                  role="button"
                  tabIndex={0}
                  onClick={() => setIsDropdownSelected(true)}
                >
                  <div className="select-container">
                    {selectedValue ? (
                      <span>{selectedValue}</span>
                    ) : (
                      <span>Select an issue</span>
                    )}
                  </div>
                </div>
                {isDropdownSelected && (
                  <div className="select-dropdown-content">
                    <AccordianDropDown
                      caseNoteQuestions={caseNoteQuestions}
                      setSelectedValue={(value) =>
                        handleSelectedValueChange(value, caseNote.questionId)
                      }
                      setIsDropdownSelected={setIsDropdownSelected}
                      parentRef={parentRef}
                    />
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="question">
                  <Typography weight="400" size={14} color="primary">
                    {caseNote.question}
                  </Typography>
                </div>
                <div className="answer">
                  <TextArea
                    selectedValue={isDropdownSelected}
                    value={caseNote.note || ''}
                    disabled={mode === 'read'}
                    onChange={(value) =>
                      onCaseNoteAnswerChange(caseNote.questionId, value)
                    }
                  />
                </div>
              </>
            )}
          </div>
        ))}
        <Button
          label={t('saveChanges')}
          width="100%"
          height="2.5rem"
          onClick={onCaseNoteSave}
          variant="primary"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({ clientData: state.provider });

const mapDispatchToProps = (dispatch: Function) => ({
  getCaseNoteData: (meetingId: string) => dispatch(_getCaseNoteData(meetingId)),
  saveCaseNoteData: (
    meetingId: string,
    userId: number,
    caseNotes: INewCasNote[],
  ) => dispatch(_saveCaseNoteData(meetingId, userId, caseNotes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseNotes);
