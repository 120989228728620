import Config from '../../shared/Config';
import { ClientCreditTiers } from '../../shared/constant/Common';
import { ICredit } from '../../shared/types/response/provider';
import { beautifyUrl } from './Path';

export const displayDateFormat = 'MMM DD, YYYY';
export const displayTimeFormat = 'hh:mm a';
export const piDisplayDateFormat = 'DD, MMM, YYYY';
export const displayJaTimeFormat = 'HH:mm';
export const displayJaDateFormat = 'MM,DD,YYYY,dddd';

export const formatNumberTo2Digits = (input: number) => `0${input}`.slice(-2);

export const getTruncatedMeetingId = (meetingId: string) =>
  `#${meetingId.substring(0, 6)}`;

export const getUnreadMessagesCountForFloatingChatButton = (count: number) => {
  // messages more than 9 will show as 9+
  if (count >= 10) return '9+';
  return count;
};

export const getImageUri = (imagePath: string) =>
  beautifyUrl(Config.api.media.getImage, [imagePath]);

export const getClientCredits = (clientCredit: ICredit) => {
  let displayCredits = '-';
  const { showCredits, tier } = clientCredit;

  if (tier === ClientCreditTiers.CREDIT_POOL) return 'unlimited';

  displayCredits = showCredits ? 'availableCredits_other' : 'pool';

  return displayCredits;
};
