import * as React from 'react';
import Typography from '../Typography';
import './TextArea.scss';

interface ITextAreaProps {
  value: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  showCharacterCounter?: boolean;
  characterLimit?: number;
  selectedValue?: boolean;
}

function TextArea({
  value,
  onChange,
  onBlur,
  placeholder,
  disabled = false,
  showCharacterCounter = false,
  characterLimit = Infinity,
  selectedValue,
}: ITextAreaProps) {
  const onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <div className="textarea-container">
      <textarea
        className={selectedValue ? 'text-area-selected' : 'text-area'}
        value={value}
        disabled={disabled}
        onChange={onTextChange}
        onBlur={onBlur}
        placeholder={placeholder || ''}
        maxLength={characterLimit}
      />
      {showCharacterCounter && (
        <div className="char-counter">
          <Typography withColor="#636C73" size={10} weight="400">
            {value.length}/{characterLimit}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default TextArea;
