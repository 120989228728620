import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BaseTable from '../BaseTable';
import {
  InsightsDetailsCardContainer,
  StyledSpacer,
  StyledTextNote,
  StyledDescription,
} from './InsightsDetailsCard.styled';
import BaseCard from '../BaseCard/BaseCard';
import { CssSize } from '../BaseCard/BaseCard.d';
import { InsightsDetailsCardProps } from './InsightsDetailsCard.d';

// For calculating the base card height
function CalculateBaseCardHeight(
  prevCardHeight: CssSize,
  tableHeader?: string,
): CssSize {
  if (tableHeader) {
    return `${parseInt(prevCardHeight, 10) - 14}px`;
  }
  return prevCardHeight;
}

function InsightsDetailsCard({
  cardValue,
  cardDescription,
  tableHeader,
  width = tableHeader ? '267px' : '190px',
  height = '215px',
  fontSize,
  fontWeight,
  color,
  backgroundColor = '#D9D9D9',
  textAlign,
  cardValueFontSize,
  spacerHeight = '14px',
  insightInfo,
  textNoteWidth = tableHeader ? '238px' : '175px',
  textNoteFontSize,
  textNoteWeight,
  baseTableData,
  visibilityDesc,
}: InsightsDetailsCardProps) {
  const { t } = useTranslation();
  return (
    <InsightsDetailsCardContainer
      height={height}
      width={width}
      background={backgroundColor}
    >
      <BaseCard
        width={width}
        height={CalculateBaseCardHeight('78px', tableHeader)}
        cardDescription={cardDescription}
        cardValue={cardValue}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textAlign={textAlign}
        cardValueFontSize={cardValueFontSize}
        color={color}
        useTranslationForDescription
      />
      {tableHeader && (
        <StyledSpacer spacerHeight={spacerHeight} width={width} />
      )}

      {!baseTableData.length && visibilityDesc ? (
        <StyledDescription
          descWidth="100%"
          descFontSize="12px"
          descTextAlign="center"
          descHeight="137px"
          descBackground={backgroundColor}
        >
          {t('VISIBILITY_RATIO')} = <br /> {t(`${visibilityDesc}`)}
        </StyledDescription>
      ) : (
        <BaseTable
          tableData={baseTableData}
          tableHeader={tableHeader}
          height="137px"
        />
      )}
      <StyledTextNote
        textNoteWidth={textNoteWidth}
        textNoteWeight={textNoteWeight}
        textNoteFontSize={textNoteFontSize}
        textNoteTextAlign="left"
      >
        {t(`${insightInfo}`)}
      </StyledTextNote>
    </InsightsDetailsCardContainer>
  );
}

export default InsightsDetailsCard;
