import * as React from 'react';
import TabsContext, { TabItem } from '../../../shared/context/TabsContext';
import Typography from '../Typography';
import './Tabs.scss';

interface ITabs {
  children?: React.ReactNode;
  tabs: TabItem[];
  headerSectionWidth?: string;
  onTabChange?: (newTab: TabItem) => void;
  customClassname?: string;
  showKey?: boolean;
  textAlign?: 'left' | 'center' | 'right';
}

function Tabs({
  children,
  tabs,
  headerSectionWidth,
  onTabChange,
  customClassname,
  showKey = false,
  textAlign = 'center',
}: ITabs) {
  const [selectedTab, setSelectedTab] = React.useState<TabItem>(tabs[0]);
  const { key: selectedKey } = selectedTab;
  const memoizedTab = React.useMemo(() => ({ selectedTab }), [selectedTab]);
  const headerWidth = `${100 / tabs.length}%`;

  React.useEffect(() => {
    onTabChange?.(selectedTab);
  }, [selectedTab]);

  return (
    <div className="tabs-container">
      <div
        className="tab-headers"
        style={{ width: headerSectionWidth ?? '100%' }}
      >
        {tabs.map(({ key, label }, index) => (
          <div
            role="button"
            tabIndex={index}
            style={{ width: headerWidth }}
            className={`tab-header ${
              selectedKey === key ? 'selected' : ''
            } ${customClassname}`}
            onClick={() => setSelectedTab({ key, label })}
            key={key}
          >
            <div className={`tab-name-wrapper ${textAlign}`}>
              {showKey && (
                <Typography color="secondary" weight="500" size={14}>
                  {key}
                </Typography>
              )}
              <Typography
                color={selectedKey === key ? 'primary' : 'secondary'}
                weight="600"
                size={14}
              >
                {label}
              </Typography>
            </div>
          </div>
        ))}
      </div>
      <TabsContext.Provider value={memoizedTab}>
        {children}
      </TabsContext.Provider>
    </div>
  );
}

export default Tabs;
