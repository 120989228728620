import { useMutation } from 'react-query';
import {
  getProviderOpenSlots,
  getproviderClientDetails,
  bookProviderSession,
  requestCreditSession,
  getCasenotesQuestions,
  getProviderServices,
  getProviderConfigDetails,
  updateProviderTimezone,
  requestCustomIssue,
} from '../../../shared/service/API/provider';
import { useFetch } from '../useFetch';
import { IBookSessionProps } from '../../../shared/types/request/booking';
import useAuth from '../useAuth';
import { IProviderService } from '../../../shared/types/response/provider';
import { addItemToLocalStorage } from '../../common/Storage';

export interface IResponse<T> {
  data: {
    success: boolean;
    data?: T;
    error?: {
      message: string;
      statusCode?: number;
    };
    statusCode: number;
  };
  status: number;
}

export interface IGenericResponse {
  success: boolean;
}
export type AvailableSessionDurations = '900' | '1800' | '2700' | '3600';
export type ProviderOpenSlots = {
  displayDate: string;
  displayTime: string;
  displayTimeEnd: string;
  startTimeUtc: number;
  endTimeUtc: number;
};
export type IProviderOpenSlotsFull = Record<
  AvailableSessionDurations,
  ProviderOpenSlots[]
>;

interface IClientDataprops {
  userInfo: { friendlyName: string; timeZone: string; userId: string };
  credits: { tier: string; credit: number; canBook: boolean };
  subscription: Array<{
    expiresOn: string;
    billingPlan: { inclusions: string };
  }>;
  duration: Array<string>;
  providerMapping: {
    createdAt: string;
    providerRole: string;
    scheduledDate: string;
    isHold: boolean;
  };
  recentMeeting: { scheduledDate: string; displayDate: string };
}

interface ICaseNoteResType {
  id: number;
  question: string;
  element: string;
  children?: {
    children: {
      question: string;
      id: number;
      element: string;
    }[];
    question: string;
    id: number;
    element: string;
  }[];
}
export type IProviderOpenSlotsResponse = IResponse<IProviderOpenSlotsFull>;

interface IProviderConfigRes {
  timezone: string;
}

export function useProviderOpenSlots(
  providerId: string,
  providerRole: string,
  clientId: string,
  meetingId?: string,
) {
  return useFetch<IProviderOpenSlotsFull>(
    ['provider', 'slots', providerId, providerRole],
    () => getProviderOpenSlots(providerId, providerRole, clientId, meetingId),
    {
      refetchOnWindowFocus: true,
      retry: false,
    },
  );
}

export function useproviderClientDataQuery(
  selectedClientId: number,
  role: string,
) {
  return useFetch<IClientDataprops>(
    ['provider', 'clientDetails', selectedClientId, role],
    () => getproviderClientDetails(selectedClientId, role),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useCasenoteQuestionQuery() {
  return useFetch<ICaseNoteResType[]>(
    ['casenotesQuestions'],
    () => getCasenotesQuestions(),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export const useBookProviderSessionQuery = (
  clientid: string,
  providerBookDetails: IBookSessionProps,
  {
    onSuccess,
    onError,
  }: {
    onSuccess: (data: Record<string, string>) => void;
    onError: (error: Error) => void;
  },
) =>
  useMutation(() => bookProviderSession(clientid, providerBookDetails), {
    onSuccess,
    onError,
  });

export const useRequestCreditsQuery = (
  userId: string,
  requestCreditsDetails: { userId: string; creditType: number },
  {
    onSuccess,
    onError,
  }: { onSuccess: () => void; onError: (error: Error) => void },
) =>
  useMutation(() => requestCreditSession(userId, requestCreditsDetails), {
    onSuccess,
    onError,
  });

export function useProviderServices() {
  const { user } = useAuth();

  return useFetch<IProviderService>(
    ['provider', 'services', user.id],
    () => getProviderServices(user.id),
    {
      refetchOnWindowFocus: true,
      retry: false,
    },
  );
}

export function useProviderConfig() {
  const { isLoggedIn } = useAuth();
  return useFetch<IProviderConfigRes>(
    ['provider-config-details'],
    () => getProviderConfigDetails(),
    {
      refetchOnWindowFocus: false,
      enabled: !!isLoggedIn,
      onSuccess(data) {
        addItemToLocalStorage('userTimeZone', data.timezone);
      },
    },
  );
}

export const useUpdateProviderTimezone = ({
  onSuccess,
  onError,
}: {
  onSuccess: (
    data: any,
    payload: {
      providerId: string;
      newTimezone: string;
    },
  ) => void;
  onError: (error: Error) => void;
}) =>
  useMutation(
    ({
      providerId,
      newTimezone,
    }: {
      providerId: string;
      newTimezone: string;
    }) => updateProviderTimezone({ providerId, newTimezone }),
    {
      onSuccess,
      onError,
    },
  );

export const useRequestCustomIssue = (
  body: { meetingId: string; topic: string; description: string },
  {
    onSuccess,
    onError,
  }: { onSuccess: () => void; onError: (error: Error) => void },
) =>
  useMutation(() => requestCustomIssue(body), {
    onSuccess,
    onError,
  });
