import { useMutation as useRQMutation } from 'react-query';

interface UseMutationReturns {
  data: any;
  mutate: Function;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

interface IUseMutationOptions {
  onSuccess?: (data: any, variables?: any) => void;
  onError?: (data: any) => void;
}

function useMutation(
  query: any,
  options: IUseMutationOptions = {},
): UseMutationReturns {
  const { onSuccess, onError } = options;
  const { data, mutate, isLoading, isSuccess, isError } = useRQMutation(query, {
    onSuccess,
    onError,
  });

  return {
    data,
    mutate,
    isLoading,
    isSuccess,
    isError,
  };
}

export default useMutation;
