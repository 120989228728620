import * as React from 'react';

interface IMediaPreviewContext {
  showPreview: (media: string | null) => void;
}

const MediaPreviewContext = React.createContext<IMediaPreviewContext>(
  {} as IMediaPreviewContext,
);

export default MediaPreviewContext;
