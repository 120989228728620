import * as React from 'react';
import { motion } from 'framer-motion';
import Typography from '../Typography';
import './CircularProgressBar.scss';

interface CircularProgressBarProps {
  progressValue: number;
}

function CircularProgressBar({ progressValue }: CircularProgressBarProps) {
  const conicGradientValue = `conic-gradient(#428BB5 ${
    progressValue * 3.6
  }deg, #FBFCFE 0deg)`;
  return (
    <motion.div
      className="circular-progress-bar"
      animate={{ background: conicGradientValue }}
      style={{ background: conicGradientValue }}
      transition={{ duration: 0.5 }}
    >
      <div className="progress-label">
        <Typography size={12} weight="400" color="secondary">
          {progressValue}%
        </Typography>
      </div>
    </motion.div>
  );
}

export default CircularProgressBar;
