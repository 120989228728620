import ActionTypes from '../../constant/ActionTypes';
import { MeetingStatus } from '../../constant/Common';
import { IMeetingStatus, IProviderData } from '../../types/response/provider';

const defaultState = {
  meeting: {} as IMeetingStatus,
  providerData: {} as IProviderData,
  chatToken: '',
};

const defaultAction = {
  type: '',
  payload: '',
};

interface IReducerState {
  meeting: IMeetingStatus;
  providerData: IProviderData;
  chatToken: string;
}

type Action = { type: string; payload: any };

export default (
  state: IReducerState = defaultState,
  action: Action = defaultAction,
) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.APP.INITIALIZE_MEETING:
      return {
        ...state,
        meeting: {
          ...state.meeting,
          status: 'started',
          data: payload,
        },
      };

    case ActionTypes.APP.COMPLETE_MEETING:
      return {
        ...state,
        meeting: { ...state.meeting, status: MeetingStatus.COMPLETED },
      };

    case ActionTypes.APP.RESET_MEETING:
      return {
        ...state,
        meeting: { ...state.meeting, status: MeetingStatus.NOT_STARTED },
      };

    case ActionTypes.APP.SET_PROVIDER_DATA:
      return {
        ...state,
        providerData: { ...payload },
      };

    case ActionTypes.APP.SET_CHAT_TOKEN:
      return {
        ...state,
        chatToken: payload,
      };

    default:
      return state;
  }
};
