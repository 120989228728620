import { AnimatePresence } from 'framer-motion';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../shared/constant/Analytics';
import { getCustomAssignments } from '../../../shared/redux/actions/assignment';
import {
  AssignmentPage,
  CustomAssignment,
} from '../../../shared/types/response/assignment';
import useDebounce from '../../../utilities/hooks/useDebounce';
import { useInfiniteFetch } from '../../../utilities/hooks/useFetch';
import useTracking from '../../../utilities/hooks/useTracking';
import Loader from '../../components/Loader';
import TextBox from '../../components/TextBox';
import AssignmentCard from '../AssignmentCard';
import GoToTop from '../GoToTop';
import NoResultsFound from '../NoResultsFound';
import '../InternalAssignments/InternalAssignments.scss'; // can re-use internal assignment's layout
import searchIcon from '../../../assets/search.svg';
import CreateCustomAssignmentPopup from '../CreateCustomAssignmentPopup';

interface CustomAssignmentsProps {
  onCreateCustomAssignmentClick: () => void;
}

function CustomAssignments({
  onCreateCustomAssignmentClick,
}: CustomAssignmentsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { track } = useTracking();
  const [searchText, setSearchText] = React.useState<string>('');
  const [selectedAssignment, setSelectedAssignment] =
    React.useState<CustomAssignment | null>(null);
  const lastElementRef = React.useRef<HTMLDivElement>(null);
  const assignmentListingElementRef = React.useRef<HTMLDivElement>(null);
  const lastElementObserver = React.useRef<IntersectionObserver>();
  const debouncedSearchText = useDebounce(searchText, 1000);

  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteFetch(
      ['assignments', debouncedSearchText],
      ({ pageParam = 0 }) =>
        dispatch(getCustomAssignments(pageParam, searchText)),
      {
        getNextPageParam: (currentPage: AssignmentPage<CustomAssignment>) => {
          const { nbPages, page } = currentPage;
          if (page < nbPages - 1) {
            return page + 1;
          }

          return undefined;
        },
      },
    );

  const isAssignmentFilterResultsEmpty =
    !isLoading && !data?.pages[0]?.assignments?.length;

  React.useEffect(() => {
    lastElementObserver.current = new IntersectionObserver(
      async (entries) => {
        const lastAssignmentCardEntry = entries[0];
        if (lastAssignmentCardEntry.isIntersecting) {
          fetchNextPage();
        }
      },
      { threshold: 1 },
    );

    const element = lastElementRef.current;
    const observer = lastElementObserver.current;

    if (element) {
      observer?.observe(element);
    }

    return () => {
      if (element) {
        observer?.unobserve(element);
      }
    };
  }, [data]);

  // React.useEffect(() => {
  //   track(EventNames.providerWebAssignment, {
  //     eventAction: EventActions.click,
  //     eventCategory: EventCategories.viewAssignments,
  //     eventLabel: 'view_assignments',
  //   });
  // }, []);

  React.useEffect(() => {
    if (isAssignmentFilterResultsEmpty) {
      track(EventNames.providerWebCustomAssignment, {
        eventAction: EventActions.popup,
        eventCategory: EventCategories.noExternalResources,
        eventLabel: 'ES_no_external_resource',
      });
    }
  }, [isAssignmentFilterResultsEmpty]);

  const onAssignButtonClick = (assignment: CustomAssignment) => {
    setSelectedAssignment(assignment);
  };

  const onAssignPopupClose = () => {
    setSelectedAssignment(null);
  };

  return (
    <div className="assignments-section">
      {selectedAssignment && (
        <CreateCustomAssignmentPopup
          onClose={onAssignPopupClose}
          existingData={{
            id: selectedAssignment._id,
            title: selectedAssignment.title,
            filename: selectedAssignment.filename,
            fileURL: selectedAssignment.fileURL,
          }}
        />
      )}
      {(isLoading || isFetchingNextPage) && (
        <Loader useFullPage withBackdrop={false} />
      )}
      <div className="filters-and-search">
        <div className="search">
          <TextBox
            variant="box-border"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            icon={searchIcon}
            placeholder={t('searchFor')}
            animate={false}
          />
        </div>
      </div>
      <div className="assignments-listing" ref={assignmentListingElementRef}>
        {!!isAssignmentFilterResultsEmpty && (
          <div className="empty-assignments">
            <NoResultsFound
              captionCopy={t('NO_EXTERNAL_RESOURCE_TITLE')}
              ctaLabel={t('NO_EXTERNAL_RESOURCE_CTA')}
              ctaCallback={() => onCreateCustomAssignmentClick()}
            />
          </div>
        )}
        <AnimatePresence>
          {data?.pages.map((group) => (
            <React.Fragment key={group.page}>
              {(group.assignments || []).map(
                (assignment: CustomAssignment, index: number) => (
                  <AssignmentCard<CustomAssignment>
                    index={index}
                    key={assignment._id}
                    assignmentData={assignment}
                    onAssignClick={onAssignButtonClick}
                  />
                ),
              )}
            </React.Fragment>
          ))}
        </AnimatePresence>
        <div className="bottom-element" ref={lastElementRef} />
      </div>
      <div className="goto-top-container">
        <GoToTop
          scrollThresholdToAppear={200}
          scrollElementRef={assignmentListingElementRef}
        />
      </div>
    </div>
  );
}

export default CustomAssignments;
