/* eslint-disable react/jsx-props-no-spreading */ //* this rule can be disabled for HOCs
import * as React from 'react';
import useTracking, { TrackFunction } from '../hooks/useTracking';

interface WithTrackingProps {
  track: TrackFunction;
}

function withTracking<P extends WithTrackingProps = WithTrackingProps>(
  WrappedComponent: any,
) {
  // displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  function ComponentWithTracking(props: Omit<P, keyof WithTrackingProps>) {
    const { track } = useTracking();

    return <WrappedComponent {...(props as P)} track={track} />;
  }

  ComponentWithTracking.displayName = `withTracker(${displayName})`;

  return ComponentWithTracking;
}

export default withTracking;
