/* eslint-disable */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './ReportCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleChevronRight,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { IReportCard } from './types';

import Typography from '../../../../view/components/Typography';
import SideDrawer from '../../../../view/components/SideDrawer';
import PersonalInsightsResults from '../../../../view/components/PersonalInsightsResults';
import {
  GoalDimesionRelevencyMapping,
  TraitIdMapping,
} from '../../../../shared/constant/Goals';
import useTracking from '../../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';

function ReportCard({
  reportCartData,
  goalName,
  onClickLearnMore,
}: IReportCard) {
  const { t } = useTranslation();
  const { track } = useTracking();

  const [showGoalsDrawer, setShowGoalsDrawer] = React.useState<boolean>(false);

  const handleLearnMoreCLick = (traitId: string) => {
    setShowGoalsDrawer(true);
    const traitTitle = TraitIdMapping[traitId];
    track(EventNames.client_personal_insights, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.dimension,
      eventLabel: 'dimension_info',
      eventValue: traitTitle,
      featureVersion: 'v1',
      eventSource: 'goal',
    });
    onClickLearnMore(traitTitle);
  };
  const hideGoalsDrawer = () => {
    setShowGoalsDrawer(false);
  };

  const scoreDiff = React.useMemo(
    () => reportCartData.score - reportCartData.previousScore,
    [reportCartData],
  );

  return (
    <article className="report-card-wrapper">
      <section className="report-marks-section">
        <Typography
          size={24}
          weight="700"
          withColor="#0A0C0E"
          textAlign="center"
        >
          {reportCartData.score} / 100
        </Typography>
        <Typography
          size={16}
          weight="500"
          withColor="#0a0c0e99"
          textAlign="center"
        >
          <span
            className={`progress-indicator-wrapper ${
              scoreDiff > 0 ? 'increasing' : scoreDiff === 0 ? '' : 'decreasing'
            }`}
          >
            {scoreDiff !== 0 && (
              <FontAwesomeIcon icon={scoreDiff > 0 ? faArrowUp : faArrowDown} />
            )}
            {scoreDiff !== 0 && Math.abs(scoreDiff)}
          </span>
        </Typography>
      </section>
      <section className="report-description-container">
        <Typography size={16} weight="700" withColor="#0A0C0E">
          {reportCartData.title}
        </Typography>
        <Typography size={14} weight="400" withColor="#0a0c0e99">
          {reportCartData.description}
        </Typography>
      </section>
      <motion.button
        className="report-control-item"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => handleLearnMoreCLick(reportCartData.traitId)}
      >
        <FontAwesomeIcon icon={faCircleChevronRight} className="icons" />
      </motion.button>
      {showGoalsDrawer && (
        <SideDrawer show={showGoalsDrawer} hideHandler={hideGoalsDrawer}>
          <section className="hero-dimension-wrapper">
            <PersonalInsightsResults
              dimensionHeading={reportCartData.title}
              dimensionDesc={reportCartData.description}
              goalDescription={t(
                GoalDimesionRelevencyMapping?.[goalName]?.[
                  reportCartData?.traitId
                ],
              )}
              score={reportCartData.score}
              difference={scoreDiff}
              goalName={goalName}
              traitId={reportCartData.traitId}
              recommendedAssignments={reportCartData.recommendations}
              relevancyText={t('DIMENSION_RELEVANT_QUESTION')}
              source="goal"
            />
          </section>
        </SideDrawer>
      )}
    </article>
  );
}

export default ReportCard;
