import styled from 'styled-components';

export const StyledNewProviderContainer = styled.section`
  flex-direction: column;
  height: 80%;
  margin: auto;
  align-items: center;
  padding: 100px 25px;
  display: flex;
  align-items: center;
  gap: 24px;
  border-top: 1px solid #e9eaeb;
`;

export const StyledImageSection = styled.section`
  width: 230px;
`;

export const StyledImg = styled.img`
  margin: auto;
  padding-bottom: 20px;
`;
