import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';

interface TooltipProps {
  id: string;
  content: string;
  tooltipBgColor?: string;
  toolTipFontColor?: string;
  placement?: PlacesType;
}
export default function Tooltip({
  id,
  content,
  placement = 'top',
  tooltipBgColor = '#FCFCFD',
  toolTipFontColor = '#101828',
}: TooltipProps) {
  return (
    <ReactTooltip
      id={id}
      place={placement}
      content={content}
      style={{
        backgroundColor: tooltipBgColor,
        color: toolTipFontColor,
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
    />
  );
}
