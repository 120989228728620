import * as React from 'react';
import moment from 'moment';
import './TimeSlotDataRow.scss';
import lodash from 'lodash';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import TimeRangePicker from '../../components/TimeRangePicker';
import Typography from '../../components/Typography';
import removeIcon from '../../../assets/remove-red.svg';
import inPersonIcon from '../../../assets/location-pointer.svg';
import virtualIcon from '../../../assets/virtual-session.svg';
import {
  IProviderDaySlot,
  IProviderService,
  ISlotRange,
} from '../../../shared/types/response/provider';
import { ITimeRange } from '../../components/TimeRangePicker/TimeRangePicker';
import ToggleSwitch from '../../components/ToggleSwitch';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';
import { SessionType } from '../../../shared/constant/Common';
import SelectBoxV2 from '../../components/SelectBoxV2';
import SelectTimeSlotLocationPopup from '../SelectTimeSlotLocationPopup';
import { INewSlot } from './types';
import { getSessionTypeOptions } from '../../../utilities/common/Provider';

interface ITimeSlotDataRow {
  day: string;
  data: IProviderDaySlot;
  onRemoveSlot: (day: string, removedSlot: ISlotRange) => void;
  onAddSlot: (day: string, newSlot: ISlotRange) => Promise<any>;
  onSlotsClear: (day: string) => void;
  providerServices: IProviderService;
}

function TimeSlotDataRow({
  day,
  data,
  onRemoveSlot,
  onAddSlot,
  onSlotsClear,
  providerServices,
}: ITimeSlotDataRow) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const [newSlots, setNewSlots] = React.useState<Array<INewSlot>>([]);
  const [isDayEnabled, setDayEnabled] = React.useState<boolean>(false);
  const [showChooseLocationModal, setShowChooseLocationModal] =
    React.useState<boolean>(false);

  const handleAddTimeSlot = (selectedLocationIds: INewSlot['locationId'][]) => {
    const slotsToAdd = selectedLocationIds.map((locationId, index) => {
      if (newSlots.length) {
        const lastIndex = newSlots[newSlots.length - 1].index;
        return {
          index: lastIndex + index + 1,
          locationId,
          timeRange: { startTime: '', endTime: '' },
        };
      }
      return {
        index: 0 + index,
        locationId,
        timeRange: { startTime: '', endTime: '' },
      };
    });

    setNewSlots((prev) => [...prev, ...slotsToAdd]);

    track(EventNames.workingHours, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.addTimeSlot,
      eventLabel: 'add_time_slot',
      featureVersion: 'v1',
    });
  };

  const onSessionTypeSelect = (selectedSessionType: SessionType) => {
    if (selectedSessionType === SessionType.VIRTUAL) {
      handleAddTimeSlot([null]);
    } else {
      setShowChooseLocationModal(true);
    }
  };

  const onRemoveNewSlot = (index: number) => {
    let updatedSlots = [];
    const [beforeSlots, afterSlots] = lodash.partition(
      newSlots,
      (item) => item.index <= index,
    );
    beforeSlots.splice(-1, 1); // removing the actual item
    updatedSlots = [
      ...(beforeSlots || []),
      ...afterSlots.map((item) => ({ ...item, index: item.index - 1 })),
    ];
    setNewSlots(updatedSlots);
    track(EventNames.workingHours, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.removeTimeSlot,
      eventLabel: 'remove_time_slot',
      featureVersion: 'v1',
    });
  };

  const onTimeSlotsValueChange = async (
    index: number,
    newTimeRange: ITimeRange,
    locationId: string | null,
  ) => {
    const { startTime, endTime } = newTimeRange;

    if (startTime && endTime) {
      if (data && data.day) {
        const response = await onAddSlot(data?.day, {
          locationId,
          slots: [+startTime, +endTime],
        });

        if (!Array.isArray(response) && !response?.error) {
          setNewSlots(newSlots.filter((item) => item.index !== index));
        }
      }
    } else {
      const updatedSlots = newSlots.map((slot) => {
        if (slot.index === index) {
          const updatedSlot: INewSlot = { ...slot, timeRange: newTimeRange };
          return updatedSlot;
        }
        return slot;
      });

      setNewSlots(updatedSlots);
    }
  };

  const onDayToggleChange = (isChecked: boolean) => {
    setDayEnabled(isChecked);
    if (!isChecked) {
      onSlotsClear(data?.day || '');
    }
  };

  const onToggleChange = (isChecked: boolean) => {
    track(EventNames.workingHours, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.day,
      eventLabel: moment.weekdaysShort(Number(data?.day)),
      featureVersion: 'v1',
      eventValue: isChecked,
    });
  };

  return (
    <motion.div
      className="row-container"
      initial={{ backgroundColor: '#fff' }}
      animate={
        isDayEnabled
          ? { backgroundColor: '#fff' }
          : { backgroundColor: '#FBFCFE' }
      }
    >
      <div className="day-container">
        <Typography withColor="#263E58" size={12} weight="400" color="primary">
          {day}
        </Typography>
        <ToggleSwitch
          id={day}
          key={day}
          initialValue={Boolean(data.slotsRange.length)}
          onChange={(isChecked) => onDayToggleChange(isChecked)}
          onToggleClicked={onToggleChange}
        />
      </div>

      <div className="timeslot-item-container">
        {!!data &&
          !!data.slotsRange &&
          !!data.slotsRange.length &&
          [...data.slotsRange]
            .sort((a, b) => (a.slots[0] > b.slots[0] ? 1 : -1))
            .map((slot) => {
              const timeRange = {
                startTime: slot.slots[0].toString(),
                endTime: slot.slots[1].toString(),
              };

              const rowLocation = providerServices.clinics.find(
                (loc) => loc.id === slot?.locationId,
              );

              return (
                <React.Fragment key={JSON.stringify(slot)}>
                  <div className="location-container">
                    <img
                      src={rowLocation ? inPersonIcon : virtualIcon}
                      alt={
                        rowLocation ? 'in person session' : 'virtual session'
                      }
                    />
                    <div className="location-name">
                      {rowLocation ? rowLocation.name : t('VIRTUAL')}
                    </div>
                  </div>
                  <div
                    className="time-ranges-container"
                    key={timeRange.startTime + timeRange.endTime}
                  >
                    <TimeRangePicker initialValue={timeRange} disabled />
                    <img
                      src={removeIcon}
                      alt="remove"
                      onClick={() => onRemoveSlot(data.day, slot)}
                      className="remove"
                    />
                  </div>
                </React.Fragment>
              );
            })}
        {newSlots &&
          newSlots.map((slot) => {
            const { timeRange } = slot;

            const rowLocation = providerServices.clinics.find(
              (loc) => loc.id === slot?.locationId,
            );

            return (
              <React.Fragment key={slot.index}>
                <div className="location-container">
                  <img
                    src={rowLocation ? inPersonIcon : virtualIcon}
                    alt={rowLocation ? 'in person session' : 'virtual session'}
                  />
                  <Typography size={14} weight="400" color="secondary">
                    {rowLocation ? rowLocation.name : t('VIRTUAL')}
                  </Typography>
                </div>
                <div className="time-ranges-container">
                  <TimeRangePicker
                    initialValue={timeRange}
                    onChange={(value) =>
                      onTimeSlotsValueChange(
                        slot.index,
                        value,
                        slot?.locationId,
                      )
                    }
                  />
                  <img
                    src={removeIcon}
                    alt="remove"
                    onClick={() => onRemoveNewSlot(slot.index)}
                    className="remove"
                  />
                </div>
              </React.Fragment>
            );
          })}
      </div>
      <div className="row-actions">
        {isDayEnabled && (
          <>
            <SelectBoxV2
              onChange={(newValue) =>
                onSessionTypeSelect(newValue as SessionType)
              }
              values={getSessionTypeOptions(providerServices.services || [])}
              selectedValue=""
              placeHolderText={t('addTimeSlot')}
            />
            {showChooseLocationModal && (
              <SelectTimeSlotLocationPopup
                locations={providerServices.clinics}
                closeHandler={() => setShowChooseLocationModal(false)}
                addSlotForLocation={(locationList: string[]) =>
                  handleAddTimeSlot(locationList)
                }
              />
            )}
          </>
        )}
      </div>
    </motion.div>
  );
}

export default TimeSlotDataRow;
