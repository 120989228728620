import { twMerge } from 'tailwind-merge';
import { extractInitials } from '../../utilities/common/Avatar';

export default function Avatar({
  clientName,
  className = '',
}: {
  clientName: string;
  className?: string;
}) {
  return (
    <section
      className={twMerge(
        'w-12 h-12 rounded-full flex items-center justify-center md font-semibold text-white bg-rose-800',
        className,
      )}
    >
      {extractInitials(clientName)}
    </section>
  );
}
