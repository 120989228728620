import * as React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import { useQueryClient } from 'react-query';
import { toast } from 'sonner';
import Typography from '../../components/Typography';
import DailyOverrideCalenderView from '../DailyOverrideCalenderView';
import './DailyOverrideTab.scss';
import { useFetch, useMutation } from '../../../utilities/hooks/useFetch';
import {
  getDailyDataV2,
  updateDailyOverride,
} from '../../../shared/redux/actions/dailyOverrides';
import Loader from '../../components/Loader';
import {
  APIDateFormat,
  APIDisplayTimeFormat,
  OldChatContactFormat,
  OnsiteWarningTimeFormat,
} from '../../../utilities/common/Date';
import TimeRangePicker from '../../components/TimeRangePicker';
import Button from '../../components/Button';
import removeIcon from '../../../assets/remove-red.svg';
import inPersonIcon from '../../../assets/location-pointer.svg';
import virtualIcon from '../../../assets/virtual-session.svg';
import { ITimeRange } from '../../components/TimeRangePicker/TimeRangePicker';
import AppointmentsCard from '../AppointmentsCard';
import {
  IProviderDailyData,
  IProviderDaySlot,
  IProviderSlot,
} from '../../../shared/types/response/provider';
import Tags from '../Tags';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../shared/constant/Analytics';
import { isObjectEmpty } from '../../../utilities/common/Object';
import { isArrayEmpty } from '../../../utilities/common/Array';
import Card from '../../components/Card';
import InformationModal from '../InformationModal';
import useOutOfOffice, {
  removeOutOfOfficeDate,
} from '../../../utilities/hooks/useOutOfOffice/useOutOfOffice';
import useMonthlyDailyOverrides from '../../../utilities/hooks/useMonthlyDailyOverride/useMonthlyDailyOverrides';
import { INewSlot } from '../TimeSlotDataRow/types';
import { useProviderServices } from '../../../utilities/hooks/fetchHooks/provider';
import { SessionType } from '../../../shared/constant/Common';
import { getSessionTypeOptions } from '../../../utilities/common/Provider';
import SelectBoxV2 from '../../components/SelectBoxV2';
import SelectTimeSlotLocationPopup from '../SelectTimeSlotLocationPopup';

interface IDailyOverrideTabProps {
  onTabChange: (tabName: string) => void;
  updateLeaveMarker: (value: boolean) => void;
}

function DailyOverrideTab({
  onTabChange,
  updateLeaveMarker,
}: IDailyOverrideTabProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { track } = useTracking();
  const {
    isOnLeave: leaveInfo,
    fetchOnLeave,
    leaveCheckLoading,
  } = useOutOfOffice();
  const [selectedDay, setSelectedDay] = React.useState<string>(
    moment().toISOString(),
  );
  const [showSaveControls, setShowSaveControls] =
    React.useState<boolean>(false);
  const [showChooseLocationModal, setShowChooseLocationModal] =
    React.useState<boolean>(false);
  const [newSlots, setNewSlots] = React.useState<Array<INewSlot>>([]);
  const [isOnLeave, setIsOnLeave] = React.useState<boolean>(false);
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    React.useState<boolean>(false);
  const existingDataRef = React.useRef<
  Pick<INewSlot, 'timeRange' | 'locationId'>[]
  >([]);
  const isOldDate = moment(selectedDay).isBefore(moment(), 'day');
  const { refetch: leavesOverideRefetch } = useMonthlyDailyOverrides(
    moment(selectedDay).format('YYYY/MM'),
  );

  const queryClient = useQueryClient();

  const { data: providerServices, isLoading: providerServicesLoading } =
    useProviderServices();

  const onAddTimeSlot = (
    selectedLocationIds: INewSlot['locationId'][],
    initialBatchUpdates?: Pick<INewSlot, 'timeRange' | 'locationId'>[],
  ) => {
    if (!initialBatchUpdates) {
      const slotsToAdd = selectedLocationIds.map((locationId, index) => {
        if (newSlots.length) {
          const lastIndex = newSlots[newSlots.length - 1].index;
          return {
            index: lastIndex + index + 1,
            locationId,
            timeRange: { startTime: '', endTime: '' },
          };
        }
        return {
          index: 0 + index,
          locationId,
          timeRange: { startTime: '', endTime: '' },
        };
      });

      setNewSlots((prev) => [...prev, ...slotsToAdd]);
    } else {
      const slots: INewSlot[] = initialBatchUpdates.map(
        ({ locationId, timeRange }, index) => ({
          locationId,
          index,
          timeRange,
        }),
      );
      setNewSlots(slots);
    }
  };

  const addExistingTimeSlots = (existingData: IProviderSlot) => {
    const existingDataKeys = Object.keys(existingData);
    if (!isObjectEmpty(existingData)) {
      const day = existingDataKeys[0];
      const slot = existingData[day];
      const { slotsRange } = slot || {};
      if (slotsRange && !isArrayEmpty(slotsRange)) {
        const initialSlots = slotsRange.map((slotItem) => ({
          timeRange: {
            startTime: slotItem.slots[0]?.toString(),
            endTime: slotItem.slots[1]?.toString(),
          },
          locationId: slotItem.locationId,
        }));
        onAddTimeSlot([null], initialSlots);
        initialSlots.forEach(
          ({ timeRange: { startTime, endTime }, locationId }) => {
            existingDataRef.current.push({
              timeRange: { startTime, endTime },
              locationId,
            });
          },
        );
      }
    }
  };

  const { isLoading, data, refetch, isFetching } = useFetch<IProviderDailyData>(
    ['daily-overrides', moment(selectedDay).format(APIDateFormat)],
    () => dispatch(getDailyDataV2(moment(selectedDay).format(APIDateFormat))),
    {
      refetchOnWindowFocus: false,
      initialData: {
        override: false,
        overrides: [],
        defaultSlots: [],
        meetings: [],
        leave: false,
      },

      onSuccess: (dailyData: IProviderDailyData) => {
        const { override, slots, leave } = dailyData;
        const { overriddenSlots, defaultSlots } = slots || {};
        if (override) {
          setNewSlots([]);
          existingDataRef.current = [];
          if (overriddenSlots) {
            addExistingTimeSlots(overriddenSlots);
          }
          setIsOnLeave(leave);
        } else {
          // not overridden, there will be default slots
          setNewSlots([]);
          existingDataRef.current = [];
          if (defaultSlots) {
            addExistingTimeSlots(defaultSlots);
          }
          setIsOnLeave(leave);
        }
        setShowSaveControls(false);
      },
    },
  );

  const refetchOverrideData = () => {
    queryClient.invalidateQueries(['overridden-dates'], { active: true });
    refetch();
  };

  const { mutate: updateSlots } = useMutation(
    (slots: Record<string, IProviderDaySlot>) =>
      dispatch(
        updateDailyOverride(
          moment(selectedDay).format(APIDateFormat),
          slots,
          providerServices?.clinics || [],
          data?.defaultSlots || [],
          data?.slots?.overriddenSlots || {},
        ),
      ),
    {
      onSuccess: (updateOverrideResponse) => {
        if (updateOverrideResponse.error) {
          toast.error(updateOverrideResponse.error.message);
        } else {
          toast.success(
            t('OVERRIDE_SUCCESS_TOAST', {
              date: moment(selectedDay).format('DD MMM, dddd'),
            }),
          );
          refetchOverrideData();
        }
      },

      onError: (error: Error) => {
        toast.error(t(error.message));
      },
    },
  );

  const onCalenderDayChange = (day: string) => {
    existingDataRef.current = [];
    fetchOnLeave(moment(day).format(APIDateFormat));
    setSelectedDay(day);
  };

  const onRemoveNewSlot = (index: number, isExistingData: boolean) => {
    track(EventNames.providerWebDailyOverride, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.removeSlotBtn,
      eventLabel: 'remove_slot_button',
      featureVersion: 'v1',
    });
    if (
      newSlots.length === 1 &&
      newSlots[index].timeRange.startTime.length &&
      newSlots[index].timeRange.endTime.length
    ) {
      setConfirmationModalVisibility(true);
      return;
    }
    let updatedSlots = [];
    const [beforeSlots, afterSlots] = lodash.partition(
      newSlots,
      (item) => item.index <= index,
    );
    beforeSlots.splice(-1, 1); // removing the actual item
    updatedSlots = [
      ...(beforeSlots || []),
      ...afterSlots.map((item) => ({ ...item, index: item.index - 1 })),
    ];
    setNewSlots(updatedSlots);

    if (isExistingData) {
      setShowSaveControls(true);
    }
  };

  const onTimeSlotsValueChange = (index: number, newTimeRange: ITimeRange) => {
    const updatedSlots = newSlots.map((slot) => {
      if (slot.index === index) {
        const updatedSlot: INewSlot = { ...slot, timeRange: newTimeRange };
        return updatedSlot;
      }
      return slot;
    });

    setNewSlots(updatedSlots);
  };

  const isSlotsNeedsToBeSaved = () => {
    const isValidData = newSlots.every(
      ({ timeRange }) => timeRange.startTime && timeRange.endTime,
    );
    let dataChanged = false;
    if (
      newSlots.length &&
      existingDataRef.current.length &&
      newSlots.length === existingDataRef.current.length
    ) {
      for (let i = 0; i < newSlots.length; i += 1) {
        if (
          !lodash.isEqual(
            newSlots[i].timeRange,
            existingDataRef.current[i].timeRange,
          )
        ) {
          dataChanged = true;
          break;
        }
      }
    } else if (newSlots.length !== existingDataRef.current.length) {
      dataChanged = true;
    }

    return dataChanged && isValidData;
  };

  const onConfirmEditedSlots = () => {
    const weekDay = moment(selectedDay).day();
    const slots = {
      [weekDay]: {
        day: weekDay,
        slotsRange: newSlots.map(({ locationId, timeRange }) => ({
          locationId,
          slots: [timeRange.startTime, timeRange.endTime],
        })),
      },
    };
    updateSlots(slots);
    track(EventNames.providerWebDailyOverride, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.saveButton,
      eventLabel: 'save_button',
    });
  };

  const isExistingData = (timeRange: ITimeRange, locationId: string | null) =>
    existingDataRef.current.findIndex(
      (el) =>
        lodash.isEqual(el.timeRange, timeRange) && el.locationId === locationId,
    ) > -1;

  const onSessionTypeSelect = (selectedSessionType: SessionType) => {
    if (selectedSessionType === SessionType.VIRTUAL) {
      onAddTimeSlot([null]);
    } else if (selectedSessionType === SessionType.INPERSON) {
      setShowChooseLocationModal(true);
    }
    track(EventNames.providerWebDailyOverride, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.newSlotButton,
      eventLabel: 'new_slot_button',
    });
  };

  React.useEffect(() => {
    setNewSlots([]);
  }, [selectedDay]);

  React.useEffect(() => {
    setShowSaveControls(isSlotsNeedsToBeSaved());
  }, [newSlots]);

  React.useEffect(() => {
    fetchOnLeave(moment().format(APIDateFormat));
    track(EventNames.providerWebDailyOverride, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.dailyOverrideTab,
      eventLabel: 'daily_override_tab',
    });
  }, []);

  const handleConfirmationModalState = () => {
    track(EventNames.providerWebDailyOverride, {
      eventAction: EventActions.click,
      eventCategory: confirmationModalVisibility
        ? EventCategories.confirmMarkAvailable
        : EventCategories.markAvailable,
      eventLabel: confirmationModalVisibility
        ? EventCategories.close
        : EventCategories.markAvailable,
      featureVersion: 'v1',
    });
    setConfirmationModalVisibility((prev) => !prev);
  };

  const onRemovalSuccess = () => {
    toast.success(t('OOO_SUCCESS_PROMPT_2'));
    setConfirmationModalVisibility(false);
    setIsOnLeave(false);
    leavesOverideRefetch();
  };

  const { removeLeaveDates } = removeOutOfOfficeDate(onRemovalSuccess);

  const handleMarkAvailable = () => {
    track(EventNames.providerWebDailyOverride, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.confirmMarkAvailable,
      eventLabel: 'confirm_mark_available',
      featureVersion: 'v1',
    });
    const body = {
      dates: [moment(selectedDay).format(APIDateFormat)],
    };
    removeLeaveDates(body);
  };

  const handleMarkOnLeave = () => {
    track(EventNames.workingHours, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.onLeave,
      eventLabel: 'on_leave',
      featureVersion: 'v1',
      eventSource: 'working_hours_removed',
    });
    onTabChange('WORKING_HOURS');
    updateLeaveMarker(true);
  };

  if (isLoading || providerServicesLoading || !providerServices) {
    return (
      <div className="daily-override-container">
        <Loader useFullPage />
      </div>
    );
  }

  return (
    <div className="daily-override-container">
      <div className="daily-override-controls">
        <div className="calender-view">
          <DailyOverrideCalenderView onCalenderDayClick={onCalenderDayChange} />
        </div>
        <div className="daily-overrides-edit">
          <div className="edit-header">
            <Typography weight="600" size={20}>
              {t('MANAGE_SLOTS')}
            </Typography>
            {(data?.override || isOnLeave) && (
              <Tags
                tags={isOnLeave ? t('ON_LEAVE') : t('CALENDAR_LABEL_OVERRIDE')}
                variant="pill"
                backgroundColor="#DBE1E6"
                textColor="#344054"
                customClassName="custom-tag"
              />
            )}
          </div>
          {!isOnLeave && newSlots.length ? (
            <div className="slots-header">
              <div className="location-header">
                <Typography
                  weight="500"
                  size={12}
                  withColor="rgba(86, 95, 112, 0.53)"
                >
                  {t('LOCATION')}
                </Typography>
              </div>
              <div className="timerange-header">
                <div>
                  <Typography
                    weight="500"
                    size={12}
                    withColor="rgba(86, 95, 112, 0.53)"
                  >
                    {t('from')}
                  </Typography>
                </div>
                <div>
                  <Typography
                    weight="500"
                    size={12}
                    withColor="rgba(86, 95, 112, 0.53)"
                  >
                    {t('to')}
                  </Typography>
                </div>
                <div />
              </div>
            </div>
          ) : null}
          <div className="slots-editor">
            {!isOnLeave && (
              <div className="added-slots">
                {newSlots.map(({ timeRange, locationId, index }, i) => {
                  const rowLocation = providerServices.clinics.find(
                    (loc) => loc.id === locationId,
                  );
                  return (
                    <div className="slot-item" key={index}>
                      <div className="location-container">
                        <img
                          src={rowLocation ? inPersonIcon : virtualIcon}
                          alt={
                            rowLocation
                              ? 'in person session'
                              : 'virtual session'
                          }
                        />
                        <div className="location-name">
                          {rowLocation ? rowLocation.name : t('VIRTUAL')}
                        </div>
                      </div>
                      <div className="timerange-items">
                        <TimeRangePicker
                          disabled={isExistingData(timeRange, locationId)}
                          initialValue={timeRange}
                          onChange={(value) => onTimeSlotsValueChange(i, value)}
                        />
                        {!isOldDate && (
                          <img
                            className="remove-slot"
                            src={removeIcon}
                            alt="remove"
                            onClick={() =>
                              onRemoveNewSlot(
                                i,
                                isExistingData(timeRange, locationId),
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {!isOldDate && !isOnLeave && (
              <div className="row-actions">
                <SelectBoxV2
                  onChange={(newValue) =>
                    onSessionTypeSelect(newValue as SessionType)
                  }
                  values={getSessionTypeOptions(providerServices.services || [])}
                  selectedValue=""
                  placeHolderText={t('addTimeSlot')}
                />
                {showChooseLocationModal && (
                  <SelectTimeSlotLocationPopup
                    locations={providerServices.clinics}
                    closeHandler={() => setShowChooseLocationModal(false)}
                    addSlotForLocation={(locationList: string[]) =>
                      onAddTimeSlot(locationList)
                    }
                  />
                )}
              </div>
            )}
            {isOnLeave && !leaveCheckLoading && (
              <Card
                showControls={!isOldDate}
                show={isOnLeave}
                controlClick={handleConfirmationModalState} // add set a state to show confirmation pop up
                cardWidth="75%"
                startingLeaveDate={`${moment
                  .unix(Number(leaveInfo?.leave.start))
                  .format(
                    `${OldChatContactFormat} - ${APIDisplayTimeFormat}`,
                  )}`}
                enditingLeaveDate={`${moment
                  .unix(Number(leaveInfo?.leave.end))
                  .format(
                    `${OldChatContactFormat} - ${APIDisplayTimeFormat}`,
                  )}`}
              />
            )}
            {leaveInfo?.onsite?.status ? (
              <div className="onsite-times-warning">
                <div className="onsite-header">
                  {t('ONSITE_CARD_HEADER', {
                    deploymentStartTime: moment
                      .unix(Number(leaveInfo?.onsite.start))
                      .format(OnsiteWarningTimeFormat),
                    deploymentEndTime: moment
                      .unix(Number(leaveInfo?.onsite.end))
                      .format(OnsiteWarningTimeFormat),
                  })}
                </div>
                <div className="onsite-description">
                  {t('ONSITE_CARD_BODY_1')}
                  <br />
                  {t('ONSITE_CARD_BODY_2')}
                </div>
              </div>
            ) : null}
            <InformationModal
              headerText={
                isOnLeave
                  ? t('MARK_YOURSELF_AS_AVAILABLE')
                  : t('MARK_AS_ON_LEAVE')
              }
              bodyText={
                isOnLeave
                  ? t('MARK_YOURSELF_AS_AVAILABLE_SUBTEXT')
                  : t('MARK_AS_ON_LEAVE_POPUP_SUBTEXT')
              }
              onClose={handleConfirmationModalState}
              show={confirmationModalVisibility}
              leftBtnLabel=""
              rightBtnLabel={
                isOnLeave ? t('MARK_AS_AVAILABLE') : t('MARK_AS_ON_LEAVE')
              }
              modalVariant="single-cta"
              borderRadius="53px"
              rightBtnClick={
                isOnLeave ? handleMarkAvailable : handleMarkOnLeave
              }
            />

            {!!data?.meetings.length && !isFetching && (
              <AppointmentsCard meetings={data.meetings} />
            )}
          </div>
          {showSaveControls && (
            <div className="edit-confirmations">
              <Button
                label={t('CANCEL_CTA')}
                variant="secondary"
                onClick={() => refetchOverrideData()}
                height="45px"
                width="100%"
              />
              <Button
                label={t('SAVE_CTA')}
                variant="blue"
                onClick={() => onConfirmEditedSlots()}
                height="45px"
                width="100%"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DailyOverrideTab;
