import React from 'react';

export const useIsOverflowing = (
  orientation: 'height' | 'width',
  element: React.RefObject<HTMLDivElement>,
  callback?: (hasOverflowed: boolean) => void,
) => {
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  React.useLayoutEffect(() => {
    const trigger = () => {
      const { current } = element;

      if (current) {
        let hasOverflowed = false;

        if (orientation === 'height') {
          hasOverflowed = current.scrollHeight > current.clientHeight;
        } else {
          hasOverflowed = current.scrollWidth > current.clientWidth;
        }

        setIsOverflowing(hasOverflowed);

        if (callback) callback(hasOverflowed);
      }
    };

    trigger();
  }, [callback, element, orientation]);

  return isOverflowing;
};
