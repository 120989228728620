import { useQuery } from 'react-query';

import { IClientSummary } from '../../../../../shared/types/response/provider';
import { getClients } from '../../../../shared/service/API/client';

interface ClientsListResponse {
  conversations: IClientSummary[];
  page: number;
  pageSize: number;
  pages: number;
  total: number;
}

export const useClientsList = ({
  currentPage,
  filters,
  sort,
}: {
  currentPage: number;
  filters: Record<string, string | string[]>;
  sort: Record<string, string>;
}) => {
  const filtersArray = Object.entries(filters).reduce<
    {
      field: string;
      value: string | string[];
    }[]
  >((result, [field, value]) => {
    if (value.length) {
      result.push({ field, value });
    }
    return result;
  }, []);

  return useQuery<ClientsListResponse>({
    queryKey: [currentPage, filters, sort],
    queryFn: () =>
      getClients(
        currentPage,
        filtersArray,
        Object.entries(sort).map(([label, dir]) => ({
          field: label,
          direction: dir,
        })),
      ),
  });
};
