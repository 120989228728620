import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as CalendarWithoutDateIcon } from '../../../../assets/CalendarWithoutDate.svg';
import { HelplineRecordRiskMapping } from '../../../../shared/constant/Helpline';
import {
  ClientProfileDateFormat,
  getTimeFromMilliSeconds,
  HelplineMeetingDateFormat,
  HelplineMeetingJaDateFormat,
  HelplineMeetingJaTimeFormat,
  HelplineMeetingTimeFormat,
} from '../../../../utilities/common/Date';
import InfoTile from '../../../components/InfoTile';
import Modal from '../../../components/Modal';
import MultilineInputWithCounter from '../../../components/MultilineInputWithCounter';
import StatusChip from '../../../components/StatusChip';
import UnOrderedList from '../../../components/UnOrderedList';
import useAuth from '../../../../../utilities/hooks/useAuth';
import { variants } from '../../../../shared/constant/Colors';
import {
  useHelplineNotes,
  useHelplineRecordDetails,
} from '../hooks/UseClientHelplineRecords';
import { getCurrentLocale } from '../../../../../i18n';
import { IDisplayDate } from '../../../../shared/types/Date';
// import {
//   EventActions,
//   EventCategories,
//   EventNames,
// } from '../../../../../shared/constant/Analytics';
// import useTracking from '../../../../../utilities/hooks/useTracking';
import { HelplineRecordSessionType } from '../types/helpline';

interface IHelplineLogDetailsModal {
  selectedIndex: number;
  availableRecords: string[];
  open: boolean;
  handleClose: () => void;
}

function HelplineDataLoader() {
  return (
    <ContentLoader speed={2} height="64px" width="100%">
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="64" />
    </ContentLoader>
  );
}

function HelplineLogDetailsModal({
  selectedIndex,
  availableRecords,
  open,
  handleClose,
}: IHelplineLogDetailsModal) {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();
  const currentLocale = getCurrentLocale();
  const [newComment, setNewComment] = useState('');
  const [currentIndex, setCurrentIndex] = useState(selectedIndex ?? 0);
  // const { track } = useTracking();

  useEffect(() => {
    setCurrentIndex(selectedIndex);
  }, [open, selectedIndex]);

  useEffect(() => {
    setNewComment('');
  }, [open, selectedIndex, currentIndex]);

  const helplineRecordId = availableRecords?.[currentIndex] || '';

  const {
    data: helplineRecordDetails,
    refetch: refetchHelplineDetails,
    isLoading: isRecordLoading,
  } = useHelplineRecordDetails(helplineRecordId);

  const { mutate: addHelplineNote, isLoading: isAddCommentLoading } =
    useHelplineNotes({
      sessionId: helplineRecordId || '',
      onSuccess: () => {
        refetchHelplineDetails();
        setNewComment('');
      },
    });

  const { client, caseNotes, risk, meeting, issue, sessionType } =
    helplineRecordDetails || {};

  const { location, name, organisationName } = client || {};

  const HelplineLogOrgLocationDetails = useMemo(() => {
    const details = [];

    if (organisationName?.name) {
      details.push({
        label: organisationName.name,
      });
    }

    if (location) {
      details.push({
        label: location,
      });
    }

    return details;
  }, [organisationName, location]);

  const getSessionLabelAndVariant: () => {
    label: string;
    variant: keyof typeof variants;
  } = () => ({
    label: t(meeting ? 'booked' : 'matched'),
    variant: meeting ? 'warning' : 'purple',
  });

  const getCommentAuthorLabel = (addedBy: number) => {
    let authorName = '';
    if (addedBy === Number(user.id)) {
      authorName = t('ADDED_BY_ACTOR_PROVIDER', {
        provider_name: user?.name ?? '',
      });
    } else {
      authorName = t('ADDED_BY', {
        actor_name: sessionType === HelplineRecordSessionType.RESPONDER
        ? 'Responder'
        : 'Carenav',
      });
    }

    return (
       /* eslint-disable-next-line */
       <span dangerouslySetInnerHTML={{ __html:authorName }}/>
    );
  };

  const getDisplayDate = ({
    locale,
    selectedDate,
    format,
    translatorResource = { ja: 'DISPLAY_DATE_WITH_MENTION' },
    splitWith = ',',
  }: IDisplayDate): string => {
    const formattedDate = moment(selectedDate).format(
      format[locale] || format.default || HelplineMeetingJaDateFormat,
    );
    const [month, date, year, day] = formattedDate.split(splitWith);
    if (translatorResource) {
      if (
        locale.includes('ja') &&
        (translatorResource?.ja || translatorResource?.common)
      ) {
        const ja = translatorResource?.ja || translatorResource?.common;
        return t(ja as string, { year, month, date, day });
      }
      if (
        locale.includes('en') &&
        (translatorResource?.en || translatorResource?.common)
      ) {
        const en = translatorResource?.en || translatorResource?.common;
        return t(en as string, { year, month, date, day });
      }
    }
    return formattedDate;
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={
        isRecordLoading ? (
          <ContentLoader speed={2} height="32px" width="100%">
            <rect x="0" y="0" rx="3" ry="3" width="40%" height="32" />
          </ContentLoader>
        ) : (
          name
        )
      }
      subtitle={
        isRecordLoading ? (
          <ContentLoader speed={2} height="18" width="100%">
            <rect x="0" y="5" rx="3" ry="3" width="40%" height="18" />
          </ContentLoader>
        ) : (
          <UnOrderedList
            className=""
            liClassName="max-w-[24%]"
            items={HelplineLogOrgLocationDetails}
          />
        )
      }
      className="w-7/12"
      showNavigation
      handlePrevClick={() => {
        setCurrentIndex(
          (currentIndex ?? 0) > 0
            ? (currentIndex ?? 0) - 1
            : availableRecords.length - 1,
        );
      }}
      handleNextClick={() => {
        setCurrentIndex(
          (currentIndex ?? 0) < availableRecords.length - 1
            ? (currentIndex ?? 0) + 1
            : 0,
        );
      }}
      hideLeftNavigation={currentIndex === 0}
      hideRightNavigation={currentIndex === availableRecords.length - 1}
    >
      <section className="flex flex-col gap-4">
        {isRecordLoading ? (
          <ContentLoader speed={2} height="64px" width="100%">
            <rect x="0" y="0" rx="3" ry="3" width="15%" height="64" />
            <rect x="16%" y="0" rx="3" ry="3" width="15%" height="64" />
            <rect x="32%" y="0" rx="3" ry="3" width="15%" height="64" />
          </ContentLoader>
        ) : (
          <section className="w-[80%] flex flex-row items-start gap-1">
            <section className="pr-4 flex flex-col gap-2">
              <div>
                <span className="text-xs text-gray-900">{t('RISK_LEVEL_HEADER')}</span>
              </div>
              <div>
                {!lodash.isNil(risk) ? (
                  <span
                    className={twMerge(
                      'text-md font-semibold',
                      HelplineRecordRiskMapping[risk]?.fontColor ?? '',
                    )}
                  >
                    {t(HelplineRecordRiskMapping?.[risk]?.label) ?? '-'}
                  </span>
                ) : (
                  <span>-</span>
                )}
              </div>
            </section>
            <div className="h-12 border-l border-gray-200" />
            <section className="pl-4 pr-4 flex flex-col gap-2">
              <div>
                <span className="text-xs text-gray-900">{t('status')}</span>
              </div>
              <div>
                <StatusChip
                  label={getSessionLabelAndVariant().label}
                  value="0"
                  variant={getSessionLabelAndVariant().variant}
                />
              </div>
            </section>
            <div className="h-12 border-l border-gray-200" />
            <section className="pl-4 flex flex-col gap-2">
              <div>
                <span className="text-xs text-gray-900">{t('MATCHED_PROVIDER_HEADER')}</span>
              </div>
              <div>
                <span className="text-md text-gray-600 font-semibold">
                  {user?.name ?? ''} (You)
                </span>
              </div>
            </section>
          </section>
        )}

        {isRecordLoading && <HelplineDataLoader />}
        {!isRecordLoading && issue && (
          <section className="flex flex-col gap-1">
            <div className="text-xs text-gray-900 font-medium">
              {t('PRIMARY_PRESENTING_ISSUE')}
            </div>
            <div className="text-sm text-gray-600 font-semibold line-clamp-2">
              {issue}
            </div>
          </section>
        )}
        {isRecordLoading && <HelplineDataLoader />}

        {!isRecordLoading && meeting ? (
          <section className="flex flex-col gap-1">
            <div className="text-xs text-gray-900 font-medium">
              {t('SESSION_BOOKING_DATE')}
            </div>
            <div className="text-sm text-gray-600 font-semibold flex gap-2 items-center line-clamp-2">
              <CalendarWithoutDateIcon />
              {getDisplayDate({
                locale: currentLocale,
                selectedDate: meeting?.scheduledDate,
                format: {
                  en: HelplineMeetingDateFormat,
                  ja: HelplineMeetingJaDateFormat,
                },
                translatorResource: { ja: 'DISPLAY_DATE_WITHOUT_MENTION' },
              })}{' '}
              -{' '}
              {`${getTimeFromMilliSeconds(
                Number(meeting.scheduledStartTime),
                currentLocale === 'ja'
                  ? HelplineMeetingJaTimeFormat
                  : HelplineMeetingTimeFormat,
              )} - ${getTimeFromMilliSeconds(
                Number(meeting.scheduledEndTime),
                currentLocale === 'ja'
                  ? HelplineMeetingJaTimeFormat
                  : HelplineMeetingTimeFormat,
              )}`}
            </div>
          </section>
        ) : null}
        <section className="flex flex-col gap-1">
          <section className="flex flex-col gap-2 max-h-[24vh] overflow-scroll">
            {isRecordLoading || isAddCommentLoading ? (
              <HelplineDataLoader />
            ) : (
              <>
                <div className="text-xs text-gray-900 font-medium">
                  {t('SESSION_NOTES')}
                </div>
                {caseNotes?.map((caseNote) => (
                  <InfoTile
                    lineClamp={2}
                    variant="gray"
                    className="text-justify whitespace-pre-line"
                    key={caseNote?.id}
                    content={caseNote?.note}
                    subContent={
                      <UnOrderedList
                        className="truncate"
                        items={[
                          {
                            label: getCommentAuthorLabel(caseNote?.addedBy),
                          },
                          {
                            label: moment(caseNote?.updatedAt).format(
                              ClientProfileDateFormat,
                            ),
                          },
                        ]}
                      />
                    }
                    expandable
                  />
                ))}
              </>
            )}
          </section>
        </section>
        <MultilineInputWithCounter
          inputData={newComment}
          setInputData={setNewComment}
          handleSubmit={() => {
            // track(EventNames.clientView, {
            //   eventAction: EventActions.click,
            //   eventCategory: EventCategories.helplineLog,
            //   eventLabel: 'add_comment',
            // });
            addHelplineNote(newComment);
          }}
          inputRows={3}
          loading={isRecordLoading || isAddCommentLoading}
          btnLabel={t('ADD_COMMENT')}
          placeholder={t('ADD_COMMENTS')}
        />
      </section>
    </Modal>
  );
}

export default HelplineLogDetailsModal;
