import * as React from 'react';
import { createPortal } from 'react-dom';
import './Popup.scss';
import { motion } from 'framer-motion';
import closeIcon from '../../../assets/close.svg';
import Backdrop from '../Backdrop';
import { BackdropMode } from '../Backdrop/Backdrop';

interface IPopupProps {
  children: React.ReactNode;
  onClose: React.MouseEventHandler;
  withBackdrop?: boolean;
  backdropMode?: BackdropMode;
  showCloseIcon?: boolean;
  useDefaultPadding?: boolean;
  show?: boolean;
}

function Popup({
  children,
  onClose,
  withBackdrop = false,
  showCloseIcon = true,
  backdropMode = 'dark',
  show = true,
  useDefaultPadding = true,
}: IPopupProps) {
  return createPortal(
    <>
      {withBackdrop && show && <Backdrop mode={backdropMode} />}
      <motion.div
        className={`popup-container ${
          useDefaultPadding ? 'with-padding' : ''
        } ${show ? 'show' : ''}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key="popup-modal"
      >
        {showCloseIcon && (
          <img
            className="close-icon"
            src={closeIcon}
            alt="close"
            onClick={onClose}
          />
        )}
        {children}
      </motion.div>
    </>,
    document.body,
  );
}

export default Popup;
