import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import RiskLevelEditIcon from './RiskLevelEditIcon';

interface RiskLabelProps {
  onClick?: () => void;
  isLocked?: boolean;
}

function RiskEmptyState({ onClick, isLocked = false }: RiskLabelProps) {
  const { t } = useTranslation();
  return (
    <section
      className={twMerge(
        'w-full p-3 flex items-center justify-between border-l-2 border-bl-[5px] border-solid rounded-r-lg',
        'bg-gray-50',
        'border-gray-500',
      )}
    >
      <section className={twMerge('w-3/5 text-xs', 'text-gray-900')}>
        {t('RISK_LEVEL_ERROR')}
      </section>
      <RiskLevelEditIcon onClick={onClick} isLocked={isLocked} />
    </section>
  );
}

export default RiskEmptyState;
