import Config from '../../../../shared/Config';
import NetworkClient from '../../../../shared/service/Network';
import { beautifyUrl } from '../../../../utilities/common/Path';
import { CreditCarouselCardTextData } from '../../../view/screens/ClientDetails/constants';
import {
  IAddNotesReqType,
  IAddRiskLevelPayloadType,
} from '../../../view/screens/ClientDetails/types/client';

const client = new NetworkClient();

const errorMessages = {
  generic: 'Unable to fetch client details, please try again!',
};

export const getClients = async (
  page: number,
  filters: { field: string; value: string | string[] }[],
  sort: { field: string; direction: string }[],
) => {
  const response = await client.doPost(Config.apiV2.client.list, {
    page,
    filters,
    sort,
  });
  if (response && response.data) {
    return response.data;
  }

  throw new Error('Unable to get clients list, please try again!');
};

export const getClientDetail = async (clientId: string, role?: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.client.detail, [clientId]),
    {},
    {
      role,
    },
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const getClientNotes = async (clientId: string, role?: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.client.notes, [clientId]),
    {},
    {
      role,
    },
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const addClientNote = async (
  clientId: string,
  payload: IAddNotesReqType,
) => {
  const response = await client.doPost(
    beautifyUrl(Config.apiV2.client.notes, [clientId]),
    payload,
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const editClientNote = async (
  clientId: string,
  noteId: string,
  payload: Pick<IAddNotesReqType, 'note'>,
) => {
  const response = await client.doPatch(
    beautifyUrl(Config.apiV2.client.editNotes, [clientId, noteId]),
    payload,
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const getClientRiskLevels = async (clientId: string, role?: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.client.riskLevels, [clientId]),
    {},
    {
      role,
    },
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const addClientRiskLevel = async (
  clientId: string,
  payload: IAddRiskLevelPayloadType,
) => {
  const response = await client.doPost(
    beautifyUrl(Config.apiV2.client.riskLevels, [clientId]),
    payload,
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const editClientRiskLevel = async (
  clientId: string,
  riskId: string,
  payload: Pick<IAddRiskLevelPayloadType, 'risk'>,
) => {
  const response = await client.doPatch(
    beautifyUrl(Config.apiV2.client.editRiskLevels, [clientId, riskId]),
    payload,
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const getClientEmergencyContacts = async (clientId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.client.emergencyContacts, [clientId]),
    {},
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const getClientAssignments = async (clientId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.assignments.getClientAssignments, [clientId]),
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const sendAssignmentReminder = async (
  clientId: string,
  assignmentId: string,
) => {
  const response = await client.doPost(
    beautifyUrl(Config.api.assignments.sendReminder, [assignmentId, clientId]),
    {},
  );
  if (response && response.success) {
    return 'success';
  }

  throw new Error(response.error?.message, {
    cause: response.error?.statusCode,
  });
};

export const getHelplineRecords = async (clientId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.helpline.getHelplineRecords, [clientId]),
  );
  if (response && response.data && response.data.result) {
    const result = response.data.result[0];
    if (result?.sessions) {
      return result?.sessions;
    }

    return [];
  }

  throw new Error(errorMessages.generic);
};

export const getHelplineDetails = async (sessionId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.helpline.getHelplineRecordDetails, [sessionId]),
  );
  if (response && response.success) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const addHelplineRecordComment = async (
  sessionId: string,
  comment: string,
) => {
  const body = { note: comment };
  const response = await client.doPost(
    beautifyUrl(Config.api.helpline.addHelplineNote, [sessionId]),
    body,
  );
  if (response && response.success) {
    return true;
  }

  throw new Error(errorMessages.generic);
};

export const getClientBenefits = async (clientId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.client.benefits, [clientId]),
  );

  const currentlySupportedCreditCarouselRoles = Object.keys(
    CreditCarouselCardTextData,
  );
  if (response && response.data) {
    return Object.values(response.data?.creditMap || {}).filter((item: any) =>
      currentlySupportedCreditCarouselRoles.includes(item.type),
    );
  }

  throw new Error(errorMessages.generic);
};
