import * as React from 'react';
import { motion } from 'framer-motion';
import Typography from '../Typography';
import './Button.scss';
import { FontWeights } from '../Typography/Typography';

export interface IButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  label: string | React.ReactNode;
  variant:
    | 'primary'
    | 'secondary'
    | 'raised'
    | 'caution'
    | 'blue'
    | 'save'
    | 'label'
    | 'info'
    | 'sos'
    | 'hyperlink'
    | 'inperson-default'
    | 'inperson-complete';
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  icon?: string;
  width?: string;
  height?: string;
  fontSize?: number;
  fontWeight?: FontWeights;
  backgroundColor?: string;
  minWidth?: string;
  minHeight?: string;
  disableAnimation?: boolean;
  cursor?: 'pointer' | 'not-allowed' | 'none' | 'auto';
  breakWord?: 'keep-all' | 'break-all';
}

function Button(
  {
    label,
    variant,
    icon,
    backgroundColor,
    fontSize = 12,
    onClick = () => {},
    disabled = false,
    width = '130px',
    height = '32px',
    fontWeight = '600',
    minWidth = '180px',
    minHeight = '32px',
    style,
    disableAnimation = false,
    cursor = 'pointer',
    breakWord,
  }: IButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const getStyles = () => {
    const styles: React.CSSProperties = {
      height,
      width,
      minWidth,
      minHeight,
      cursor,
      ...style,
    };

    if (backgroundColor) {
      styles.backgroundColor = backgroundColor;
    }

    return styles;
  };

  return (
    <motion.button
      whileHover={{ scale: disableAnimation ? 1 : 1.025 }}
      whileTap={{ scale: disableAnimation ? 1 : 0.975 }}
      type="button"
      className={`button-container ${variant}`}
      onClick={disabled ? () => {} : onClick}
      disabled={disabled}
      ref={ref}
      style={getStyles()}
    >
      {['raised', 'inperson-complete'].includes(variant) && (
        <img src={icon} className="icon" alt="filter" />
      )}
      <Typography
        size={fontSize}
        weight={fontWeight}
        color={
          variant === 'primary' ||
          variant === 'blue' ||
          variant === 'inperson-default' ||
          variant === 'inperson-complete'
            ? 'light'
            : 'primary'
        }
        breakWord={breakWord}
      >
        {label}
      </Typography>
    </motion.button>
  );
}

export default React.forwardRef(Button);
