/* eslint-disable */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MentionsInput, Mention, SuggestionDataItem } from 'react-mentions';
import './TextEditor.scss';
import Typography from '../Typography';

interface ITextEditor {
  onTextValueChange: (value: string) => void;
  defaultTemplate: string;
  parametersValue: SuggestionDataItem[];
  maxCharacterLimit?: number;
  disabled?: boolean;
  onDisabledClick?: () => void;
  className?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  placeHolderText?: string;
  showMaxCharText?: boolean;
  inputType?: 'number' | 'text';
}

function TextEditor({
  onTextValueChange,
  defaultTemplate,
  parametersValue,
  maxCharacterLimit,
  disabled = false,
  onDisabledClick = () => {},
  className = '',
  onFocus = () => {},
  onBlur = () => {},
  placeHolderText = 'PLACEHOLDER_TEXT',
  showMaxCharText = true,
  inputType = 'text',
}: ITextEditor) {
  const { t } = useTranslation();
  const [textValue, setTextValue] = React.useState(defaultTemplate);
  const suggestionPortalRef = React.useRef<HTMLElement>(null);
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const onTextChange = (
    event: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
  ) => {
    let final_val = newValue;
    if (inputType === 'number') {
      final_val = final_val.replace(/[^0-9]/g, '');
    }
    if (maxCharacterLimit && final_val?.length > maxCharacterLimit) {
      final_val = final_val.slice(0, maxCharacterLimit);
    }
    final_val = final_val.trimStart();
    setTextValue(final_val);
    onTextValueChange(final_val);
  };

  React.useEffect(() => {
    if (disabled && inputRef.current) {
      inputRef.current.style.pointerEvents = 'none';
    } else if (!disabled && inputRef.current) {
      inputRef.current.style.pointerEvents = 'all';
    }
  }, [disabled]);

  React.useEffect(() => {
    if (defaultTemplate !== textValue) {
      setTextValue(defaultTemplate);
    }
  }, [defaultTemplate]);

  return (
    <article
      className={`editor-container ${className}`}
      ref={suggestionPortalRef}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <section
        className="editor-input-wrapper"
        onClick={() => {
          if (disabled) onDisabledClick();
        }}
      >
        <MentionsInput
          value={textValue}
          onChange={onTextChange}
          className="mentions"
          suggestionsPortalHost={suggestionPortalRef.current as Element}
          inputRef={inputRef}
          placeholder={t(placeHolderText)}
        >
          <Mention
            trigger="@"
            appendSpaceOnAdd
            data={parametersValue}
            markup="@{__display__}"
            displayTransform={(id, display) => {
              return `@${display}`;
            }}
            className="mentions__mention"
          />
        </MentionsInput>
      </section>
      {!disabled && maxCharacterLimit && (
        <section className="char-count-container">
          <Typography size={12} color="secondary">
            <span
              className={
                textValue?.length === maxCharacterLimit ? 'highlight-count' : ''
              }
            >
              {`${showMaxCharText ? t('MAX_CHARACTERS') : ''} ${
                textValue?.length
              } / ${maxCharacterLimit}`}
            </span>
          </Typography>
        </section>
      )}
    </article>
  );
}

export default TextEditor;
