import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './PersonalInsightsResults.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Typography from '../Typography';
import { IPersonalInsightsResults } from './types';
import RecommendedAssignmentCard from '../RecommendedAssignmentCard';
import { getImageUri } from '../../../utilities/common/Display';
import ChartWidget from '../Charts/ChartWidget';
import GraphDataContext from '../../../shared/context/GraphDataContext';
import { IRecommendedRespType } from '../GoalsCard/types';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';

function PersonalInsightsResults({
  dimensionHeading,
  dimensionDesc,
  score,
  difference,
  recommendedAssignments,
  goalDescription,
  relevancyText,
  source,
}: IPersonalInsightsResults) {
  const { t } = useTranslation();
  const { track } = useTracking();

  const { traitData, onAssignClick, userId } =
    React.useContext(GraphDataContext);

  const handleAssignCTA = (recommedation: IRecommendedRespType) => {
    const tempArr = recommedation?.deepLink?.split('/').slice(-1);
    onAssignClick({
      assignmentType: 'internal',
      assignment: {
        entityId: tempArr[0],
        type: recommedation?.contentType,
      },
      clientId: userId,
      note: '',
    });
    track(EventNames.providerWebAssignment, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.recommendedAssignments,
      eventLabel: 'send_assignment',
      eventValue: recommedation.title,
      featureVersion: 'v1',
      eventSource: source,
    });
  };

  return (
    <article className="pi-wrapper">
      <section className="dimension-header">
        <Typography size={24} weight="600" withColor="#1A2631">
          {dimensionHeading}
        </Typography>
        <Typography size={16} color="secondary">
          {dimensionDesc}
        </Typography>
      </section>
      <section className="dimension-chart">
        <Typography size={16} weight="500" color="secondary">
          Score
        </Typography>
        <section className="current-score">
          <Typography size={24} weight="600" withColor="#1A2631">
            {score} / 100
          </Typography>
          <span
            className={`score-icon-wrapper ${
              difference > 0 ? 'increasing' : 'decreasing'
            }`}
          >
            {difference !== 0 && (
              <FontAwesomeIcon
                icon={difference > 0 ? faArrowUp : faArrowDown}
              />
            )}
            {difference !== 0 && Math.abs(difference)}
          </span>
        </section>
        {traitData?.widgetData?.graphData && (
          <section className="chart-container">
            <ChartWidget
              isLoading={false}
              type="LineChart"
              chartData={traitData?.widgetData?.graphData}
              props={{
                fill: true,
                backgroundColor: '#FFC99633',
                borderColor: '#FFA958',
                max: 100,
                responsive: true,
                stepSize: 40,
              }}
              height="287px"
            />
          </section>
        )}
      </section>
      <section className="dimension-desc">
        <Typography size={16} weight="600" withColor="#1A2631">
          {relevancyText}
        </Typography>
        <Typography size={16} color="secondary">
          {goalDescription}
        </Typography>
      </section>
      <section className="score-desc">
        <section>
          <Typography size={14} color="secondary">
            Low
          </Typography>
          <Typography size={14} weight="600" withColor="#E25C3A">
            0-40
          </Typography>
        </section>
        <section>
          <Typography size={14} color="secondary">
            Moderate
          </Typography>
          <Typography size={14} weight="600" withColor="#FFA958">
            41-80
          </Typography>
        </section>
        <section>
          <Typography size={14} color="secondary">
            High
          </Typography>
          <Typography size={14} weight="600" withColor="#0AA65D">
            81-100
          </Typography>
        </section>
      </section>
      <section className="assignment-section">
        <Typography size={16} weight="600" withColor="#1A2631">
          {t('RECOMMENDED_ASSIGNMENTS')}
        </Typography>
        {recommendedAssignments?.length && (
          <RecommendedAssignmentCard
            key={recommendedAssignments[0]._id}
            imgSrc={getImageUri(recommendedAssignments[0]?.image)}
            tags={[recommendedAssignments[0]?.type]}
            handleAssign={() => handleAssignCTA(recommendedAssignments[0])}
            title={
              recommendedAssignments[0].translationKeys[
                recommendedAssignments[0].title
              ]
            }
            customClassname="overlay-assignment"
            type={recommendedAssignments[0].contentType}
          />
        )}
      </section>
    </article>
  );
}

export default PersonalInsightsResults;
