import { twMerge } from 'tailwind-merge';

export interface UnorderedListProps {
  items: {
    label: string | JSX.Element;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
  }[];
  className?: string;
  liClassName?: string;
  skipFirstItemIcon?: boolean;
}
export default function UnOrderedList({
  items,
  className,
  liClassName,
  skipFirstItemIcon = true,
}: UnorderedListProps) {
  return (
    <ul
      className={twMerge(
        'text-xs text-gray-600 font-medium list-disc inline-flex w-full',
        className,
      )}
    >
      {items.map(({ label, startIcon, endIcon }, idx: number) => (
        <li className={twMerge('list-none', liClassName)}>
          <section className="flex items-center w-full">
            {idx !== 0 || !skipFirstItemIcon ? (
              <span className="text-gray-500">・</span>
            ) : null}
            <section className="flex items-center gap-1 min-w-0">
              {startIcon}
              <section className="truncate">{label}</section>
              {endIcon}
            </section>
          </section>
        </li>
      ))}
    </ul>
  );
}
