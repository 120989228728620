import * as React from 'react';
import './InformationModal.scss';
import { ISOSNumber } from './types';
import Popup from '../../../../components/Popup';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/Button';

function InformationModal({
  headerText,
  bodyText,
  onClose,
  show,
  icon,
  leftBtnLabel,
  rightBtnLabel,
  leftBtnClick,
  leftBtnVariant = 'blue',
  iconHeight = '16px',
  iconWidth = '16px',
  rightBtnClick,
  rightBtnVariant = 'blue',
  customBtnContainerStyle = '',
}: ISOSNumber) {
  return (
    <Popup withBackdrop show={show} useDefaultPadding={false} onClose={onClose}>
      <article className="info-wrapper">
        <section className="info-header">
          <Typography size={20} weight="600">
            {headerText}
          </Typography>
        </section>
        <section className="info-body">
          {icon && (
            <section className="info-modal-icon ">
              <img
                height={iconHeight}
                width={iconWidth}
                src={icon}
                className="sos-icon"
                alt="information-icon"
              />
            </section>
          )}
          <Typography size={14}>{bodyText}</Typography>
        </section>
        <section className={`info-modal-footer ${customBtnContainerStyle} `}>
          <Button
            height="50px"
            width="207"
            variant={leftBtnVariant}
            label={leftBtnLabel}
            onClick={() => leftBtnClick?.()}
            style={{ borderRadius: '10px' }}
            disableAnimation={!leftBtnClick}
            fontSize={18}
            fontWeight="700"
          />
          <Button
            height="50px"
            width="207"
            variant={rightBtnVariant}
            label={rightBtnLabel}
            onClick={() => rightBtnClick?.()}
            style={{ borderRadius: '10px' }}
            disableAnimation={!rightBtnClick}
            fontSize={18}
            fontWeight="700"
          />
        </section>
      </article>
    </Popup>
  );
}

export default InformationModal;
