import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './GoalsCard.scss';
import { IGoalsCard } from './types';
import Tabs from '../../../../view/components/Tabs';
import { TabItem } from '../../../../shared/context/TabsContext';
import Tab from '../../../../view/components/Tab';
import ReportCard from '../ReportCard/ReportCard';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';
import useTracking from '../../../../utilities/hooks/useTracking';
import NoResultsFound from '../../../../view/app-components/NoResultsFound';

function GoalsCard({ width, goalData, onClickLearnMore }: IGoalsCard) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const tabsData: TabItem[] =
    Object.keys(goalData).map((goal, index) => ({
      key: `${t('GOAL')} ${index + 1}`,
      label: goal,
    })) || [];

  const handleGoalChange = (selectedTab: TabItem) => {
    track(EventNames.client_personal_insights, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.client_personal_insights,
      eventLabel: selectedTab.key,
      eventValue: selectedTab.label,
      featureVersion: 'v1',
    });
  };

  return (
    <article className="goals-card-container" style={{ width }}>
      {!!Object.keys(goalData).length && (
        <Tabs
          tabs={tabsData}
          showKey
          textAlign="left"
          onTabChange={handleGoalChange}
        >
          {tabsData.map((tabsName) => (
            <Tab key={tabsName.key} childrenFor={tabsName.key}>
              {goalData?.[tabsName.label].map((dimension) => (
                <ReportCard
                  key={dimension.traitId}
                  reportCartData={dimension}
                  goalName={tabsName.label}
                  onClickLearnMore={onClickLearnMore}
                />
              ))}
            </Tab>
          ))}
        </Tabs>
      )}
      {!Object.keys(goalData).length && (
        <section className="flex items-center justify-center">
          <NoResultsFound
            captionCopy={t('NO_GOALS')}
            description={t('NO_GOALS_DESC')}
            width="300px"
          />
        </section>
      )}
    </article>
  );
}
export default GoalsCard;
