/**
 * Converts single word to sentence case
 * @param word
 * @returns
 */
export const toSentenceCase = (word: string) => {
  if (!word) return word;
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

/**
 * Converts string of multiple words to sentence case
 * @param sentence
 * @returns
 */
export const toSentenceCaseMultipleWords = (sentence: string) => {
  if (!sentence) return sentence;
  return sentence.split(' ').map(toSentenceCase).join(' ');
};
