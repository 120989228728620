import { useTranslation } from 'react-i18next';
import intellectVerifiedIcon from '../../../assets/intellect-verified.png';
import { ReactComponent as SoundWaveIcon } from '../../../assets/sound-wave.svg';
import blueBadgeIcon from '../../../assets/badge-blue.png';
import { getImageUri } from '../../../utilities/common/Display';
import useAuth from '../../../utilities/hooks/useAuth';
import { ProviderAttributes } from '../../../shared/constant/Common';
import { IProviderData } from '../../../shared/types/response/provider';
import getProfileTranslations from '../../../utilities/common/Profile';
import Modal from '../components/Modal';
import { Button } from '../components/Button';

interface ProfilePopupProps {
  handleClose: () => void;
  open: boolean;
  providerData: IProviderData;
}

function ProfilePopup({ handleClose, open, providerData }: ProfilePopupProps) {
  // Custom Hooks
  const { t } = useTranslation();
  const { invalidateUser } = useAuth();

  const { name, bio, attributes } = getProfileTranslations(
    providerData as IProviderData,
  );

  const { profileImage = '', languages = [] } = providerData || {};

  // Functions
  const onLogoutClick = () => {
    invalidateUser();
  };

  return (
    <Modal
      title={t('profile')}
      open={open}
      handleClose={handleClose}
      className="w-[60vw]"
    >
      <div>
        <div className="flex items-start gap-5 mb-5">
          <img src={getImageUri(profileImage)} alt={name} className="w-14" />
          <div className="flex flex-col gap-1 grow">
            <div className="text-displayXs font-bold">{name}</div>
            <div className="text-md text-gray-500 font-medium">
              {t('intellectCoach')}
            </div>
            <div className="flex items-center font-medium gap-2">
              <SoundWaveIcon className="w-5" />
              <div className="text-wrap">
                {languages &&
                  languages.length &&
                  languages.map(
                    (lang: any, index: number) =>
                      `${lang.name}${
                        index !== languages.length - 1 ? ',' : ''
                      } `,
                  )}
              </div>
            </div>
          </div>
          <div className="right-content">
            <img
              src={intellectVerifiedIcon}
              alt="Intellect verified"
              className="w-12"
            />
          </div>
        </div>
        <div className="mb-5">
          <div className="text-xl font-bold mb-2">{t('aboutMe')}</div>
          <div>{bio}</div>
        </div>
        {!!attributes.filter(
          (a) => a.type === ProviderAttributes.PROFESSIONAL_BACKGROUND,
        ).length && (
          <div className="mb-5">
            <div className="text-xl font-bold mb-2">
              {t('professionalBackground')}
            </div>
            {/* // TODO check enum types for attribute types and group them beforehand instead of filter */}
            {attributes
              .filter(
                (a) => a.type === ProviderAttributes.PROFESSIONAL_BACKGROUND,
              )
              .map((item) => (
                <div className="flex items-start gap-2" key={item.id}>
                  <img src={blueBadgeIcon} alt="badge" />
                  <div>{item.name}</div>
                </div>
              ))}
          </div>
        )}
        {!!attributes.filter(
          (a) => a.type === ProviderAttributes.AREA_OF_SPECIALTY,
        ).length && (
          <div>
            <div className="text-xl font-bold mb-1">{t('areaOfSpecialty')}</div>
            <div>
              {attributes
                .filter((a) => a.type === ProviderAttributes.AREA_OF_SPECIALTY)
                .map((item) => item.name)
                .join(', ')}
            </div>
          </div>
        )}
        {!!attributes.filter((a) => a.type === ProviderAttributes.MY_APPROACH)
          .length && (
          <div>
            <div className="text-xl font-bold mb-1">{t('myApproach')}</div>
            <div>
              {attributes
                .filter((a) => a.type === ProviderAttributes.MY_APPROACH)
                .filter((attr) => attr.type === ProviderAttributes.MY_APPROACH)
                .map((item) => item.name)
                .join(', ')}
            </div>
          </div>
        )}
        <Button
          variant="primary"
          className="w-full mt-8"
          onClick={onLogoutClick}
        >
          {t('logout')}
        </Button>
      </div>
    </Modal>
  );
}

export default ProfilePopup;
