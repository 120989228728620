import { useFetch } from '../../../../../utilities/hooks/useFetch';
import { getClientDetail } from '../../../../shared/service/API/client';
import { IClientDetail } from '../types/client';

export function useClientDetailsV2(clientId: string, role?: string) {
  return useFetch<IClientDetail>(
    ['provider', 'clientDetails', clientId, role],
    () => getClientDetail(clientId, role),
    {
      refetchOnWindowFocus: false,
    },
  );
}
