import { Label, Radio, RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as UncheckedRadio } from '../../assets/uncheckedRadio.svg';
import { ReactComponent as CheckedRadio } from '../../assets/checkedRadio.svg';

const radioIconColours = {
  primary: {
    button: 'text-primary-600 border-primary-300 bg-primary-50',
    icon: 'text-primary-600',
  },
} as const;

interface RadioButtonGroupProps {
  selected: string;
  onSelect: (newSelected: string) => void;
  options: {
    label: string;
    value: string;
    colourWhenSelected?: keyof typeof radioIconColours;
  }[];
  disabled?: boolean;
}

export default function RadioButtonGroup({
  selected,
  options,
  onSelect,
  disabled,
}: RadioButtonGroupProps) {
  return (
    <RadioGroup
      value={selected}
      onChange={onSelect}
      className="w-full flex items-center gap-2 flex-wrap"
      disabled={disabled}
    >
      {options.map((option) => (
        <Radio
          key={option.label}
          value={option.value}
          className={({ checked }) =>
            twMerge(
              'min-w-44 px-4 py-3 border border-gray-300 rounded-lg font-medium cursor-pointer transition-colors ',
              checked &&
                radioIconColours[option.colourWhenSelected || 'primary'].button,
              disabled && 'text-gray-300 border-gray-200 cursor-not-allowed',
            )
          }
        >
          {({ checked }) => (
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center">
                <div className="text-sm">
                  <Label>{option.label}</Label>
                </div>
              </div>
              {checked ? (
                <CheckedRadio
                  className={
                    radioIconColours[option.colourWhenSelected || 'primary']
                      .icon
                  }
                />
              ) : (
                <UncheckedRadio />
              )}
            </div>
          )}
        </Radio>
      ))}
    </RadioGroup>
  );
}
