import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import './MessageTemplate.scss';
import { toast } from 'sonner';
import { IMessageTemplateProps } from '../../screens/Settings/types';
import TextEditor from '../TextEditor';
import TemplateHeader from '../MessageTemplateHeader';
import TemplateFooter from '../MessageTemplateFooter';
import { maxCharacterLimit } from '../../../shared/constant/AutomatedChat';

function MessageTemplate({
  selectedTrigger,
  handleToggle,
  handleSaveBtnClick,
}: IMessageTemplateProps) {
  const { t } = useTranslation();

  const [textValueData, setTextValueData] = React.useState<{
    savedValue: string;
    changedValue: string;
  }>({
    savedValue: selectedTrigger?.template,
    changedValue: selectedTrigger?.template,
  });
  const [isEditable, setIsEditable] = React.useState<boolean>(false);
  const paramsValue = selectedTrigger?.settings?.params?.map((item) => ({
    id: item,
    display: item.replace('@{', '').replace('}', ''),
  }));
  const onDisabledEditorClick = () => {
    toast.info(t('TOGGLE_OFF_PROMPT'));
  };

  React.useEffect(() => {
    setTextValueData((prev) => ({
      ...prev,
      savedValue: selectedTrigger?.template,
    }));
  }, [selectedTrigger]);

  return (
    <article
      className={`msg-template-container ${isEditable && 'enabledEditor'} `}
    >
      <AnimatePresence>
        {selectedTrigger?.customEnabled && (
          <TemplateHeader
            onSave={() =>
              handleSaveBtnClick?.(selectedTrigger, textValueData.changedValue)
            }
            showSaveButton={
              !!textValueData.changedValue.length &&
              textValueData.savedValue !== textValueData.changedValue
            }
            headerContent={t('CUSTOMISE_MESSAGE_HEADER')}
          />
        )}
      </AnimatePresence>

      <article className="msg-template-body">
        <TextEditor
          onTextValueChange={(value) => {
            setTextValueData((prev) => ({ ...prev, changedValue: value }));
          }}
          defaultTemplate={selectedTrigger?.template}
          parametersValue={paramsValue}
          maxCharacterLimit={maxCharacterLimit}
          disabled={!selectedTrigger.customEnabled}
          onDisabledClick={onDisabledEditorClick}
          onBlur={() => {
            setIsEditable(false);
          }}
          onFocus={() => {
            setIsEditable(true);
          }}
        />
      </article>
      <TemplateFooter
        selectedTrigger={selectedTrigger}
        handleToggle={handleToggle}
      />
    </article>
  );
}

export default MessageTemplate;
