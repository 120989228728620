/* eslint-disable */
import * as React from 'react';
import _, { debounce, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import closeIcon from '../../../assets/close.svg';
import './ComboBox.scss';
import { IMultiDropdown, IMultiOption, IMultiOptionsData } from './types';
import Typography from '../Typography';
import chevronDownIcon from '../../../assets/chevron-down.svg';
import CheckBox from '../CheckBox';
import searchIcon from '../../../assets/search.svg';
import findIcon from '../../../assets/find_magnifier.svg';
import Button from '../Button';

function instanceOfIMultiOptionsData(data: any): data is IMultiOptionsData {
  return 'label' in data;
}
function instanceOfIMultiOptionsDataList(
  data: any,
): data is IMultiOptionsData[] {
  return data?.length;
}
function ComboBox({
  placeholderText,
  multiDropValues,
  onChange,
  onFocus,
  onBlur,
  menuFontSize = 16,
  menuFontWeight = '600',
  subMenuFontSize = 14,
  subMenuFontWeight = '400',
  isMultiSelect = false,
  defaultSelected,
  limit = 3,
  onEmptyCtaClick,
}: IMultiDropdown) {
  const { t } = useTranslation();
  const [menuVisible, setMenuVisible] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(true);
  const [searchText, setSearchText] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<any>([]);
  const [optionsCategoryMap, setOptionsCategoryMap] = React.useState<any>({});
  const [selectedMenuItem, setSelectedMenuItem] = React.useState<
    IMultiOption[] | IMultiOption | undefined
  >(undefined);

  const [sectionState, setSectionState] = React.useState(
    multiDropValues.map((section) => ({
      ...section,
      isExpanded: false,
      selectedCount: 0,
    })),
  );

  const [selectedCategories, setSelectedCategories] = React.useState<
    Record<string, number>
  >({});

  function updateCategoryCounter(
    counter: any,
    option: IMultiOption,
    label: string,
    action: 'increment' | 'decrement',
  ) {
    if (
      !showIcon(option) &&
      ((!!selectedMenuItem && instanceOfIMultiOptionsData(selectedMenuItem)) ||
        (instanceOfIMultiOptionsDataList(selectedMenuItem) &&
          selectedMenuItem.length === limit))
    ) {
      return;
    }
    switch (action) {
      case 'increment':
        if (counter[label] === undefined) {
          counter[label] = 1;
        } else {
          counter[label] += 1;
        }
        break;

      case 'decrement':
        if (counter[label] !== undefined) {
          counter[label] -= 1;
          if (counter[label] <= 0) {
            delete counter[label];
          }
        }
        break;
    }

    Object.keys(counter).forEach((key) => {
      if (counter[key] <= 0) {
        delete counter[key];
      }
    });
    setSelectedCategories(counter);
  }

  const toggleSection = (index: number) => {
    setSectionState((prevSections) =>
      prevSections.map((section, i) =>
        i === index ? { ...section, isExpanded: !section.isExpanded } : section,
      ),
    );
  };

  function mapOptionsToCategoryLabel() {
    const childToParentMap: Record<string, string> = {};
    dropdownArr.forEach((category: IMultiOptionsData) => {
      category.children.forEach((child: IMultiOption) => {
        childToParentMap[child.value] = category.label; // or use category.id if you prefer
      });
    });
    setOptionsCategoryMap(childToParentMap);
  }

  React.useEffect(() => {
    mapOptionsToCategoryLabel();
    multiDropValues.forEach((item) => {
      const data = item;
      data.children.forEach((option) => {
        const opt = option;
        opt.parentLabel = item.label;
        opt.parentValue = item.value;
        // option = opt;
      });
    });
  }, []);

  React.useEffect(() => {
    const allChildren = _.flatten(
      multiDropValues.map((item) => item?.children),
    );
    if (!defaultSelected) return;
    if (!isMultiSelect && instanceOfIMultiOptionsData(defaultSelected)) {
      setSelectedMenuItem(
        allChildren?.filter(
          (item) => item?.value === defaultSelected.value,
        )?.[0],
      );
    } else if (instanceOfIMultiOptionsDataList(defaultSelected)) {
      const defaultSelectedValue = defaultSelected.map((item) => item.value);
      const filteredValues = allChildren?.filter(
        (item) => !!item && defaultSelectedValue?.includes(item?.value),
      );
      const sortedInOrderData = filteredValues.sort((a, b) => {
        return (
          defaultSelectedValue.indexOf(a.value) -
          defaultSelectedValue.indexOf(b.value)
        );
      });
      setSelectedMenuItem(sortedInOrderData);
    }
  }, [defaultSelected]);

  React.useEffect(() => {
    if (
      !isMultiSelect &&
      !!defaultSelected &&
      instanceOfIMultiOptionsData(defaultSelected)
    ) {
      setSelectedCategories({ [optionsCategoryMap[defaultSelected.value]]: 1 });
    } else {
      if (isArray(defaultSelected) && defaultSelected?.length > 0) {
        const selectedCategoriesObj = defaultSelected.reduce<
          Record<string, number>
        >((acc, item) => {
          if (item.id) {
            acc[optionsCategoryMap[item.id]] =
              (acc[optionsCategoryMap[item.id]] || 0) + 1;
          }
          return acc;
        }, {});
        setSelectedCategories(selectedCategoriesObj);
      }
    }
  }, [defaultSelected]);

  const search = React.useCallback(
    (searchText: string) => {
      if (searchText.trim() === '') {
        setSearchResults(sectionState);
        return;
      }

      const results = sectionState
        .map((section) => ({
          ...section,
          isExpanded: true,
          children: section.children.filter((item) =>
            item.label.toLowerCase().includes(searchText.toLowerCase()),
          ),
        }))
        .filter((section) => section.children.length > 0);
      setSearchResults(results);
    },
    [sectionState],
  );

  const debouncedOnChange = React.useCallback(debounce(search, 300), []);

  const onChangeSearchText = (text: string) => {
    setSearchText(text);
    debouncedOnChange(text);
  };

  const ref =
    React.useRef<HTMLElement>() as React.MutableRefObject<HTMLInputElement>;

  React.useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setMenuVisible(false);
      }
    }
    // Bind
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setMenuVisible]);

  const onSelect = (child: IMultiOption) => {
    if (isMultiSelect && instanceOfIMultiOptionsDataList(selectedMenuItem)) {
      const selectedValues = selectedMenuItem?.filter(
        (item) => item.value !== child.value,
      );
      if (selectedMenuItem?.length !== selectedValues?.length) {
        setSelectedMenuItem(selectedValues);
        onChange(selectedValues);
      } else {
        if (selectedMenuItem.length >= limit) {
          return;
        }
        setSelectedMenuItem([...selectedValues, child]);
        onChange([...selectedValues, child]);
      }
    } else if (isMultiSelect) {
      setSelectedMenuItem([child]);
      onChange([child]);
    } else {
      setMenuVisible(false);
      if (
        !!selectedMenuItem &&
        instanceOfIMultiOptionsData(selectedMenuItem) &&
        selectedMenuItem.id === child.id
      ) {
        onChange({ id: '', value: '', label: '' } as IMultiOption);
        setSelectedMenuItem(undefined);
      } else {
        setSelectedMenuItem(child);
        onChange(child);
      }
    }
  };

  React.useEffect(() => {
    if (isMultiSelect) {
      if (isArray(selectedMenuItem) && selectedMenuItem?.length === limit) {
        setShowSearch(false);
      } else {
        setShowSearch(true);
      }
    } else {
      if (!!selectedMenuItem) {
        setShowSearch(false);
      } else {
        setShowSearch(true);
      }
    }
  }, [selectedMenuItem]);

  const renderPillLabel = (str: string, maxLength: number) =>
    isMultiSelect && str.length > maxLength
      ? `${str.substring(0, maxLength)}...`
      : str;

  const renderPill = (data: IMultiOptionsData) => {
    return (
      <div className="px-[10px] rounded-3xl bg-[#F1F3F6] flex flex-row items-center justify-between my-2 h-8 gap-4 flex-grow text-ellipsis overflow-hidden whitespace-pre-line">
        <span className="text-nowrap self-center text-xs font-normal text-black text-ellipsis overflow-hidden whitespace-pre-line line-clamp-1">
          {`${renderPillLabel(
            `${optionsCategoryMap[data.value]}: ${data.label}`,
            27,
          )}`}
        </span>
        <img
          src={closeIcon}
          className="h-4 w-4 color-[#6C727C] flex-wrap"
          alt="delete"
          onClick={(event: any) => {
            event.stopPropagation();
            onSelect(data);
            updateCategoryCounter(
              selectedCategories,
              data,
              optionsCategoryMap[data.value],
              'decrement',
            );
            if (!isMultiSelect) {
              setSelectedCategories({});
            }
          }}
        />
      </div>
    );
  };

  const labelValue = React.useMemo(() => {
    if (!selectedMenuItem) {
      return placeholderText;
    }
    if (!isMultiSelect && instanceOfIMultiOptionsData(selectedMenuItem)) {
      return (
        <div className="flex-row flex gap-2 text-ellipsis overflow-hidden whitespace-pre-line">
          {renderPill(selectedMenuItem)}
        </div>
      );
    }
    if (isMultiSelect && instanceOfIMultiOptionsDataList(selectedMenuItem)) {
      return (
        <div className="flex-row flex flex-none gap-2 text-ellipsis overflow-hidden whitespace-pre-line">
          {selectedMenuItem.map((item) => renderPill(item))}
        </div>
      );
    }
    return placeholderText;
  }, [selectedMenuItem]);

  const showIcon = (child: IMultiOption) => {
    if (isMultiSelect && instanceOfIMultiOptionsDataList(selectedMenuItem)) {
      return (
        selectedMenuItem.filter((item) => item.value === child.value).length > 0
      );
    } else {
      if (!!selectedMenuItem && instanceOfIMultiOptionsData(selectedMenuItem)) {
        return selectedMenuItem.id === child.id;
      }
    }
    return false;
  };

  const renderEmptyState = () => {
    return (
      <div className="flex items-center justify-between gap-2 flex-col flex-wrap py-4">
        <img src={findIcon} alt="empty" height={40} />
        <span className="text-xl font-semibold text-[#101828]">
          {t('NO_RESULTS_TITLE')}
        </span>
        <span className="text-sm font-normal text-[#475467] mb-5">
          {t('NO_RESULTS_BODY')}
        </span>
        <Button
          label={t('NO_RESULTS_CTA')}
          variant="secondary"
          onClick={() => onEmptyCtaClick && onEmptyCtaClick()}
          height="42px"
          width="240px"
        />
      </div>
    );
  };

  const onSelectOption = (val: IMultiOptionsData, child: IMultiOption) => {
    onSelect(child);
    if (!isMultiSelect && !!selectedMenuItem) {
      setSelectedCategories({});
    }
    updateCategoryCounter(
      selectedCategories,
      child,
      val.label,
      showIcon(child) ? 'decrement' : 'increment',
    );
    setSearchText('');
  };

  const renderCategoryCounter = (val: IMultiOptionsData) => {
    if (
      !isMultiSelect &&
      !!selectedMenuItem &&
      instanceOfIMultiOptionsData(selectedMenuItem) &&
      val.label === selectedMenuItem?.parentLabel
    ) {
      return 1;
    } else if (val.label in selectedCategories) {
      return `${selectedCategories[val.label]}`;
    } else return null;
  };

  const renderMenu = () => {
    return (
      <div>
        {dropdownArr.map((val: IMultiOptionsData, index: number) => (
          <section key={val.value}>
            <section onClick={() => toggleSection(index)} className="menu-item">
              <div className="flex flex-row items-center justify-start gap-2">
                <Typography size={menuFontSize} weight={menuFontWeight}>
                  {val.label}
                </Typography>
                {renderCategoryCounter(val) && (
                  <div className="bg-[#428BB5] px-2 rounded-full h-5 w-5 flex items-center justify-center">
                    <span className="text-center text-white text-xs font-normal">{`${renderCategoryCounter(
                      val,
                    )}`}</span>
                  </div>
                )}
              </div>
              <img src={chevronDownIcon} alt="select" />
            </section>
            {val.isExpanded &&
              val?.children?.map((child) => (
                <motion.section
                  key={child.value}
                  className="submenu-item"
                  initial={{ opacity: 0, top: '-70px' }}
                  animate={{ opacity: 1, top: '50px' }}
                  exit={{ opacity: 0, top: '-70px' }}
                  transition={{ duration: 0.4 }}
                  onClick={() => {
                    onSelectOption(val, child);
                  }}
                >
                  <CheckBox
                    isChecked={showIcon(child)}
                    onChange={() => {
                      onSelectOption(val, child);
                    }}
                  />
                  <Typography size={subMenuFontSize} weight={subMenuFontWeight}>
                    {child.label.replace(/\([^)]*\)/g, '')}
                    <Typography size={12} withColor="#b3b3b3">
                      {child.label.match(/\(([^)]+)\)/g)}
                    </Typography>
                  </Typography>
                </motion.section>
              ))}
          </section>
        ))}
      </div>
    );
  };

  const dropdownArr = searchText.length > 0 ? searchResults : sectionState;
  return (
    <article
      className="multi-dropdown-wrapper"
      role="button"
      tabIndex={0}
      onFocus={() => onFocus?.()}
    >
      <section
        className="placeholder-container"
        onClick={() => setMenuVisible(true)}
      >
        <img src={searchIcon} alt="search" />
        <Typography withColor="#667085" size={14}>
          {labelValue}
        </Typography>
        {showSearch && (
          <motion.input
            type="text"
            className="shrink flex flex-auto h-full bg-transparent outline-none"
            onChange={(e) => onChangeSearchText(e.target.value)}
            value={searchText}
            onBlur={() => {
              // setSearchResults([]);
              // setSearchText('');
            }}
            placeholder={t('SELECT_PI_PROMPT')}
          />
        )}
      </section>

      {menuVisible && (
        <motion.section
          className="dropdown-base"
          ref={ref}
          initial={{ opacity: 0, top: '-70px' }}
          animate={{ opacity: 1, top: '50px' }}
          exit={{ opacity: 0, top: '-70px' }}
          transition={{ duration: 0.4 }}
        >
          {searchText && searchResults.length === 0
            ? renderEmptyState()
            : renderMenu()}
        </motion.section>
      )}
    </article>
  );
}

export default ComboBox;
