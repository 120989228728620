/*eslint-disable */
import * as React from 'react';
import './Billing.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Typography from '../../components/Typography';
import {
  APIDateFormat,
  BillingMonthFormat,
  getAvailableBillingMonthRange,
} from '../../../utilities/common/Date';
import { displayDateFormat } from '../../../utilities/common/Display';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../shared/constant/Analytics';
import Button from '../../components/Button';
import SelectBoxV2 from '../../components/SelectBoxV2';
import { useBillingInfo } from '../../../utilities/hooks/fetchHooks/billing';
import Loader from '../../components/Loader';

const billingHeadings = [
  {
    label: 'date',
    className: 'date',
  },
  {
    label: 'noOfSessions',
    className: 'sessions-count',
  },
  {
    label: 'TOTAL_HOURS',
    className: 'total-hours',
  },
  {
    label: 'TOTAL_PAY',
    className: 'total-pay',
  },
];

function Billing() {
  const { track } = useTracking();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sesssionCategories = [
    {
      label: t('select'),
      value: 'select',
    },
    {
      label: t('coaching'),
      value: '0',
    },
    {
      label: t('clinical'),
      value: '1',
    },
  ];
  const [billingMonth, setBillingMonth] = React.useState<string>(() =>
    moment().format(BillingMonthFormat),
  );
  const [sessionType, setSessionType] = React.useState<number[]>([0, 1]);

  // const { data: billingData, isLoading } = useBillingListing(billingMonth);
  const { data: billSummaryData, isLoading: isBillSummaryLoading } =
    useBillingInfo(billingMonth, sessionType);
  const billingMonths = getAvailableBillingMonthRange();

  const onMonthChange = (month: string) => {
    setBillingMonth(month);
  };

  const onSessionChange = (selectedSession: string) => {
    if (selectedSession === 'select') {
      setSessionType([0, 1]);
      return;
    }
    setSessionType([Number(selectedSession)]);
  };

  React.useEffect(() => {
    track(EventNames.providerDashboard, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.billingInfo,
      eventLabel: 'billing_info',
    });
  }, []);

  return (
    <div className="billings-container">
      {isBillSummaryLoading && <Loader />}
      <div className="billing-header-container">
        <div className="main-heading">
          <Typography weight="600" color="primary" size={24}>
            {t('billingInfo')}
          </Typography>
          <Typography color="primary" size={16}>
            {t('billingDisclaimer')}
          </Typography>
        </div>
      </div>
      <div className="clients-content-container">
        <div className="search-container">
          <div className="search-bar">
            <SelectBoxV2
              selectedValue={billingMonth}
              values={billingMonths.map((v) => ({ value: v, label: v }))}
              onChange={(month) => onMonthChange(month)}
            />
          </div>
          <div className="search-bar">
            <SelectBoxV2
              selectedValue={
                sessionType.length > 1 ? 'select' : sessionType?.[0].toString()
              }
              values={sesssionCategories}
              onChange={(val) => onSessionChange(val)}
            />
          </div>
        </div>
        <div className="client-data-container">
          <div className="client-data">
            <div className="list-headings">
              {billingHeadings.map((heading) => (
                <div key={heading.label} className="heading-item">
                  <Typography size={12} weight="500" color="primary">
                    {t(heading.label)}
                  </Typography>
                </div>
              ))}
            </div>
            <div className="list-wrapper">
              <div className="clients-list">
                {billSummaryData?.data?.sessions?.map((data, index) => (
                  <div className="billing-list-item" key={index}>
                    <div>
                      <Typography size={12} weight="400">
                        {moment(data?.date, APIDateFormat).format(
                          displayDateFormat,
                        )}
                      </Typography>
                    </div>
                    <div>
                      <Typography size={12} weight="400">
                        {data?.sessions}
                      </Typography>
                    </div>
                    <div>
                      <Typography size={12} weight="400">
                        {t('HOURS', {
                          count: Number(data?.duration.toFixed(2)) || 0,
                        })}
                      </Typography>
                    </div>
                    <div className="last-row">
                      <Typography size={12} weight="400">
                        {data?.payment}
                      </Typography>
                      <Button
                        variant="secondary"
                        label={t('details')}
                        onClick={() =>
                          navigate(
                            `/billing/${encodeURIComponent(
                              data?.date,
                            )}/break-up`,
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="clients-list fixed">
                <div className="billing-list-item">
                  <div>
                    <Typography size={14} weight="600">
                      {t('TOTAL')}
                    </Typography>
                  </div>
                  <div>
                    <Typography size={14} weight="600">
                      {billSummaryData?.data?.summary?.count}
                    </Typography>
                  </div>

                  <div>
                    <Typography size={14} weight="600">
                      {t('HOURS', {
                        count:
                          Number(
                            billSummaryData?.data?.summary?.hours.toFixed(2),
                          ) || 0,
                      })}
                    </Typography>
                  </div>
                  <div>
                    <Typography size={14} weight="600">
                      {billSummaryData?.data?.summary?.payment}
                    </Typography>
                  </div>
                </div>
                {billSummaryData?.data?.bonus?.show && (
                  <div className="additional-pay-details">
                    <Typography size={14} weight="600">
                      {t('ADDITIONAL_PAY')}
                    </Typography>
                    <div className="bonus-details">
                      <div>
                        <Typography size={14} weight="600">
                          {t('BONUS')}
                        </Typography>
                      </div>
                      <div>
                        <Typography size={14} weight="600">
                          {billSummaryData?.data?.bonus?.amount}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Billing;
