import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

interface IIUseFetchReturns<T> {
  isLoading: boolean;
  data: T | undefined;
  refetch: Function;
  isSuccess: boolean;
  isError: boolean;
  error: T | unknown;
  isFetching: boolean;
}

// TODO add ts generics for response type
function useFetch<T>(
  queryId: QueryKey,
  query: any,
  options: Omit<
    UseQueryOptions<T, unknown, T, QueryKey>,
    'queryKey' | 'queryFn'
  > = {},
): IIUseFetchReturns<T> {
  const { isLoading, data, refetch, isSuccess, isError, error, isFetching } =
    useQuery<T>(queryId, query, options);

  return { data, isLoading, refetch, isSuccess, isError, error, isFetching };
}

export default useFetch;
