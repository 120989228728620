import ActionTypes from '../../constant/ActionTypes';
import { ClientMeetingData } from '../../types/response/provider';

export const initializeMeeting =
  (data: ClientMeetingData) => (dispatch: Function) => {
    dispatch({
      type: ActionTypes.APP.INITIALIZE_MEETING,
      payload: data,
    });
  };

export const completeMeeting = () => (dispatch: Function) => {
  dispatch({
    type: ActionTypes.APP.COMPLETE_MEETING,
    payload: {},
  });
};

export const resetMeeting = () => (dispatch: Function) => {
  dispatch({
    type: ActionTypes.APP.RESET_MEETING,
    payload: {},
  });
};
