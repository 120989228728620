import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SOSUrls } from '../../../shared/constant/ExternalURLs';
import { IClientSummary } from '../../../shared/types/response/provider';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Popup from '../../components/Popup';
import Typography from '../../components/Typography';
import './SendSOSPopup.scss';
import { useChatBroker } from '../../../chat-module/broker/useChatBroker';
import {
  MessageChannelTypes,
  MessageSendType,
} from '../../../chat-module/constants/CommonConstants';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';
import sosRequestTracker from '../../../utilities/hooks/sosRequestTracker/sosRequestTracker';

interface SendSOSPopupProps {
  onCancel: (isSOSSuccessful: boolean) => void;
  clientData: IClientSummary;
  showCloseIcon?: boolean;
  trackingData?: {
    source: string;
    eventValue?: string;
  };
}

function SendSOSPopup({
  clientData,
  onCancel,
  trackingData,
  showCloseIcon = true,
}: SendSOSPopupProps) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {
    channelExternalId,
    conversationId,
    channelType,
    providerRole,
    providerId,
    userId,
  } = clientData || {};
  const {
    sendMessage,
    liveChatData: { getSelectedUserMessages },
  } = useChatBroker();
  const { logSOSEvent } = sosRequestTracker();

  React.useEffect(() => {
    getSelectedUserMessages({
      selectedConversationId: conversationId as string,
      channelId: channelExternalId as string,
      pageNo: 1,
      channelType: channelType as MessageChannelTypes,
    });
  }, [channelExternalId, conversationId, channelType]);

  const onSendSOS = async () => {
    track(EventNames.sosHelpLine, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.confirm,
      featureVersion: 'v1',
      eventLabel: 'confirm',
      eventSource: trackingData?.source,
      eventValue: trackingData?.eventValue,
    });
    setLoading(true);
    logSOSEvent([
      {
        userId,
        providerId,
        providerRole,
      },
    ]);
    sendMessage({
      contentToSend: {
        data: 'This message contains required information for emergency purpose. Please click to get more information.',
        type: MessageSendType.DEEPLINK,
        deepLink: SOSUrls.emergencySOS,
      },
      conversationId: conversationId as string,
      channelType: channelType as MessageChannelTypes,
    });
    setLoading(false);
    onCancel(true);
  };

  return (
    <Popup
      withBackdrop
      useDefaultPadding={false}
      onClose={() => onCancel(false)}
      showCloseIcon={showCloseIcon}
    >
      <div className="send-sos">
        {isLoading && <Loader useFullPage />}
        <Typography size={24} weight="600" textAlign="center">
          {t('SOS_CASE_NOTES_POPUP')}
        </Typography>
        <Typography color="secondary" size={16} weight="400" textAlign="center">
          {t('SOS_CASE_NOTES_POPUP_DESC')}
        </Typography>
        <div className="send-sos-actions">
          <Button
            height="40px"
            width="43%"
            variant="secondary"
            label={t('CANCEL_CTA')}
            onClick={() => onCancel(false)}
          />
          <Button
            height="40px"
            width="43%"
            variant="blue"
            label={t('CONFIRM_CTA')}
            onClick={onSendSOS}
          />
        </div>
      </div>
    </Popup>
  );
}

export default SendSOSPopup;
