import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import Button from '../../components/Button';
import RadioGroup from '../../components/RadioGroup';
import Typography from '../../components/Typography';
import ClientDetailsCard from '../ClientDetailsCard';
import SessionSelectButton from '../SessionSelectButton';
import './MeetingSlotSelection.scss';
import DatePickerV2 from '../../components/DatePickerV2';
import Loader from '../../components/Loader';
import {
  AvailableSessionDurations,
  IProviderOpenSlotsFull,
} from '../../../shared/types/response/provider';
import SelectList from '../../components/SelectList';
import {
  useBookProviderSessionQuery,
  useproviderClientDataQuery,
} from '../../../utilities/hooks/fetchHooks/provider';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';
import {
  getProviderRole,
  getProviderRolesOptions,
  holisticRoles,
} from '../../../utilities/common/Provider';

interface ISlotSelectionProps {
  selectedClientId: number;
  setCurrentStep?: (step: number) => void;
  isPopUp: boolean;
  providerOpenSlots?: IProviderOpenSlotsFull;
  setIsPopUp?: (value: boolean) => void;
  providerRole?: string;
  name: string;
}

export const selectedColor = 'rgba(134, 162, 188, 0.1)';
function MeetingSlotSelection({
  selectedClientId,
  setCurrentStep,
  isPopUp,
  providerOpenSlots,
  setIsPopUp,
  providerRole,
  name,
}: ISlotSelectionProps) {
  // React Hooks
  const navigate = useNavigate();

  // Custom Hooks
  const { track } = useTracking();

  const { t } = useTranslation();
  const providerRolesOptions = getProviderRolesOptions();

  // React Query
  //* Query to get selected client data
  const { data: clientDetails, isLoading } = useproviderClientDataQuery(
    selectedClientId,
    providerRole as string,
  );

  // Local State
  const [parentDimensions, setParentDimensions] = React.useState<DOMRect>();
  const [selectedDate, setSelectedDate] = React.useState<string>('');
  const [selectedSessionDuration, setSelectedSessionDuration] =
    React.useState<AvailableSessionDurations>('900');
  const [selectedSlot, setSelectedSlot] = React.useState<string>('');
  const [durationList, setDurationList] = React.useState<string[]>([]);
  const [selectedDuration, setSelectedDuration] = React.useState('');
  const [isClinicalunlimited, setIsClinicalunlimited] = React.useState(false);

  //* Query to Book a session
  const providerBookDetails = {
    scheduledStartTime: selectedSlot,
    duration: selectedSessionDuration,
    role: providerRole as string,
    type: '0',
    source: '5',
  };
  const message = t('providerBookingSuccessMessage', { name });
  const { mutate: bookProviderSessionFun, isLoading: isBookSessionLoading } =
    useBookProviderSessionQuery(
      selectedClientId?.toString(),
      providerBookDetails,
      {
        onSuccess: () => {
          track(EventNames.providerBookSession, {
            eventAction: EventActions.click,
            eventCategory: EventCategories.confirmSession,
            eventLabel: 'provider_confirm_session',
          });
          navigate(-1);
          toast.success(message);
          if (setIsPopUp) {
            setIsPopUp(false);
          }
        },
        onError: (error: Error) => {
          const errorMessage = t('providerBookingErrorMessage', {
            clientName: name,
          });
          toast.error(error.message || errorMessage);
          // if (setIsPopUp) {
          //   setIsPopUp(false);
          // }
        },
      },
    );

  // Functions
  //* Function to get parent dimension
  const parentMeasuredRef = React.useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setParentDimensions(node.getBoundingClientRect());
    }
  }, []);

  //* Function to getAvailable Slots
  const getAvailableSlotsDates = () => {
    if (
      providerOpenSlots &&
      selectedSessionDuration &&
      providerOpenSlots[selectedSessionDuration]
    ) {
      return providerOpenSlots[selectedSessionDuration].map(
        (slot) => slot.displayDate,
      );
    }

    return [];
  };

  //* Function to getAvailable Slots
  const getAvailableSlots = () => {
    let slots: { label: string; value: string }[] = [];
    if (
      providerOpenSlots &&
      selectedSessionDuration &&
      providerOpenSlots[selectedSessionDuration] &&
      selectedDate
    ) {
      const availableSlots = providerOpenSlots[selectedSessionDuration];
      slots = availableSlots
        .filter((slot) => slot.displayDate === selectedDate)
        .map(({ displayTime, startTimeUtc }) => ({
          label: displayTime,
          value: startTimeUtc?.toString(),
        }));
    }

    return slots;
  };

  // Component Life Cycle
  const slots = React.useMemo(
    () => getAvailableSlots(),
    [selectedSessionDuration, selectedDate],
  );

  // Component Life Cycle
  React.useEffect(() => {
    if (providerOpenSlots) {
      const clinicalUnlimited = clientDetails?.credits.tier === '1';
      setIsClinicalunlimited(clinicalUnlimited);
    }
  }, [providerOpenSlots, clientDetails]);

  React.useEffect(() => {
    if (
      holisticRoles.includes(
        clientDetails?.providerMapping?.providerRole || '',
      ) &&
      providerOpenSlots
    ) {
      const availableDurationList = Object.keys(providerOpenSlots).filter(
        (item) => item === '1800',
      );
      setDurationList(availableDurationList);
      return;
    }
    if (isClinicalunlimited === false && providerOpenSlots) {
      const availableDurationList = Object.keys(providerOpenSlots).filter(
        (item) => item !== '5400',
      );
      setDurationList(availableDurationList);
    } else if (providerOpenSlots) {
      setDurationList(Object.keys(providerOpenSlots));
    }
  }, [isClinicalunlimited, providerOpenSlots, clientDetails]);

  const setDurationFun = (item: string) => {
    setSelectedSessionDuration(item);
    setSelectedDuration(item);
  };
  // * Function to handle Select Date
  const handleSelectDate = (date: string) => {
    setSelectedDate(date);
    setSelectedSlot('');
  };

  const cnCancelBookingSession = () => {
    if (setCurrentStep) {
      setCurrentStep(1);
    }
  };
  const sessionType = React.useMemo(
    () =>
      providerRolesOptions?.filter(
        (item) =>
          item.value.toLowerCase() ===
          getProviderRole(clientDetails?.providerMapping?.providerRole),
      ),
    [clientDetails],
  );
  //* Show toast message if customer is on hold

  return (
    <div
      className={`p-5 ${isPopUp ? 'w-[60vh] h-[80vh]' : 'm-auto w-[65%]'}`}
      ref={parentMeasuredRef}
    >
      {isLoading && <Loader useFullPage={false} dimension={parentDimensions} />}
      <div className={`mt-6 mb-8 ${isPopUp ? '' : 'w-[60%] m-auto'}`}>
        <Typography
          size={24}
          weight="500"
          color="white"
          textAlign={isPopUp ? 'left' : 'center'}
        >
          {isPopUp ? t('bookSession') : t('slotBookingSession')}
        </Typography>
      </div>
      {isPopUp && (
        <Typography size={14} weight="500" color="primary" textAlign="left">
          {t('clientDetails')}
        </Typography>
      )}
      {clientDetails && <ClientDetailsCard clientData={clientDetails} />}
      <div className="mt-8">
        <Typography size={14} weight="500" color="primary" textAlign="left">
          {t('sessionType')}
        </Typography>
      </div>
      <RadioGroup
        name="languages"
        values={sessionType}
        onChange={() => {}}
        selectedValue={sessionType?.[0]?.value}
        customCss
        duration
      />
      <div className="mt-8">
        <Typography size={14} weight="500" color="primary" textAlign="left">
          {t('sessionDuration')}
        </Typography>
        {/* <Typography
          size={12}
          weight="400"
          withColor="rgba(26, 38, 49, 0.48)"
          textAlign="left"
        >
          For 30 min coaching session it would cost 1 credit, for 60 min
          clinical session its 1 credit
        </Typography> */}
        <div className="flex justify-between gap-1.5">
          {durationList?.map((item) => {
            const buttonName = parseInt(item, 10) / 60;
            return (
              <SessionSelectButton
                key={item}
                buttonName={`${buttonName?.toString()} ${t('minutes')}`}
                onClick={() => setDurationFun(item)}
                selectedDuration={selectedDuration}
                currentElement={item}
              />
            );
          })}
        </div>
      </div>
      <div className="mt-8 flex justify-between">
        <div className={isPopUp ? 'w-full' : 'w-[49%]'}>
          <Typography size={14} weight="500" color="primary" textAlign="left">
            {t('sessionDate')}
          </Typography>
          <DatePickerV2
            onSelect={(date) => handleSelectDate(date)}
            enabledDates={getAvailableSlotsDates()}
          />
        </div>
        {!isPopUp && (
          <div className="w-[49%]">
            <Typography size={14} weight="500" color="primary" textAlign="left">
              {clientDetails?.userInfo?.timeZone}
            </Typography>
            <button
              type="button"
              className="flex flex-col items-center	justify-center mt-3 w-full h-[46px] bg-white rounded-lg border-[rgba(72, 79, 84, 0.2)] cursor-pointer ease-in-out duration-300 hover:scale-105"
            >
              <Typography
                size={14}
                weight="500"
                color="primary"
                textAlign="left"
              >
                {clientDetails?.userInfo?.timeZone}
              </Typography>
            </button>
          </div>
        )}
      </div>
      {slots?.length !== 0 && (
        <div className="form-item fixed-container">
          <Typography size={14} weight="500" color="primary" textAlign="left">
            {t('selectStartTime')}
          </Typography>
          <SelectList
            options={slots}
            selectedValue={selectedSlot}
            onClick={(newSlot) => setSelectedSlot(newSlot)}
            emptyLabel={{
              show: Boolean(selectedDate) && !slots.length,
              label: 'No available slots, please try a different date.',
            }}
          />
        </div>
      )}
      <div
        className={
          isPopUp
            ? 'w-full py-8'
            : 'w-[40%] m-auto flex justify-between mt-[100px] mb-[50px]'
        }
      >
        {!isPopUp && (
          <Button
            label="Cancel"
            onClick={() => cnCancelBookingSession()}
            variant="secondary"
          />
        )}
        <Button
          label={isPopUp ? t('confirmButton') : 'Continue'}
          onClick={() => bookProviderSessionFun()}
          variant="blue"
          width={isPopUp ? '100%' : 'auto'}
          height={isPopUp ? '48px' : 'auto'}
          disabled={!selectedSlot || isBookSessionLoading}
        />
      </div>
    </div>
  );
}

export default MeetingSlotSelection;
