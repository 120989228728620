import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import { Tag } from '../../components/Tag';
import Tooltip from '../../components/Tooltip';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';
import ClientDetailsContext from './context/ClientDetailsContext';

export default function UserTagsCard() {
  const { t } = useTranslation();
  const { clientDetailData, isLoading } = useContext(ClientDetailsContext);

  const clientTags = clientDetailData?.tags ?? [];

  if (isLoading) {
    return <ClientOverviewCardLoader variant="card" />;
  }

  if (!clientTags?.length) return null;

  return (
    <BasicCardTemplate heading={t('tags')} showToolTip={false}>
      <section className="flex flex-wrap gap-2">
        {clientTags.map((tag, index) => (
          <>
            <Tag
              key={tag}
              className="truncate max-w-[165px] text-xs text-center"
              variant="primary"
              tooltipId={`${tag}-${index}`}
            >
              {tag}
            </Tag>
            <Tooltip id={`${tag}-${index}`} content={tag} />
          </>
        ))}
      </section>
    </BasicCardTemplate>
  );
}
