import React from 'react';
import { motion } from 'framer-motion';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import alertCircleIcon from '../../../assets/alert-circle.svg';
import { IReferCarer } from './FollowUpSession.type';
import redirect from '../../../assets/redirect.svg';

function ReferCarer({
  onClick,
  icon,
  btnLabel = 'Inform Intellect',
  description = 'Refer the client to Intellect Care sessions.',
  from = '',
  showRedirectArrow = false,
}: IReferCarer) {
  return (
    <motion.div
      className="high-risk-inform"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      // exit={{ opacity: 0, translateY: '-100%' }}
      transition={{ duration: 0.5 }}
      key={from}
    >
      <section>
        <div className="info-content">
          <img
            src={icon || alertCircleIcon}
            height="16px"
            width="16px"
            alt="info"
          />
        </div>
        <Typography size={14} weight="400">
          {description}
        </Typography>
      </section>
      <section className="inform-intellect">
        <Button
          type="button"
          variant="blue"
          onClick={onClick}
          label={
            <div className="redirect-btn">
              {btnLabel}
              {showRedirectArrow && (
                <img src={redirect} alt="redirect" height={16} width={16} />
              )}
            </div>
          }
          width="161px"
          height="32px"
          minWidth="131px"
          minHeight="40px"
          fontSize={12}
          fontWeight="500"
        />
      </section>
    </motion.div>
  );
}

export default ReferCarer;
