/* eslint-disable @typescript-eslint/no-unused-vars */ // disabling as we have an purposeful unused var for debugging
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  MessageContentTypes,
  MessageContentTypeCssMap,
} from '../../../constants/CommonConstants';
import { ChatSource, IMessageItem } from '../../../../shared/types/Chat';
import {
  OldChatMessageFormat,
  RecentChatMessageFormat,
} from '../../../../utilities/common/Date';
import Typography from '../../../../view/components/Typography';
import AssignmentMessageItem from '../AssignmentMessageItemV2';
import AudioMessageItem from '../AudioMessageItemV2';
import CtaMessageItem from '../CtaMessageItemV2';
import ImageMessageItem from '../ImageMessageItemV2';
import TextMessageItem from '../TextMessageItemV2';
import './ChatMessage.scss';
import { MessageResponseType } from '../../../data-types/ClientTypes';
import { getMessageContentType } from '../../../utils/helper';
import DocumentMessageItem from '../DocumentMessageItem';

export interface IChatMessage {
  message: MessageResponseType;
  source: ChatSource;
  topMessage?: boolean; // just for debugging purposes
  clientId: number;
  updateChatWindowSettings: (settings: any) => void;
  hasAppAccess: boolean;
}

const getFormattedMessagedTime = (messagedDate: moment.Moment): string => {
  const { t } = useTranslation();
  const currentDate = moment();
  const isSameDay = currentDate.startOf('day') <= messagedDate;
  const isYesterday =
    currentDate.subtract('1', 'day').startOf('day') <= messagedDate;
  if (isSameDay) {
    return messagedDate.format(RecentChatMessageFormat);
  }
  if (isYesterday) {
    return t('yesterdayWithTime', {
      time: messagedDate.format(RecentChatMessageFormat),
    });
  }

  return messagedDate.format(OldChatMessageFormat);
};

function ChatMessage(
  {
    message,
    source,
    topMessage,
    clientId,
    updateChatWindowSettings,
    hasAppAccess,
  }: IChatMessage,
  ref: React.ForwardedRef<HTMLDivElement> | null,
) {
  const messageContentType = getMessageContentType(message.attachments);
  const formattedTime = getFormattedMessagedTime(moment(message.createdAt));

  return (
    <div>
      <div className={`chat-message-container ${source}`} ref={ref}>
        <div
          className={`chat-contents ${source} ${MessageContentTypeCssMap[messageContentType]}`}
        >
          {messageContentType === MessageContentTypes.text && (
            <TextMessageItem message={message.message} source={source} />
          )}
          {messageContentType === MessageContentTypes.image && (
            <ImageMessageItem
              imageUrl={message.attachments[0].content}
              body={message.message}
              source={source}
            />
          )}
          {messageContentType === MessageContentTypes.pdf && (
            <DocumentMessageItem
              docUrl={message.attachments[0].content}
              docName="PDF DOCUMENT"
              body={message.message}
              source={source}
            />
          )}
          {messageContentType === MessageContentTypes.audio && (
            <AudioMessageItem
              audioUrl={message.attachments[0].content}
              source={source}
              body={message.message}
            />
          )}
          {messageContentType === MessageContentTypes.assignment &&
            hasAppAccess && (
              <AssignmentMessageItem
                assignmentContent={message.attachments[0].content}
                clientId={clientId}
              />
            )}
          {messageContentType === MessageContentTypes.cta && (
            <CtaMessageItem
              message={message.message}
              ctaContent={message.attachments[0].content}
              source={source}
              updateChatWindowSettings={updateChatWindowSettings}
            />
          )}
        </div>
      </div>
      {messageContentType === MessageContentTypes.assignment &&
      !hasAppAccess ? null : (
        <div
          className={`flex	mb-5 px-2 ${
            source === 'outbound' ? 'justify-end' : 'justify-start'
          }`}
        >
          <Typography weight="400" size={10} breakWord="break-all">
            {formattedTime}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default React.forwardRef(ChatMessage);
