import { useMutation } from 'react-query';
import {
  BookSessionReqType,
  LateCancellationPercentageRespType,
  LateCancellationReqType,
  NoCancellationReqType,
} from '../../../v2/view/app-components/CancellationFlow/types';
import {
  lateCancellationPercentage,
  notifyNoCancellation,
  rescheduleClientSession,
} from '../../../shared/service/API/cancellation';
import { useFetch } from '../useFetch';
// import { IResponse } from '../fetchHooks/provider';

export function useCancellationData({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (err: Error) => void;
}) {
  const { mutate: rescheduleSession, isLoading: isBookingLoading } =
    useMutation(
      ({ body, meetingId }: { body: BookSessionReqType; meetingId: string }) =>
        rescheduleClientSession(body, meetingId),
      {
        onSuccess,
        onError,
      },
    );
  const { mutate: notifyNoCancel, isLoading: isNotifyNoCancelLoading } =
    useMutation((body: NoCancellationReqType) => notifyNoCancellation(body), {
      onSuccess,
      onError,
    });
  return {
    isBookingLoading,
    rescheduleSession,
    notifyNoCancel,
    isNotifyNoCancelLoading,
  };
}

export function useLateCancellationPercent({
  roleId,
  withIn24hrs,
  onError,
  onSuccess,
}: LateCancellationReqType) {
  const { data: queryData, ...rest } =
    useFetch<LateCancellationPercentageRespType>(
      ['lateCancellationPercentage', roleId, withIn24hrs],
      () => lateCancellationPercentage(roleId),
      {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: Boolean(String(roleId) && withIn24hrs),
        onSuccess,
        onError,
      },
    );

  return { data: queryData?.data, ...rest };
}
