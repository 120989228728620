import { twMerge } from 'tailwind-merge';
import { riskColorVariants } from '../../../shared/constant/Colors';
import RiskLevelEditIcon from './RiskLevelEditIcon';

interface RiskLabelProps {
  riskLevel: string;
  subText: string;
  onClick?: () => void;
  variant: keyof typeof riskColorVariants;
  isLocked?: boolean;
}

function RiskLabel({
  riskLevel,
  subText,
  onClick,
  variant,
  isLocked = false,
}: RiskLabelProps) {
  return (
    <section
      className={twMerge(
        'w-full py-3 px-1 flex items-center justify-between border-l-2 border-bl-[5px] border-solid rounded-r-lg',
        riskColorVariants[variant].backgroundColor,
        riskColorVariants[variant].borderColor,
      )}
    >
      <section className="flex flex-col items-start gap-2 ">
        <section
          className={twMerge(
            'text-xs font-semibold list-item ml-[1.3rem]',
            riskColorVariants[variant].textColor,
          )}
        >
          {riskLevel}
        </section>
        <section
          className={twMerge(
            'ml-2 text-xs font-normal',
            riskColorVariants[variant].subTextColor,
          )}
        >
          {subText}
        </section>
      </section>
      <RiskLevelEditIcon
        onClick={onClick}
        isLocked={isLocked}
        iconBgColorClass={riskColorVariants[variant].iconBg}
        iconColor={riskColorVariants[variant].iconColor}
      />
    </section>
  );
}

export default RiskLabel;
