import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as HamburgerIcon } from '../../../assets/hamburger.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/logout.svg';
import { ReactComponent as PersonIcon } from '../../../assets/person.svg';
import { ReactComponent as ClockIcon } from '../../../assets/clock.svg';
import intellectLogo from '../../../assets/IntellectLogo.svg';
import { Menu, MenuItem } from '../../components/Menu';
import { Button } from '../../components/Button';
import LanguageSwitcher from './LanguageSwitcher';
import ProfilePopup from '../ProfilePopup';
import useAuth from '../../../../utilities/hooks/useAuth';
import SosClientList from './SosClientList/SosClientList';
import { getProviderProfileData } from '../../../../shared/redux/actions/provider';
import { IProviderData } from '../../../../shared/types/response/provider';
import { useFetch } from '../../../../utilities/hooks/useFetch';
import { getImageUri } from '../../../../utilities/common/Display';

interface NavbarProps {
  handleOpenSidebar: () => void;
  currentTimeZone: string;
}

export function Navbar({ handleOpenSidebar, currentTimeZone }: NavbarProps) {
  const { t } = useTranslation();

  const { invalidateUser, user } = useAuth();

  const dispatch = useDispatch();
  const { data: providerData, isLoading } = useFetch<IProviderData>(
    ['provider-profile', user.id],
    () => dispatch(getProviderProfileData(user.id)),
  );

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showSosClientList, setShowSosClientList] = useState(false);
  return (
    <div
      className="h-[3.75rem] fixed top-0 z-[8] w-screen bg-white flex justify-between items-center px-8"
      style={{ fontFamily: 'Inter, sans-serif' }} // temporary till we change the global font
    >
      <div className="flex items-center gap-3">
        <div
          role="button"
          className="lg:hidden hover:bg-gray-100 transition-colors"
          tabIndex={0}
          onClick={handleOpenSidebar}
        >
          <HamburgerIcon />
        </div>
        <img src={intellectLogo} alt="intellect logo" className="w-[7.5rem]" />
      </div>
      <div className="flex items-center gap-6">
        <LanguageSwitcher />
        <div className="text-sm flex items-center gap-1">
          <ClockIcon />
          {currentTimeZone}
        </div>
        <Button
          variant="secondary"
          className="border-error-100 bg-error-25 text-error-700 h-8 focus:bg-error-100 hover:bg-error-50"
          onClick={() => setShowSosClientList(true)}
        >
          {t('SOS')}
        </Button>
        <Menu
          render={
            isLoading || !providerData ? (
              <div className="font-semibold text-lg bg-primary-600 w-10 h-10 text-white flex items-center justify-center">
                {user.name?.charAt(0)}
              </div>
            ) : (
              <div className="rounded-full w-10 h-10 overflow-hidden">
                <img
                  src={
                    providerData
                      ? getImageUri(providerData.profileImage)
                      : undefined
                  }
                  width="40px"
                  alt="user-menu"
                />
              </div>
            )
          }
          className="rounded-full overflow-hidden m-r-0"
        >
          <MenuItem
            label={t('logout')}
            icon={
              <LogoutIcon
                className="text-error-500"
                height="0.875rem"
                fontWeight={500}
              />
            }
            className="text-error-500"
            onClick={() => invalidateUser()}
          />
          <MenuItem
            label={t('VIEW_PROFILE')}
            icon={<PersonIcon height="0.875rem" />}
            onClick={() => setShowProfileModal(true)}
          />
        </Menu>
      </div>
      {isLoading || !providerData ? null : (
        <ProfilePopup
          open={showProfileModal}
          handleClose={() => setShowProfileModal(false)}
          providerData={providerData}
        />
      )}

      <SosClientList
        open={showSosClientList}
        handleClose={() => setShowSosClientList(false)}
      />
    </div>
  );
}
