import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Carousel from '../../../../components/Carousel';
import Modal from '../../../../components/Modal';
import { useClientBenefits } from '../../hooks/UseClientBenefits';
import ClientOverviewCardLoader from '../../ClientOverviewCardLoader';
import { ICredit } from '../../types/client';
import { CreditCarouselCardTextData } from '../../constants';
import { ReactComponent as QuestionMarkIcon } from '../../../../../assets/QuestionMark.svg';
import { getClientCreditString } from '../../../../../utilities/common/Clients';

export function CreditDetailsModal({
  open,
  handleClose,
  clientName,
  userId,
}: {
  open: boolean;
  handleClose: () => void;
  clientName: string;
  userId?: string;
}) {
  const { data, isLoading } = useClientBenefits(userId);
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      className="w-[44rem]"
      title={t('POPUP_HEADER', { client_name: clientName })}
    >
      <div className="text-lg font-semibold mb-0.5">
        {t('PLAN_DETAILS_HEADER')}
      </div>
      <div className="text-sm mb-3">{t('PLAN_DETAILS_SUBTEXT')}</div>

      {isLoading || !data ? (
        <ClientOverviewCardLoader variant="card" />
      ) : (
        <Carousel<ICredit>
          data={data}
          renderItem={(item) => {
            const carouselCardDisplayData =
              CreditCarouselCardTextData[
                item.type as keyof typeof CreditCarouselCardTextData
              ];

            const carouselCardCreditData = getClientCreditString(item);

            return (
              <div className="grid grid-rows-9 gap-y-3 p-3 w-44 min-w-44 h-48 text-xs border border-gray-100 bg-gray-25 rounded-lg">
                <div className="row-span-2">{carouselCardDisplayData.icon}</div>
                <div className="row-span-4">
                  <div className="font-medium text-primary-700 mb-1">
                    {t(carouselCardDisplayData.title)}
                  </div>
                  <div>{t(carouselCardDisplayData.subtitle)}</div>
                </div>
                <hr className="row-span-1 text-gray-100 my-2" />
                <div className="row-span-2">
                  <div className="font-semibold flex items-center gap-1">
                    {carouselCardCreditData.creditString}
                    {carouselCardCreditData.isPoolUncapped && (
                      <>
                        <QuestionMarkIcon
                          stroke="#101828"
                          data-tooltip-id="beacon-credit"
                        />
                        <ReactTooltip
                          id="beacon-credit"
                          place="top"
                          content={t('TOOL_TIP_1')}
                          style={{
                            cursor: 'pointer',
                            width: '181px',
                            backgroundColor: '#FCFCFD',
                            color: '#101828',
                            borderRadius: '8px',
                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    {t('VALID_TILL_EXPIRY', {
                      date: carouselCardCreditData.expiresOn,
                    })}
                  </div>
                </div>
              </div>
            );
          }}
        />
      )}
    </Modal>
  );
}
