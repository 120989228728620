import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { IFollowUpSessionsFormSections } from '../../FollowUpSession.type';
import Heading from '../../Heading';
import TextArea from '../../../../components/TextEditor';
import { MAX_CHAR_LIMIT_TEXTAREA } from '../../../../../shared/constant/generics';
import './RiskAssessments.scss';
import { IOptions } from '../../../../../shared/types/CaseNotes';
import ReferCarer from '../../ReferCarer';
import CareReferral from '../../../CareReferral';
import alertCircleIcon from '../../../../../assets/alert-circle.svg';

interface SupportedQuestionProps {
  supportedFieldsOpt?: IOptions[];
  showRefer?: boolean;
  handleRefer?: () => void;
  showSOSSUpport?: boolean;
  handleSOSClick?: () => void;
}

const placeHolderText: { [key: string]: string } = {
  '1': 'SUCIDAL_PROMPT_1',
  '2': 'SUCIDAL_PROMPT_2',
  '3': 'SUCIDAL_PROMPT_3',
  '4': 'SUCIDAL_PROMPT_4',
};

function SupportedQuestion({
  watch,
  setValue,
  supportedFieldsOpt,
  trackOnBlur,
  trackOnFocus,
  handleSOSClick,
  showRefer = false,
  handleRefer = () => {},
  showSOSSUpport = false,
}: IFollowUpSessionsFormSections & SupportedQuestionProps) {
  const suicidalAssessments = watch('suicidalAssessment');
  const { t } = useTranslation();
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        // exit={{ opacity: 0, translateY: '-100%' }}
        transition={{ duration: 0.4 }}
        className="suicidal-assessments-container"
      >
        {showRefer && (
          <section className="suicidal-assessments">
            <ReferCarer
              onClick={handleRefer}
              from="riskLevel"
              btnLabel={t('INFORM_CTA')}
              description={t('REFERRAL_TEXT')}
              showRedirectArrow={false}
            />
          </section>
        )}
        {showSOSSUpport && (
          <CareReferral
            desc={t('SOS_CASE_NOTES_SUBTEXT')}
            icon={alertCircleIcon}
            iconHeight="16px"
            iconWidth="16px"
            fontSize={14}
            fontWeight="400"
            onClick={() => handleSOSClick?.()}
            btnLabel={t('SOS')}
            btnVariant="sos"
            contentWidth="251px"
          />
        )}
        {(supportedFieldsOpt || []).map(({ label, apiValue, id }) => (
          <section
            className={`section ${label} suicidal-assessments`}
            key={`followUpSessions-1-${label}`}
          >
            <Heading headerText={`${label}`} toolTipID="-1" hideTooltipChips />
            <TextArea
              parametersValue={[]}
              defaultTemplate={
                (watch('suicidalAssessment')[id] as string) || ''
              }
              onTextValueChange={(value) => {
                if (setValue)
                  setValue('suicidalAssessment', {
                    ...suicidalAssessments,
                    [id]: value,
                  });
              }}
              maxCharacterLimit={MAX_CHAR_LIMIT_TEXTAREA}
              placeHolderText={
                t(placeHolderText[apiValue || '']) ||
                t('ADD_COMMENTS_PLACEHOLDER')
              }
              onBlur={() => trackOnBlur?.(Number(id))}
              onFocus={trackOnFocus}
              showMaxCharText={false}
            />
          </section>
        ))}
      </motion.div>
    </AnimatePresence>
  );
}

export default SupportedQuestion;
