import * as React from 'react';
import './ChatVoiceRecorder.scss';
import { motion } from 'framer-motion';
import RecordingWave from '../RecordingWaveV2';
import tickIcon from '../../../../assets/tick.svg';
import closeIcon from '../../../../assets/close.svg';
import useAudioRecorder from '../../../../utilities/hooks/useAudioRecorder';
import {
  buttonVariants,
  ButtonVariantTypes,
} from '../../../../configs/animations/variants';

interface IChatVoiceRecorderProps {
  onRecordingEnd: (recordedAudio: Blob) => void;
  onRecordingCancel: () => void;
}

function ChatVoiceRecorder({
  onRecordingEnd,
  onRecordingCancel,
}: IChatVoiceRecorderProps) {
  const { startRecording, stopRecording, audioBlob } = useAudioRecorder();

  const onRecordingStart = () => {
    startRecording();
  };

  const onRecordingStop = () => {
    stopRecording();
    onRecordingEnd(audioBlob);
  };

  React.useEffect(() => {
    onRecordingStart();

    return () => onRecordingStop();
  }, []);

  React.useEffect(() => {
    if (audioBlob) {
      // audioBlob will have data when recording is stopped, using side effect as Blob generation is not immediate
      onRecordingEnd(audioBlob.slice());
      onRecordingCancel();
    }
  }, [audioBlob]);

  return (
    <div className="voice-recorder">
      <motion.div
        className="recorder-control"
        variants={buttonVariants}
        whileTap={ButtonVariantTypes.tap}
        whileHover={ButtonVariantTypes.hover}
      >
        <img
          src={closeIcon}
          className="cancel"
          alt="stop recording"
          onClick={onRecordingCancel}
        />
      </motion.div>
      <RecordingWave />
      <motion.div
        className="recorder-control"
        variants={buttonVariants}
        whileTap={ButtonVariantTypes.tap}
        whileHover={ButtonVariantTypes.hover}
      >
        <img
          src={tickIcon}
          className="confirm"
          alt="confirm voice message"
          onClick={onRecordingStop}
        />
      </motion.div>
    </div>
  );
}

export default ChatVoiceRecorder;
