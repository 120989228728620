import { PropsWithChildren } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as QuestionMarkIcon } from '../../assets/QuestionMark.svg';

interface BasicCardTemplateProps {
  heading: string;
  showToolTip: boolean;
  className?: string;
  tooltipContent?: string;
}
export default function BasicCardTemplate({
  showToolTip,
  className,
  children,
  heading,
  tooltipContent,
}: PropsWithChildren<BasicCardTemplateProps>) {
  return (
    <section
      className={twMerge(
        'w-full bg-white p-4 box-border flex flex-col gap-3 shadow items-start justify-center border border-solid border-gray-200 border-gray-200 rounded-lg ',
        className,
      )}
    >
      <section className="flex items-center gap-1">
        <section className="text-gray-900 text-sm font-medium">
          {heading}
        </section>
        {showToolTip && (
          <>
            <QuestionMarkIcon
              data-tooltip-id={`beacon-${tooltipContent}`}
              stroke="#101828"
            />
            <ReactTooltip
              id={`beacon-${tooltipContent}`}
              place="right"
              content={tooltipContent}
              style={{
                backgroundColor: '#FCFCFD',
                color: '#101828',
                borderRadius: '8px',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              }}
            />
          </>
        )}
      </section>
      {children}
    </section>
  );
}
