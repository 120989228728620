/* eslint-disable react/jsx-props-no-spreading, react/no-unstable-nested-components */
import {
  ChevronProps,
  DayPicker,
  defaultLocale,
  getDefaultClassNames,
} from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { isSameDay } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as ChevronIcon } from '../../assets/rightChevron.svg';
import 'react-day-picker/style.css';

const dayLocaleStrings = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const monthLocaleStrings = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export interface DatePickerProps {
  enabledDays?: string[];
  partiallyEnabledDays?: boolean;
  selected?: Date;
  onSelect: (newDate?: Date) => void;
  classNames?: {
    day?: string;
    today?: string;
    month_grid?: string;
    caption_label?: string;
    weekday?: string;
    disabled?: string;
    selected?: string;
    root?: string;
    chevron?: string;
  };
}

export function DatePicker({
  enabledDays,
  partiallyEnabledDays = false,
  onSelect,
  selected,
  classNames,
}: DatePickerProps) {
  const defaultClassNames = getDefaultClassNames();

  const { t } = useTranslation();

  function isDayDisabled(day: Date) {
    return (
      partiallyEnabledDays &&
      !enabledDays?.some((disabledDay) => isSameDay(day, new Date(disabledDay)))
    );
  }

  return (
    <DayPicker
      mode="single"
      weekStartsOn={1}
      disabled={(day) => isDayDisabled(day)}
      onSelect={onSelect}
      selected={selected}
      defaultMonth={selected}
      components={{
        Chevron: ({ orientation, className, ...props }: ChevronProps) => (
          <ChevronIcon
            {...props}
            className={twMerge(
              'text-gray-400',
              className,
              orientation === 'left' && 'rotate-180',
            )}
            orientation={orientation}
          />
        ),
      }}
      locale={{
        localize: {
          ...defaultLocale.localize,
          day: (day: number) => t(dayLocaleStrings[day]),
          month: (month: number) => t(monthLocaleStrings[month]),
        },
      }}
      classNames={{
        day: twMerge(
          'rounded-lg bg-primary-50 text-gray-700 transition-colors',
          classNames?.day,
        ),
        today: twMerge('text-gray-700', classNames?.today),
        month_grid: twMerge(
          'border-spacing-1 border-separate',
          classNames?.month_grid,
        ),
        disabled: twMerge('bg-white', classNames?.disabled),
        selected: twMerge('bg-primary-600 text-white', classNames?.selected), // Highlight the selected day
        root: twMerge(defaultClassNames.root, 'bg-white', classNames?.root), // Add a shadow to the root element
        chevron: twMerge(
          'text-gray-500 font-light border border-gray-300 rounded-lg',
          classNames?.chevron,
        ), // Change the color of the chevron
        caption_label: twMerge(
          'text-sm font-semibold inline-flex items-center',
          classNames?.caption_label,
        ),
        weekday: twMerge(
          'text-xs font-semibold text-center',
          classNames?.weekday,
        ),
      }}
    />
  );
}
