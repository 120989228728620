import { MeetingStatus } from '../../../shared/constant/Session';
import { ReactComponent as CoachingIcon } from '../../../assets/providerRoleIcons/coaching.svg';
import { ReactComponent as ClinicalIcon } from '../../../assets/providerRoleIcons/clinical.svg';
import { ReactComponent as NutritionIcon } from '../../../assets/providerRoleIcons/nutrition.svg';
import { ReactComponent as FitnessIcon } from '../../../assets/providerRoleIcons/fitness.svg';
import { ReactComponent as FinancialIcon } from '../../../assets/providerRoleIcons/financial.svg';
import { ReactComponent as LegalIcon } from '../../../assets/providerRoleIcons/legal.svg';

export const MeetingStatusTags = {
  '3': 'Cancelled',
  '6': 'User last min cancelled',
  '7': 'Provider no show',
  '8': 'User no show',
  '10': 'Request cancelled',
} satisfies Partial<
  Record<(typeof MeetingStatus)[keyof typeof MeetingStatus], string | null>
>;

export const SessionListPageSize = 10;

export const CreditCarouselCardTextData = {
  '0': {
    icon: <CoachingIcon />,
    title: 'COACHING_CREDITS_COVER_TITLE',
    subtitle: 'COACHING_CARD_SUBTEXT',
  },
  '1': {
    icon: <ClinicalIcon />,
    title: 'CLINICAL_CREDITS_COVER_TITLE',
    subtitle: 'CLINICAL_CARD_SUBTEXT',
  },
  '5': {
    icon: <NutritionIcon />,
    title: 'NUTRITION_CREDITS_COVER_TITLE',
    subtitle: 'NUTRITION_CARD_SUBTEXT',
  },
  '6': {
    icon: <FitnessIcon />,
    title: 'FITNESS_CREDITS_COVER_TITLE',
    subtitle: 'FITNESS_CARD_SUBTEXT',
  },
  '7': {
    icon: <FinancialIcon />,
    title: 'FINANCE_CREDITS_COVER_TITLE',
    subtitle: 'FINANCE_CARD_SUBTEXT',
  },
  '9': {
    icon: <LegalIcon />,
    title: 'LEGAL_CREDITS_COVER_TITLE',
    subtitle: 'LEGAL_CREDITS_SUBTEXT',
  },
};
