import { useTranslation } from 'react-i18next';
import { statusBaseTooltipIconColors } from '../../../shared/constant/Colors';
import { getClientStatus } from '../../../utilities/common/Clients';
import StatusChip from '../../components/StatusChip';

function UserStatusChip({
  clientStatus,
  className,
}: {
  clientStatus: string;
  className?: string;
}) {
  const { t } = useTranslation();

  const { variant, label, tooltipLabel } = getClientStatus(clientStatus);

  return (
    <StatusChip
      value={clientStatus}
      variant={variant}
      label={t(label)}
      tooltipLabel={t(tooltipLabel)}
      iconStroke={statusBaseTooltipIconColors[clientStatus]}
      className={className}
    />
  );
}

export default UserStatusChip;
