import { useFetch } from '../../../../../utilities/hooks/useFetch';
import { getClientBenefits } from '../../../../shared/service/API/client';
import { ICredit } from '../types/client';

export function useClientBenefits(clientId?: string) {
  return useFetch<ICredit[]>(
    ['provider', 'client-benefits', clientId],
    () => getClientBenefits(clientId || ''),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
    },
  );
}
