export type TriageLevel = 0 | 1 | 2 | 3 | 4;

export const HelplineRecordRiskMapping: Record<
  TriageLevel,
  { label: string; fontColor: string }
> = {
  0: {
    label: 'LOW_RISK',
    fontColor: 'text-greenLight-800',
  },
  1: {
    label: 'MEDIUM_RISK',
    fontColor: 'text-warning-800',
  },
  2: {
    label: 'HIGH_RISK_NON_CRITICAL',
    fontColor: 'text-error-800',
  },
  3: {
    label: 'OTHER',
    fontColor: 'text-gray-600',
  },
  4: {
    label: 'HIGH_RISK_CRITICAL',
    fontColor: 'text-rose-800',
  },
};

export const tagContents = ['Self Scheduled', 'EAP Helpline'];

export const tagColors: any = {
  // TODO create type-safe tag system
  'EAP Helpline': {
    fontColor: '#AF6D0A',
    backgroundColor: '#FFE8CC',
  },
  'Self Scheduled': {
    fontColor: '#263E58',
    backgroundColor: '#F8FAFE',
  },
};
