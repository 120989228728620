export type TriageLevel = 0 | 1 | 2 | 3 | 4;

export const TriageLevelData: Record<
  TriageLevel,
  { label: string; fontColor: string; bgColor: string }
> = {
  0: {
    label: 'Low',
    fontColor: '#1D98A8',
    bgColor: '#DCF3F4',
  },
  1: {
    label: 'Medium',
    fontColor: '#BD8026',
    bgColor: '#FFF7EB',
  },
  2: {
    label: 'High: Non-Critical',
    fontColor: '#5467B3',
    bgColor: '#F1F4FE',
  },
  3: {
    label: 'Other',
    fontColor: '#3C566F',
    bgColor: '#E6EDF2',
  },
  4: {
    label: 'High: Critical',
    fontColor: '#AE2638',
    bgColor: '#FDEBED',
  },
};

export const tagContents = ['Self Scheduled', 'EAP Helpline'];

export const tagColors: any = {
  // TODO create type-safe tag system
  'EAP Helpline': {
    fontColor: '#AF6D0A',
    backgroundColor: '#FFE8CC',
  },
  'Self Scheduled': {
    fontColor: '#263E58',
    backgroundColor: '#F8FAFE',
  },
};
