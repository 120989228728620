import { useMutation } from 'react-query';
import { toast } from 'sonner';
import {
  fetchSingleSessionInfo,
  trackInPersonStatus,
} from '../../../shared/service/API/meeting';
import { useFetch } from '../useFetch';
import { ISingleSessionResp } from './types';

function useInpersonInfo(
  meetingId_: string,
  enabled: boolean = true,
  onSuccess?: (
    data: any,
    request: {
      meetingStatus: string;
      meetingId: string;
    },
  ) => void,
) {
  const {
    data: singleSessionData,
    isLoading: singleSessionDataLoading,
    refetch,
  } = useFetch<ISingleSessionResp>(
    ['sigle-session-details', meetingId_],
    () => fetchSingleSessionInfo(meetingId_),
    {
      refetchOnWindowFocus: false,
      enabled,
    },
  );

  const { mutate: updateInPersonStatus, isLoading: inPersonStatusUpdated } =
    useMutation(
      ({
        meetingStatus,
        meetingId,
      }: {
        meetingStatus: string;
        meetingId: string;
      }) => trackInPersonStatus(meetingId, meetingStatus),
      {
        onSuccess: (data, request) => {
          if (enabled) refetch();
          onSuccess?.(data, request);
          console.log('status', data);
        },
        onError: (errorData: Error) => {
          toast.error(errorData.message);
        },
      },
    );
  return {
    singleSessionData,
    singleSessionDataLoading,
    updateInPersonStatus,
    inPersonStatusUpdated,
  };
}

export default useInpersonInfo;
