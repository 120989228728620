import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import moment from 'moment';
import { toast } from 'sonner';
import LayoutContext from '../../../shared/context/LayoutContext';
import RiskAssessment from './components/RiskAssessments';
// import Button from '../../components/Button';
import TextArea from '../../components/TextEditor';
import { MAX_CHAR_LIMIT_TEXTAREA } from '../../../shared/constant/generics';
import Popup from '../../components/Popup';
import FollowUp from './components/FollowUp';
import FollowUpSessionCaseNotesControls from './FollowUpSessionCaseNotes.controls';
import PresentingIssues from './components/PresentingIssues';
import InterventionModality from './components/IntevertionModality';
import ResponseToIntervention from './components/ResponseToIntervention';
import InformationModal from './components/InformationModal';
import Recommendations from './components/Recommendations';
import Heading from './Heading';
import CaseNoteFormHeader from '../../components/CaseNoteFormHeader';
import SupportedQuestion from './components/RiskAssessments/SupportedQuestions';
import alertIcon from '../../../assets/alert-circle.svg';
import NoSaveWarningModal from './components/NoSaveWarningModal';
import {
  CaseNoteQuestionTypes,
  SOSNumbers,
  RiskAssesment,
  CaseNoteFormType,
} from '../../../shared/constant/CoachingCaseNotes';
import {
  IFollowUpSessionsForm,
  IFollowUpSessionsFormSections,
  IWarningModalContent,
} from './FollowUpSession.type';
import './FollowupSessions.scss';
import useCaseNotesDetails, {
  getAllCaseNoteOptions,
} from '../../../utilities/hooks/useCaseNotesDetails/useCaseNotesDetails';
import {
  ICaseNoteQuestionResp,
  ICaseNoteResp,
} from '../../../shared/types/CaseNotes';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../shared/constant/Analytics';
import useTracking from '../../../utilities/hooks/useTracking';
import useInpersonInfo from '../../../utilities/hooks/useInPersonInfo/useInpersonInfo';
import { getInPersonMeetingState } from '../../../utilities/common/Meeting';
import {
  InPersonBannerStateToMeetingStatusMap,
  InPersonBannerStates,
  InPersonBannerValues,
} from '../../../shared/constant/Common';
import Banner from '../../components/Banner';
import greenTickIcon from '../../../assets/circularTick.svg';
import InpersonInformationModal from '../InformationModal';
import { getProviderSessions as _getProviderSessions } from '../../../shared/redux/actions/provider';
import decodeCode from '../../../utilities/common/Encryption';

const defaultValues = {
  riskAssessment: {},
  recommendations: [{}],
  recommendationText: '',
  interventionsModality: {},
  treatmentPlan: '',
  followUpPlanDesc: '',
  response: [{}],
  sessionNotes: '',
  primaryIssue: {},
  secondaryIssue: {},
  additionalSessions: '',
  suicidalAssessment: {},
};
interface FollowUPSessionCaseNotes {
  // mode?: string;
  caseNotesData: ReturnType<typeof useCaseNotesDetails>;
  caseNotesQuestionOptions: ReturnType<typeof getAllCaseNoteOptions>;
  formType: CaseNoteFormType;
  getProviderSessions: Function;
}

function FollowUpSessionCaseNotes({
  caseNotesData,
  caseNotesQuestionOptions,
  formType,
  getProviderSessions,
}: FollowUPSessionCaseNotes) {
  const { meetingId, clientId } = useParams();

  const { t } = useTranslation();
  const { track, trackDuration } = useTracking();
  const navigate = useNavigate();
  const {
    newCaseNoteQuestions,
    newCaseNoteAnswers,
    isCaseNoteAnswersLoading,
    isQuestionaireLoading,
    saveNewcaseNotes: saveCaseNotes,
    hasSuccessfullySaved,
  } = caseNotesData;

  const [initialCaseNoteAnswer, setInitialCaseNoteAnswer] = React.useState<
    Partial<IFollowUpSessionsForm>
  >({});
  const {
    isOptionsLoading,
    riskCategoriesOptions,
    suicidalAssessmentsOptions: supportedQuestionOptions,
    techniqueCategoriesOptions: interventionModalityOptions,
    recommendationCategoriesOptions,
    issuesOptions: presentingIssueOptions,
    responseToInterventionAndTreatmentOptions,
    sharedFollowUpCategoriesOptions,
  } = caseNotesQuestionOptions;

  const [showSoSModal, setShowSoSModal] = React.useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = React.useState<string>('');
  const { updateHeaderSettings, resetNavigation } =
    React.useContext(LayoutContext);
  const [inpersonWarning, setInpersonWarning] = React.useState<boolean>(false);

  const { watch, setValue, getValues, handleSubmit, reset } =
    useForm<IFollowUpSessionsForm>({ defaultValues });
  const hasNoDataModified = (formValue: any) =>
    JSON.stringify(formValue) === JSON.stringify(getValues());
  const handleGoBack = () => {
    if (
      hasNoDataModified(defaultValues) ||
      hasNoDataModified(initialCaseNoteAnswer)
    ) {
      navigate(-1);
    } else {
      setShowWarningModal('goBack');
    }
  };

  React.useEffect(() => {
    if (Object.keys(initialCaseNoteAnswer).length)
      updateHeaderSettings({ onBackButtonClick: handleGoBack });
    return () => {
      resetNavigation();
    };
  }, [initialCaseNoteAnswer]);

  const getInitialData = () =>
    FollowUpSessionCaseNotesControls.getInitialData<IFollowUpSessionsForm>({
      caseNoteAnswerData: newCaseNoteAnswers || ({} as ICaseNoteResp),
      caseNoteQuestionData:
        newCaseNoteQuestions || ({} as ICaseNoteQuestionResp[]),
      riskCategoriesOption: riskCategoriesOptions,
      recommendationCategoriesOption: recommendationCategoriesOptions,
      responseToInterventionOptions: responseToInterventionAndTreatmentOptions,
      primaryPresentingOptions: presentingIssueOptions,
      sharedFollowUpCategoriesOptions,
      interventionModalityOptions,
      suicidalAssessmentOptions: supportedQuestionOptions,
      fromTypes: [CaseNoteFormType.V4],
    });

  React.useEffect(() => {
    if (hasSuccessfullySaved) {
      setInitialCaseNoteAnswer(() => getValues());
    }
  }, [hasSuccessfullySaved]);

  React.useEffect(() => {
    if (newCaseNoteAnswers && newCaseNoteQuestions && !isOptionsLoading) {
      const savedFormData = getInitialData();
      setInitialCaseNoteAnswer(() => savedFormData);
      reset(savedFormData);
    }
  }, [newCaseNoteAnswers, newCaseNoteQuestions, isOptionsLoading]);

  React.useEffect(() => {
    if (
      !(isQuestionaireLoading || isCaseNoteAnswersLoading || isOptionsLoading)
    ) {
      track(EventNames.viewFullSessionCaseNotes, {
        eventAction: EventActions.load,
        eventCategory: EventCategories.viewFullSessionCaseNotes,
        eventLabel: 'view_full_session_case_notes',
        featureVersion: 'v1',
        eventValue: 'clinical',
      });
    }
  }, [isQuestionaireLoading, isCaseNoteAnswersLoading, isOptionsLoading]);

  const [showExtraOptionsForHC, showRefererCareForHNC] = React.useMemo(() => {
    let extraOpt: boolean[] = [false, false];
    extraOpt = [
      RiskAssesment.HighRiskCritical.toString() ===
        watch('riskAssessment')?.value?.toString(),
      RiskAssesment.HighRiskNonCritical.toString() ===
        watch('riskAssessment')?.value?.toString(),
    ];
    return extraOpt;
  }, [watch('riskAssessment')]);
  const warningContent: IWarningModalContent = useMemo(
    () => ({
      noData: t('NO_DATA_PROMPT'),
      goBack: t('WARNING_DESC'),
    }),
    [],
  );

  const caseNotesQuestionsData = React.useMemo(
    () =>
      FollowUpSessionCaseNotesControls.getCaseNotesQuestions<ICaseNoteQuestionResp>(
        newCaseNoteQuestions,
      ),
    [newCaseNoteQuestions],
  );

  const handleSaveButton = () => {
    if (JSON.stringify(defaultValues) === JSON.stringify(getValues())) {
      setShowWarningModal('noData');
    } else {
      // setSubmitted(true)
      const payload = FollowUpSessionCaseNotesControls.saveCaseNoteData(
        getValues(),
        meetingId as string,
        decodeCode(clientId as string).toString(),
        caseNotesQuestionsData,
      );
      track(EventNames.viewFullSessionCaseNotes, {
        eventName: EventNames.viewFullSessionCaseNotes,
        eventCategory: EventCategories.viewFullSessionCaseNotes,
        eventAction: EventActions.click,
        featureVersion: 'v1',
        eventLabel: 'save',
        eventValue: payload,
      });
      if (JSON.stringify(payload) !== '{}') {
        saveCaseNotes(payload);
      } else {
        setShowWarningModal('noData');
      }
    }
  };
  const handleInformIntellect = () => {
    track(EventNames.viewFullSessionCaseNotes, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.viewFullSessionCaseNotes,
      eventLabel: 'refer_client',
      featureVersion: 'v1',
      eventValue: 'critical',
      eventSource: 'assessment',
    });
    setShowSoSModal(true);
  };

  const handleTrackOnClickBackButton = (label: 'remainder' | 'cancel') => {
    track(EventNames.viewFullSessionCaseNotes, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.viewFullSessionCaseNotes,
      eventLabel: label,
      featureVersion: 'v1',
    });
  };

  const handleConfirm = () => {
    if (showWarningModal.includes('goBack')) {
      handleTrackOnClickBackButton('remainder');
      navigate(-1);
    }
  };

  const handleCancel = () => {
    if (showWarningModal.includes('goBack')) {
      handleTrackOnClickBackButton('cancel');
      setShowWarningModal('');
    }
  };

  const handleDuration = () => {
    trackDuration(EventCategories.viewFullSessionCaseNotes);
  };

  const trackQuestionId: IFollowUpSessionsFormSections['trackOnBlur'] = (
    questionId,
  ) => {
    track(EventNames.viewFullSessionCaseNotes, {
      eventLabel: questionId?.toString(),
      eventValue: 'clinical',
      eventAction: EventActions.click,
      eventCategory: EventCategories.questionId,
      featureVersion: 'v1',
    });
  };
  const onUpdateInPersonStatusSuccessFromCasenote = (
    _: any,
    request: {
      meetingStatus: string;
      meetingId: string;
    },
  ) => {
    if (
      request.meetingStatus === InPersonBannerStateToMeetingStatusMap.noShow
    ) {
      toast.success(t('NO_SHOW_SUCCESS'));
    }
    getProviderSessions();
  };
  const { singleSessionData, updateInPersonStatus } = useInpersonInfo(
    meetingId as string,
    true,
    onUpdateInPersonStatusSuccessFromCasenote,
  );

  const inPersonBannerState = React.useMemo(
    () =>
      getInPersonMeetingState({
        singleSessionData,
        enable: [CaseNoteFormType.V3, CaseNoteFormType.V4].includes(formType),
      }),
    [formType, singleSessionData],
  );

  const onInPersonBannerBtnClick = (bannerState: InPersonBannerStates) => {
    track(EventNames.inPersonSession, {
      eventAction: EventActions.click,
      eventCategory: bannerState,
      eventLabel: bannerState,
      featureVersion: 'v1',
      eventSource: EventSource.caseNotes,
    });
    if (
      ![
        InPersonBannerStates.start,
        InPersonBannerStates.noShow,
        InPersonBannerStates.stop,
      ].includes(bannerState)
    ) {
      return;
    }
    if (InPersonBannerStates.stop === bannerState) {
      setInpersonWarning(true);
      return;
    }
    updateInPersonStatus({
      meetingStatus: InPersonBannerStateToMeetingStatusMap[bannerState],
      meetingId: meetingId as string,
    });
  };
  return (
    <>
      {inPersonBannerState !== InPersonBannerStates.unknown && (
        <Banner
          primaryContent={t(
            InPersonBannerValues[inPersonBannerState].bannerContent,
          )}
          onClick={() => onInPersonBannerBtnClick(inPersonBannerState)}
          buttonLabel={t(InPersonBannerValues[inPersonBannerState].btnLabel)}
          bannerVariant="inperson"
          withBgColor={InPersonBannerValues[inPersonBannerState].bannerColor}
          buttonVariant={InPersonBannerValues[inPersonBannerState].btnVariant}
          width={InPersonBannerValues[inPersonBannerState].btnWidth}
          height="52px"
          backgroundColor={InPersonBannerValues[inPersonBannerState].btnColor}
          fontSize={16}
          primaryContentColor=""
          displayBannerCTA={
            InPersonBannerValues[inPersonBannerState].displayBtn
          }
          contentWidth="100%"
          btnIcon={greenTickIcon}
        />
      )}
      <motion.div className="followupSessionCaseNotes">
        <form
          onSubmit={handleSubmit(handleSaveButton)}
          key="followupSessionCaseNotesForm"
        >
          <section className="header">
            <CaseNoteFormHeader
              headerText={t('SESSION', {
                count: newCaseNoteAnswers?.meeting?.count,
              })}
              diplayDate={moment(newCaseNoteAnswers?.meeting?.date).format(
                'DD MMM YYYY',
              )}
            />
          </section>
          <section
            className="section riskAssessment-container"
            key="followUpSession-1"
          >
            <RiskAssessment
              watch={watch}
              trackOnBlur={trackQuestionId}
              trackOnFocus={handleDuration}
              setValue={setValue}
              question={
                caseNotesQuestionsData[CaseNoteQuestionTypes.RiskAssesment]
              }
              riskAssessmentOptions={FollowUpSessionCaseNotesControls.getFormattedOptions(
                riskCategoriesOptions,
              )}
            />
            {(showExtraOptionsForHC || showRefererCareForHNC) && (
              <SupportedQuestion
                watch={watch}
                setValue={setValue}
                supportedFieldsOpt={FollowUpSessionCaseNotesControls.getFormattedOptions(
                  supportedQuestionOptions,
                )}
                trackOnBlur={trackQuestionId}
                trackOnFocus={handleDuration}
                showRefer={showExtraOptionsForHC}
                handleRefer={handleInformIntellect}
              />
            )}
          </section>
          <section
            className="section presentingIssueContainer"
            key="followUpSession-2"
          >
            <PresentingIssues
              meetingId={meetingId as string}
              trackOnBlur={trackQuestionId}
              trackOnFocus={handleDuration}
              watch={watch}
              setValue={setValue}
              primaryOptions={presentingIssueOptions}
              secondaryOptions={presentingIssueOptions}
              primaryQuestion={
                caseNotesQuestionsData[CaseNoteQuestionTypes.PrimaryGoal]
              }
              secondaryQuestion={
                caseNotesQuestionsData[CaseNoteQuestionTypes.SecondaryGoal]
              }
            />
          </section>
          <section className="section observation" key="followUpSession-3">
            <Heading
              headerText={
                caseNotesQuestionsData[CaseNoteQuestionTypes.Observation]
                  ?.question
              }
              toolTipID="3"
            />
            <TextArea
              onFocus={handleDuration}
              onBlur={() => {
                trackQuestionId(
                  caseNotesQuestionsData[CaseNoteQuestionTypes.Observation]?.id,
                );
              }}
              parametersValue={[]}
              defaultTemplate={watch('observation') as string}
              onTextValueChange={(value) => {
                setValue('observation', value);
              }}
              maxCharacterLimit={MAX_CHAR_LIMIT_TEXTAREA}
              placeHolderText={t('CASE_NOTE_OBSERVATION_PLACEHOLDER')}
              showMaxCharText={false}
            />
          </section>
          <section className="section sessionNotes" key="followUpSession-4">
            <Heading
              headerText={
                caseNotesQuestionsData[CaseNoteQuestionTypes.SessionNotes]
                  ?.question
              }
              toolTipID="4"
            />
            <TextArea
              parametersValue={[]}
              onFocus={handleDuration}
              onBlur={() => {
                trackQuestionId(
                  caseNotesQuestionsData[CaseNoteQuestionTypes.SessionNotes]
                    ?.id,
                );
              }}
              defaultTemplate={watch('sessionNotes') as string}
              onTextValueChange={(value) => {
                setValue('sessionNotes', value);
              }}
              maxCharacterLimit={MAX_CHAR_LIMIT_TEXTAREA}
              placeHolderText={t('CASE_NOTE_SESSION_PLACEHOLDER')}
              showMaxCharText={false}
            />
          </section>
          <section
            className="section interventionModality"
            key="followUpSession-5"
          >
            <InterventionModality
              interventionsModalityOptions={FollowUpSessionCaseNotesControls.getFormattedOptions(
                interventionModalityOptions,
              )}
              setValue={setValue}
              watch={watch}
              trackOnBlur={trackQuestionId}
              trackOnFocus={handleDuration}
              question={
                caseNotesQuestionsData[
                  CaseNoteQuestionTypes.InterventionsAndModality
                ]
              }
            />
          </section>
          <section className="section formTp" key="followUpSession-6">
            <Heading
              headerText={
                caseNotesQuestionsData[
                  CaseNoteQuestionTypes.FormulationAndTreatment
                ]?.question
              }
              toolTipID="6"
            />
            <TextArea
              onFocus={handleDuration}
              onBlur={() => {
                trackQuestionId(
                  caseNotesQuestionsData[
                    CaseNoteQuestionTypes.FormulationAndTreatment
                  ]?.id,
                );
              }}
              parametersValue={[]}
              defaultTemplate={watch('treatmentPlan') as string}
              onTextValueChange={(value) => {
                setValue('treatmentPlan', value);
              }}
              maxCharacterLimit={MAX_CHAR_LIMIT_TEXTAREA}
              showMaxCharText={false}
              // @ts-ignore
              // don't try to modify the below without the stake holder
              placeHolderText={`1. ${t('FORMULATION_TREATMENT_PLAN_1')} 
2. ${t('FORMULATION_TREATMENT_PLAN_2')}`}
            />
          </section>
          <section className="section" key="followUpSession-7">
            <ResponseToIntervention
              responseToInterventionOption={FollowUpSessionCaseNotesControls.getFormattedOptions(
                responseToInterventionAndTreatmentOptions,
              )}
              trackOnBlur={trackQuestionId}
              trackOnFocus={handleDuration}
              setValue={setValue}
              watch={watch}
              question={
                caseNotesQuestionsData[
                  CaseNoteQuestionTypes.ResponseToInterventionTreatment
                ]
              }
            />
          </section>
          <section className="section" key="followUpSession-8">
            <Recommendations
              trackOnBlur={trackQuestionId}
              trackOnFocus={handleDuration}
              question={
                caseNotesQuestionsData[CaseNoteQuestionTypes.Recommendations]
              }
              setValue={setValue}
              watch={watch}
              recommendationsOptions={FollowUpSessionCaseNotesControls.getFormattedOptions(
                recommendationCategoriesOptions,
              )}
            />
          </section>
          <section className="section" key="followUpSession-9">
            <FollowUp
              watch={watch}
              trackOnBlur={trackQuestionId}
              trackOnFocus={handleDuration}
              setValue={setValue}
              followUpOptions={FollowUpSessionCaseNotesControls.getFormattedOptions(
                sharedFollowUpCategoriesOptions,
              )}
              primaryQuestionType={
                caseNotesQuestionsData[CaseNoteQuestionTypes.SharedFollowUpPlan]
              }
              secondaryQuestionType={
                caseNotesQuestionsData[CaseNoteQuestionTypes.FollowUpPlan]
              }
            />
          </section>

          <section
            className="section additionalSessions"
            key="followUpSession-10"
          >
            <Heading
              headerText={
                caseNotesQuestionsData[
                  CaseNoteQuestionTypes.AdditonalSessionRecommended
                ]?.question
              }
              toolTipID="10"
            />
            <TextArea
              onFocus={handleDuration}
              className=""
              onBlur={() => {
                trackQuestionId(
                  caseNotesQuestionsData[
                    CaseNoteQuestionTypes.AdditonalSessionRecommended
                  ]?.id,
                );
              }}
              parametersValue={[]}
              defaultTemplate={watch('additionalSessions') as string}
              onTextValueChange={(value) => {
                setValue('additionalSessions', value);
              }}
              maxCharacterLimit={3}
              placeHolderText={t('CASE_NOTE_ADDITIONAL_SESSION_PLACEHOLDER')}
              inputType="number"
              showMaxCharText={false}
            />
          </section>
          <section className="saveButton">
            <motion.input
              whileHover={{ scale: 1.025 }}
              whileTap={{ scale: 0.975 }}
              className="submit-button"
              value={t('SAVE_CTA')}
              type="submit"
            />
          </section>
        </form>
        <InformationModal
          headerText={t('INTELLECT_SOS_NUMBER')}
          bodyText={t('SOS_DESC')}
          onClose={() => setShowSoSModal(false)}
          show={showSoSModal}
          icon={alertIcon}
          rightBtnLabel={SOSNumbers[0]}
          leftBtnLabel={SOSNumbers[1]}
        />
        <Popup
          withBackdrop
          show={['noData', 'goBack'].includes(showWarningModal)}
          useDefaultPadding={false}
          onClose={() => setShowWarningModal('')}
        >
          <NoSaveWarningModal
            header={t('IMPORTANT')}
            subHeader={
              warningContent[showWarningModal as keyof IWarningModalContent]
            }
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            cancelBtnText={t('CANCEL_CTA')}
            confirmBtnText={t('CONFIRM_CTA')}
          />
        </Popup>
        <InpersonInformationModal
          headerText={t('IS_SESSION_OVER')}
          bodyText={t('IN_PERSON_SUBTEXT')}
          onClose={() => setInpersonWarning(false)}
          show={inpersonWarning}
          rightBtnLabel={t('MARK_SESSION_COMPLETE')}
          leftBtnLabel=""
          customBtnContainerStyle="custom-style"
          borderRadius="100px"
          fontSize={14}
          fontWeight="600"
          width="100%"
          height="45px"
          headerFontSize={24}
          contentFontSize={16}
          modalVariant="single-cta"
          noteText={t('IN_PERSON_NOTE')}
          rightBtnClick={() => {
            track(EventNames.inPersonSession, {
              eventAction: EventActions.click,
              eventCategory: EventCategories.endSessionConfirm,
              eventLabel: 'end_session_confirm',
              featureVersion: 'v1',
              eventSource: EventSource.caseNotes,
              eventValue: moment
                .unix(Number(singleSessionData?.scheduledStartTime))
                .diff(moment()),
            });
            updateInPersonStatus({
              meetingStatus: InPersonBannerStateToMeetingStatusMap.stop,
              meetingId: meetingId as string,
            });
            setInpersonWarning(false);
          }}
        />
      </motion.div>
    </>
  );
}

const mapDispatchToProps = (dispatch: Function) => ({
  getProviderSessions: (month: string) => dispatch(_getProviderSessions(month)),
});

export default connect(null, mapDispatchToProps)(FollowUpSessionCaseNotes);
