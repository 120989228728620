import ContentLoader from 'react-content-loader';

function TableLoader() {
  const rectangles = Array.from({ length: 8 }, (_, index) => ({
    id: index,
    x: 0,
    y: index * 85,
    rx: 3,
    ry: 3,
    width: '100%',
    height: 75,
  }));

  return (
    <ContentLoader
      speed={2}
      height="100vh"
      width="100%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {rectangles.map((rect) => (
        <rect
          key={rect.id}
          x={rect.x}
          y={rect.y}
          rx={rect.rx}
          ry={rect.ry}
          width={rect.width}
          height={rect.height}
        />
      ))}
    </ContentLoader>
  );
}

export default TableLoader;
