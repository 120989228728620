import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { Button } from './Button';

export const MultilineInputDefaultMaxLength = 5000;

interface IMultilineInputWithCounter {
  inputData: string;
  setInputData: (newValue: string) => void;
  handleSubmit?: () => void;
  loading?: boolean;
  maxLength?: number;
  inputRows?: number;
  placeholder?: string;
  className?: string;
  btnLabel?: string;
}
function MultilineInputWithCounter({
  inputData = '',
  setInputData,
  handleSubmit,
  className,
  loading = false,
  maxLength = MultilineInputDefaultMaxLength,
  inputRows = 4,
  placeholder = '',
  btnLabel = 'SAVE_CTA',
}: IMultilineInputWithCounter) {
  const { t } = useTranslation();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    // Expanding Text Area Height
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [inputData, textareaRef]);

  const handleClick = () => {
    handleSubmit?.();
  };

  const isCharLimitReached = inputData?.length > maxLength;

  return (
    <div
      className={twMerge(
        'w-full max-h-80 p-2 border-2 rounded-md mt-1 transition-all border-gray-300',
        isCharLimitReached
          ? ' border-error-300 shadow-[0px_0px_0px_4px_#FECDCA]'
          : 'focus-within:border-blueLight-300 focus-within:shadow-[0px_0px_0px_4px_#B2DDFF]',
        className,
      )}
    >
      <textarea
        ref={textareaRef}
        rows={inputRows}
        className="w-full peer resize-none focus:border-none focus:outline-none max-h-60"
        value={inputData}
        onChange={(e) => setInputData(e.target.value)}
        maxLength={maxLength + 1}
        placeholder={placeholder ?? ''}
      />
      <div className="mt-1 flex justify-between items-center transition-all duration-500 ease-in-out transform opacity-0 -translate-y-2  peer-focus:opacity-100 peer-focus:translate-y-0">
        <div
          className={twMerge(
            'text-gray-600 mt-0.5 leading-5 text-xs',
            isCharLimitReached ? 'text-error-400' : 'text-gray-400',
          )}
        >
          {`${inputData?.length}/${maxLength} ${t('CHARACTERS')}`}
        </div>

        {handleSubmit ? (
          <Button
            className="h-8"
            disabled={
              isCharLimitReached || !inputData.toString().trim() || loading
            }
            onClick={() => handleClick()}
          >
            {t(btnLabel)}
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default MultilineInputWithCounter;
