import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as CallIcon } from '../../../../assets/Call.svg';
import { ReactComponent as ChevronRightIcon } from '../../../../assets/rightChevron.svg';
import { HelplineRecordRiskMapping } from '../../../../shared/constant/Helpline';
import { ClientHelplineRecordTimeFormat } from '../../../../utilities/common/Date';
import { Button } from '../../../components/Button';
import DateBadge from '../../../components/DateBadge';
import UnOrderedList, {
  UnorderedListProps,
} from '../../../components/UnOrderedList';
import { HelplineRecord } from '../types/helpline';

interface IHelplineLogCardProps {
  helplineRecord: HelplineRecord;
  className?: string;
  handleClick: (selectedIndex: number) => void;
  index: number;
}
function HelplineLogCard({
  helplineRecord,
  className,
  handleClick,
  index,
}: IHelplineLogCardProps) {
  const { t } = useTranslation();
  const { startTime } = helplineRecord;
  const sessionCardItems: UnorderedListProps['items'] = [
    {
      label: `${moment(helplineRecord?.startTime).format(
        ClientHelplineRecordTimeFormat,
      )}`,
      startIcon: <CallIcon className="w-3 h-3" />,
    },
  ];
  return (
    <section
      className={twMerge(
        'w-full bg-white p-5 box-border flex shadow-sm items-center justify-between border border-solid border-gray-200 rounded-lg ',
        className,
      )}
    >
      <section className="w-full box-border flex items-start gap-4">
        <DateBadge timeStamp={moment(startTime).unix()} />
        <section className="w-[95%] flex flex-col gap-2 ">
          <section className="w-full flex items-center gap-2 ">
            <section className="text-md font-semibold text-gray-700">
              {t(HelplineRecordRiskMapping?.[helplineRecord?.risk]?.label) ||
                `Call ${index + 1}`}
            </section>
          </section>
          <UnOrderedList
            items={sessionCardItems}
            className="leading-[12px] text-sm font-normal"
          />
        </section>
      </section>
      <Button variant="ghost" onClick={() => handleClick(index)}>
        <ChevronRightIcon />
      </Button>
    </section>
  );
}

export default HelplineLogCard;
