import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { useFetch, useMutation } from '../../../../../utilities/hooks/useFetch';
import {
  getClientAssignments,
  sendAssignmentReminder,
} from '../../../../shared/service/API/client';
import { IClientAssignment } from '../types/client';

export function useClientAssignments(clientId?: string) {
  return useFetch<IClientAssignment[]>(
    ['client-details-v2-assignments', clientId],
    () => (clientId ? getClientAssignments(clientId) : undefined),
    {
      enabled: Boolean(clientId),
      refetchOnWindowFocus: false,
    },
  );
}

export function useSendAssignmentReminder(
  handleSuccess: (response: string, assignmentId: string) => void,
  clientId?: string,
) {
  const { t } = useTranslation();
  return useMutation(
    (assignmentId: string) =>
      clientId ? sendAssignmentReminder(clientId, assignmentId) : undefined,
    {
      onSuccess: (response: string, assignmentId: string) => {
        handleSuccess(response, assignmentId);
      },
      onError: () => {
        toast.error(
          t('ASSIGNMENT_BE_ERROR'),
        );
      },
    },
  );
}
