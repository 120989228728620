import * as React from 'react';
import './ToggleSwitch.scss';

interface IToggleSwitch {
  initialValue: boolean;
  onChange: (status: boolean) => void;
  id: string;
  disabled?: boolean;
  onToggleClicked?: (status: boolean) => void;
}

function ToggleSwitch({
  onToggleClicked,
  onChange,
  id,
  initialValue = false,
  disabled = false,
}: IToggleSwitch) {
  const elementId = `toggle-switch-${id}`;
  const [isChecked, setChecked] = React.useState<boolean>(
    initialValue || false,
  );

  React.useEffect(() => {
    onChange(isChecked);
  }, [isChecked]);

  // * some initialValue might be asynchronous, so using an effect
  React.useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  const onToggle = () => {
    if (!disabled) {
      onToggleClicked?.(!isChecked);
      setChecked((prev) => !prev);
    }
  };

  return (
    <label
      htmlFor={elementId}
      className={`switch ${disabled && 'switch-disable'}`}
    >
      <input
        type="checkbox"
        id={elementId}
        checked={isChecked}
        onChange={onToggle}
      />
      <span className="slider">&nbsp;</span>
    </label>
  );
}

export default ToggleSwitch;
