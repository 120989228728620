import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { MAX_CHAR_LIMIT_TEXTAREA } from '../../../../../shared/constant/generics';
import TextArea from '../../../../components/TextEditor';
import RadioGroup from '../../../../components/RadioGroup';
import { IFollowUpSessionsFormSections } from '../../FollowUpSession.type';
import FollowUpSessionCaseNotesControls from '../../FollowUpSessionCaseNotes.controls';
import './FollowUp.scss';
import { IOptions } from '../../../CaseNotesV2/types';
import Heading from '../../Heading';

interface IFollowUp {
  followUpOptions: IOptions[];
  primaryHeaderContent?: React.ReactNode;
  secondaryHeaderContent?: React.ReactNode;
  primaryQuestionType?: { question: string; id?: number };
  secondaryQuestionType?: { question: string; id?: number };
}

function FollowUp({
  watch,
  setValue,
  primaryHeaderContent,
  secondaryHeaderContent,
  followUpOptions,
  primaryQuestionType,
  secondaryQuestionType,
  trackOnBlur,
  trackOnFocus,
}: IFollowUpSessionsFormSections & IFollowUp) {
  const { t } = useTranslation();
  const mappedOpt = React.useMemo(
    () =>
      FollowUpSessionCaseNotesControls.getMappedOptionsWithId(followUpOptions),
    [followUpOptions],
  );
  const handleChange = (key: string) => (value: string | number) => {
    if (setValue) {
      if (key.includes('hasSharedFollowup')) {
        setValue('hasSharedFollowup', mappedOpt[value]);
      } else {
        setValue('followUpPlanDesc', value.toString());
      }
    }
  };
  return (
    <div className="followUp">
      <section className="followUpSessionSelector">
        {primaryHeaderContent || (
          <Heading
            headerText={primaryQuestionType?.question}
            hideTooltipChips
            toolTipID="-1"
          />
        )}
        <div>
          <RadioGroup
            selectedValue={watch('hasSharedFollowup')?.id?.toString()}
            values={followUpOptions}
            onChange={handleChange('hasSharedFollowup')}
            name="hasFollowUpProvided"
            customCss
            onBlur={() => trackOnBlur?.(primaryQuestionType?.id)}
            onFocus={trackOnFocus}
          />
        </div>
      </section>
      <AnimatePresence>
        <motion.section className="followUpPlanDocumentation">
          {secondaryHeaderContent || (
            <Heading
              headerText={secondaryQuestionType?.question}
              toolTipID="9"
            />
          )}
          <TextArea
            parametersValue={[]}
            defaultTemplate={watch('followUpPlanDesc') as string}
            onTextValueChange={(value) => {
              if (setValue) setValue('followUpPlanDesc', value);
            }}
            maxCharacterLimit={MAX_CHAR_LIMIT_TEXTAREA}
            placeHolderText={t('CASE_NOTE_FOLLOW_UP_PLACEHOLDER')}
            onBlur={() => trackOnBlur?.(secondaryQuestionType?.id)}
            onFocus={trackOnFocus}
            showMaxCharText={false}
          />
        </motion.section>
      </AnimatePresence>
    </div>
  );
}

export default FollowUp;
