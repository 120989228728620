/* eslint-disable react/jsx-props-no-spreading */
import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface InputProps extends ComponentPropsWithoutRef<'input'> {
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  placeholder?: string;
  containerClassName?: string;
}
export function Input({
  startIcon,
  endIcon,
  placeholder,
  className,
  containerClassName,
  ...restProps
}: InputProps) {
  return (
    <div className={twMerge('relative w-48', containerClassName)}>
      {startIcon ? (
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none text-gray-400">
          {startIcon}
        </div>
      ) : null}
      <input
        className={twMerge(
          'block bg-white text-md w-full py-2 px-4 ps-10 pe-10 text-gray-900 border border-gray-300 rounded-lg focus:border-primary-300 focus:outline-none focus:ring-4 focus:ring-primary-100',
          className,
        )}
        placeholder={placeholder}
        {...restProps}
      />
      {endIcon ? (
        <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
          {endIcon}
        </div>
      ) : null}
    </div>
  );
}
