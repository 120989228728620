import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'sonner';
import Layout from './view/app-components/Layout/Layout';
import ErrorBoundary from './view/app-components/ErrorBoundary';
import { FetchDevtools } from './utilities/hooks/useFetch';
import AppRoutes from './AppRoutes';
import { ToastConfig, ToastIconConfig } from './v2/config/ToastConfig';

// TODO lazy load screens + suspense fallback

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <Toaster
          position="top-center"
          toastOptions={ToastConfig}
          icons={ToastIconConfig}
        />
        <Layout>
          <AppRoutes />
          <FetchDevtools />
        </Layout>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
