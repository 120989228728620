import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import { Button } from '../../components/Button';
import InfoTile from '../../components/InfoTile';
import ClientDetailsContext from './context/ClientDetailsContext';
import { useClientEmergencyContacts } from './hooks/UseClientEmergencyContacts';
import { ClientECPRelationLabelMapping } from '../../../shared/constant/Clients';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';
// import {
//   EventActions,
//   EventCategories,
//   EventNames,
// } from '../../../../shared/constant/Analytics';
// import useTracking from '../../../../utilities/hooks/useTracking';

export default function UserEmergencyContactCard() {
  const { decodedClientId, isLoading } = useContext(ClientDetailsContext);
  const [fetchEcps, setFetchEcps] = useState(false);
  // const { track } = useTracking();
  const { t } = useTranslation();

  const { data, isLoading: isECLoading } = useClientEmergencyContacts(
    fetchEcps,
    decodedClientId,
  );

  const ecps = data?.ecp;

  return isLoading || isECLoading ? (
    <ClientOverviewCardLoader variant="card" />
  ) : (
    <BasicCardTemplate heading={t('EMERGENCY_CONTACT')} showToolTip={false}>
      {!fetchEcps && (
        <>
          <InfoTile
            lineClamp={2}
            variant="gray"
            contentClassName="text-gray-900"
            content={t('EC_DISCLAIMER_BODY')}
          />
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => {
              // track(EventNames.clientView, {
              //   eventAction: EventActions.click,
              //   eventCategory: EventCategories.emergencyContacts,
              //   eventLabel: 'emergency_contacts',
              // });
              setFetchEcps(true);
            }}
          >
            {t('EC_CTA')}
          </Button>
        </>
      )}

      {fetchEcps && !ecps?.length ? (
        <InfoTile
          lineClamp={2}
          variant="gray"
          content={t('EMERGENCY_CONTACT_DEFAULT_EMPTY')}
        />
      ) : null}

      {fetchEcps && ecps?.length
        ? ecps?.map((item) => (
            <InfoTile
              key={item.id}
              lineClamp={2}
              variant="gray"
              content={
                <span className="text-xs font-semibold text-gray-900">
                  {t(ClientECPRelationLabelMapping?.[item.relation])}
                </span>
              }
              subContent={
                <span className="text-xs text-gray-700">{item?.contact}</span>
              }
            />
          ))
        : null}
    </BasicCardTemplate>
  );
}
