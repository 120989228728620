/* eslint-disable */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './Pagination.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import Typography from '../../../../view/components/Typography';
import { motion } from 'framer-motion';
import { IPagination } from './types';
import useTracking from '../../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';

function Pagination({ paginationList = [], pageSize = 6 }: IPagination) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + pageSize >= paginationList.length ? 0 : prevIndex + pageSize,
    );
    track(EventNames.client_personal_insights, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.allDimensions,
      eventLabel: 'click_next',
      featureVersion: 'v1',
    });
  };
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - pageSize < 0
        ? paginationList.length - 1
        : prevIndex - pageSize,
    );
    track(EventNames.client_personal_insights, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.allDimensions,
      eventLabel: 'click_previous',
      featureVersion: 'v1',
    });
  };
  const isRightArrowDisabled = React.useMemo(
    () => currentIndex + pageSize >= paginationList.length,
    [currentIndex, paginationList],
  );
  return (
    <article className="pagination-wrapper">
      <section className="pagination-header">
        <section className="pagination-heading">
          <Typography size={18} weight="500">
            {t('ALL_DIMENSION_SCORES')}
          </Typography>
        </section>
        <section className="pagination-control">
          <span className="text-md text-gray-600">
            Showing &nbsp;
            {currentIndex + 1 === paginationList.length
              ? currentIndex + 1
              : `${currentIndex + 1}-${currentIndex + pageSize}`}{' '}
            of {paginationList?.length}
          </span>
          <motion.button
            className="control-item"
            whileHover={{ scale: currentIndex ? 1.1 : 1 }}
            whileTap={{ scale: currentIndex ? 0.9 : 1 }}
            onClick={handlePrevious}
            disabled={!currentIndex}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className={`icons ${!currentIndex && 'disabledIcon'}`}
            />
          </motion.button>
          <motion.button
            className="control-item"
            whileHover={{ scale: isRightArrowDisabled ? 1 : 1.1 }}
            whileTap={{ scale: isRightArrowDisabled ? 1 : 0.9 }}
            onClick={handleNext}
            disabled={isRightArrowDisabled}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              className={`icons ${isRightArrowDisabled && 'disabledIcon'}`}
            />
          </motion.button>
        </section>
      </section>
      <section className="pagination-body">
        {paginationList?.slice(currentIndex, currentIndex + pageSize)}
      </section>
    </article>
  );
}

export default Pagination;
