import * as React from 'react';
import './Banner.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { IBanner } from './types';
import Typography from '../Typography';
import Button from '../Button';

function Banner({
  primaryContent,
  uniqueKey,
  toolTipClass,
  secondaryContent,
  withBgColor,
  backgroundColor,
  primaryContentColor,
  height = '32px',
  width = '100%',
  fontSize = 12,
  primaryFontWeight = '500',
  secondaryFontWeight = '400',
  onClick = () => {},
  primaryIcon,
  buttonLabel,
  bannerVariant,
  buttonVariant = 'save',
  toolTipText = '',
  displayBannerCTA = true,
  contentWidth = '80%',
  secondaryCTA,
  btnIcon,
  disableBannerCTA,
}: IBanner) {
  const buildBannerStyle = () => {
    const styles: React.CSSProperties = {
      backgroundColor: withBgColor,
    };
    if (withBgColor) {
      styles.backgroundColor = withBgColor;
    }
    return styles;
  };

  const { t } = useTranslation();

  return (
    <article
      className={`banner-container ${bannerVariant}`}
      style={buildBannerStyle()}
    >
      <section className="banner-left-section">
        {primaryIcon && (
          <span className={toolTipText ? 'tooltip-enable' : ''}>
            <img
              data-tooltip-id={
                toolTipText ? `toolTipUnsaved-${uniqueKey}` : 'noToolTIp'
              }
              src={primaryIcon}
              alt="filter"
            />
          </span>
        )}
        <ReactTooltip
          id={`toolTipUnsaved-${uniqueKey}`}
          place="bottom"
          content={toolTipText}
          className={toolTipClass}
        />

        <section className="banner-content">
          <Typography
            size={fontSize}
            weight={primaryFontWeight}
            width={contentWidth}
            withColor={primaryContentColor}
          >
            {/* eslint-disable-next-line */}
            <div dangerouslySetInnerHTML={{ __html: primaryContent }} />
          </Typography>
          {secondaryContent && (
            <Typography
              size={fontSize}
              weight={secondaryFontWeight}
              width="80%"
              color="grey"
            >
              {secondaryContent}
            </Typography>
          )}
        </section>
      </section>
      <section className="banner-right-section">
        {secondaryCTA && secondaryCTA.display ? (
          <Button
            width={secondaryCTA.width || '100%'}
            label={t(secondaryCTA.label || '')}
            variant={secondaryCTA.variant || 'primary'}
            onClick={() => secondaryCTA.onClick?.()}
            minWidth="71px"
            height={secondaryCTA.height || '32px'}
            backgroundColor={secondaryCTA.backgroundColor}
            icon={secondaryCTA.icon}
          />
        ) : null}
        {displayBannerCTA && (
          <Button
            width={width}
            label={buttonLabel}
            variant={buttonVariant}
            onClick={onClick}
            minWidth="71px"
            height={height}
            backgroundColor={backgroundColor}
            icon={btnIcon}
            disabled={disableBannerCTA}
          />
        )}
      </section>
    </article>
  );
}

export default Banner;
