import { beautifyUrl } from '../../../utilities/common/Path';
import {
  BookSessionReqType,
  NoCancellationReqType,
} from '../../../v2/view/app-components/CancellationFlow/types';
import Config from '../../Config';
import NetworkClient from '../Network';

const client = new NetworkClient();

export const errorMessage = {
  generic: 'something went wrong, please try again!',
};

export const rescheduleClientSession = async (
  body: BookSessionReqType,
  meetingId: string,
) => {
  const response = await client.doPut(
    beautifyUrl(Config.api.cancellationFlows.rescheduleMeeting, [meetingId]),
    body,
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response?.error?.message || errorMessage.generic);
};

export const notifyNoCancellation = async (body: NoCancellationReqType) => {
  const response = await client.doPost(
    Config.api.cancellationFlows.noSessionCancellation,
    body,
  );
  if (response && response.success) {
    return response;
  }
  throw new Error(response.data.error?.message || errorMessage.generic);
};

export const lateCancellationPercentage = async (role: number) => {
  const response = await client.doGet(
    Config.api.cancellationFlows.getCancellationLatePercentage,
    {},
    { role },
  );
  if (response && response.success) {
    return response;
  }
  throw new Error(
    response?.data?.error?.message ||
      'something went wrong, unable to fetch the late cancellation percentage',
  );
};
