import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { useFetch, useMutation } from '../../../../../utilities/hooks/useFetch';
import {
  addClientRiskLevel,
  editClientRiskLevel,
  getClientRiskLevels,
} from '../../../../shared/service/API/client';
import { IAddRiskLevelPayloadType, IClientRiskLevel } from '../types/client';

export function useClientRiskLevel(clientId?: string, role?: string) {
  return useFetch<IClientRiskLevel[]>(
    ['client-details-v2-risk-level', clientId, role],
    () => (clientId ? getClientRiskLevels(clientId, role) : undefined),
    {
      enabled: Boolean(clientId),
      refetchOnWindowFocus: false,
    },
  );
}

export function useAddClientRiskLevel(
  payload: IAddRiskLevelPayloadType,
  handleSuccess: () => void,
  clientId?: string,
) {
  const { t } = useTranslation();
  return useMutation(
    () => (clientId ? addClientRiskLevel(clientId, payload) : undefined),
    {
      onSuccess: () => {
        toast.success(t('RISK_LEVEL_UPDATED_SUCCESS_PROMPT'));
        handleSuccess();
      },
      onError: () => {
        toast.error(
          t('RISK_LEVEL_ERROR_BE'),
        );
      },
    },
  );
}

export function useEditClientRiskLevel(
  payload: Pick<IAddRiskLevelPayloadType, 'risk'>,
  handleSuccess: () => void,
  clientId?: string,
  noteId?: string,
) {
  const { t } = useTranslation();
  return useMutation(
    () =>
      clientId && noteId
        ? editClientRiskLevel(clientId, noteId, payload)
        : undefined,
    {
      onSuccess: () => {
        toast.success(t('RISK_LEVEL_UPDATED_SUCCESS_PROMPT'));
        handleSuccess();
      },
      onError: () => {
        toast.error(t('RISK_LEVEL_ERROR_BE_2'));
      },
    },
  );
}
