import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { DOTS, usePagination } from './hooks/usePagination';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';
import useTracking from '../../../../utilities/hooks/useTracking';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/ArrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/ArrowRight.svg';
import { IClientPagination } from './types';

function Pagination({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}: IClientPagination) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const { t } = useTranslation();
  const { track } = useTracking();

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
    track(EventNames.providerWebClients, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.page,
      eventLabel: 'next',
      eventValue: currentPage + 1,
    });
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
    track(EventNames.providerWebClients, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.page,
      eventLabel: 'previous',
      eventValue: currentPage - 1,
    });
  };

  const onClickPageNumber = (newPage: number) => {
    onPageChange(newPage);
    track(EventNames.providerWebClients, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.page,
      eventLabel: 'page',
      eventValue: newPage,
    });
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={`flex justify-center list-none ${className}`}>
      <li
        className={`client-pagination-item client-pagination-arrow-btn ${
          currentPage === 1 ? 'client-pagination-arrow-btn-disabled' : ''
        }`}
        onClick={onPrevious}
      >
        <div>
          <ArrowLeftIcon />
        </div>
        {t('prev')}
      </li>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS || typeof pageNumber === 'string') {
          return (
            <li className="client-pagination-item client-pagination-dots">
              &#8230;
            </li>
          );
        }

        return (
          <li
            className={twMerge(
              'client-pagination-item',
              pageNumber === currentPage && 'client-pagination-item-selected',
            )}
            onClick={() => onClickPageNumber(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={`client-pagination-item client-pagination-arrow-btn ${
          currentPage === lastPage ? 'client-pagination-arrow-btn-disabled' : ''
        }`}
        onClick={onNext}
      >
        {t('next')}
        <div>
          <ArrowRightIcon />
        </div>
      </li>
    </ul>
  );
}

export default Pagination;
