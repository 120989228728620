import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronRight } from '../../../assets/rightChevron.svg';

interface BreadcrumbsProps {
  list: { title: string; link?: string }[];
  /** This classname affects the Breadcrumb container ONLY. */
  className?: string;
}
export function Breadcrumbs({ list, className }: BreadcrumbsProps) {
  return (
    <div className={twMerge('flex items-center gap-0.5', className)}>
      {list.map((item, index) => (
        <Fragment key={item.title}>
          <Link
            to={item.link || ''}
            className={twMerge(
              'text-sm font-medium text-gray-600 py-1 px-2 hover:underline hover:text-gray-600',
              !item.link && 'hover:no-underline pointer-events-none',
              index === list.length - 1 && 'rounded-md bg-gray-100',
            )}
          >
            {item.title}
          </Link>

          {index === list.length - 1 ? null : (
            <ChevronRight className="w-5 font-bold" />
          )}
        </Fragment>
      ))}
    </div>
  );
}
