/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { toast } from 'sonner';
import TextEditor from '../../../components/TextEditor';
import {
  CaseNoteQuestionTypes,
  RiskAssesment,
} from '../../../../shared/constant/CoachingCaseNotes';
import { ICareIntakeFormInputs } from './types';
import '../CareIntake.scss';
import { showSucidalAssessmentEditors } from '../../CaseNotesV2/utils';
import {
  ICasenoteQuestionData,
  IOptionWithQuestionId,
  IOptions,
} from '../../CaseNotesV2/types';
import RiskAndIssuePresenter from '../../RiskAndIssuePresenter';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../../shared/constant/Analytics';
import { IClientSummary } from '../../../../shared/types/response/provider';
import SendSOSPopup from '../../SendSOSPopup';
import useTracking from '../../../../utilities/hooks/useTracking';
import decodeCode from '../../../../utilities/common/Encryption';

function FormPart1({
  caseNoteQuestionsData,
  control,
  riskCategoriesOptions,
  issuesOptions,
  suicidalAssessmentsOptions,
  watchRiskCategory,
  startQuestionDurationTracking,
  caseNoteEventTracking,
  showTags = true,
  selectedClientData,
  meetingId,
}: {
  caseNoteQuestionsData: ICasenoteQuestionData;
  control: Control<ICareIntakeFormInputs, any>;
  riskCategoriesOptions: IOptions[];
  issuesOptions: IOptions[];
  suicidalAssessmentsOptions: IOptions[];
  watchRiskCategory: IOptionWithQuestionId;
  startQuestionDurationTracking?: () => void;
  caseNoteEventTracking?: (label: string, category?: string) => void;
  showTags?: boolean;
  selectedClientData: IClientSummary;
  meetingId?: string;
}) {
  const { t } = useTranslation();

  const { track } = useTracking();
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [sosModalStatus, setSosModalStatus] = React.useState({
    showSOSConfirmation: false,
  });
  const showSucidalAssessmentEditor =
    showSucidalAssessmentEditors(watchRiskCategory);

  const handleCareInformClick = () => {
    setIsVisible(true);
    caseNoteEventTracking?.('refer_client');
  };

  const onSOSBtnClick = () => {
    track(EventNames.sosHelpLine, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.sosHelpLine,
      eventLabel: 'sos_helpline',
      featureVersion: 'v1',
      eventSource: EventSource.session,
    });
    setSosModalStatus((prev) => ({ ...prev, showSOSConfirmation: true }));
  };
  const onSOSClose = (isSOSSuccessful: boolean) => {
    if (isSOSSuccessful) {
      toast.success(t('SOS_NEW_SUCCESS_PROMPT_SINGLE'));
    }
    setSosModalStatus((prev) => ({
      ...prev,
      showSOSConfirmation: false,
    }));
    track(EventNames.sosHelpLine, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.close,
      featureVersion: 'v1',
      eventLabel: 'close',
      eventSource: EventSource.session,
    });
  };
  return (
    <article className="main-form-wrapper">
      <RiskAndIssuePresenter
        meetingId={meetingId as string}
        control={control}
        caseNoteQuestionsData={caseNoteQuestionsData}
        riskCategoriesOptions={riskCategoriesOptions}
        issuesOptions={issuesOptions}
        suicidalAssessmentsOptions={suicidalAssessmentsOptions}
        showSucidalAssessmentEditor={showSucidalAssessmentEditor}
        showCareReferral={
          watchRiskCategory?.value.toString() ===
          RiskAssesment.HighRiskCritical.toString()
        }
        handleInformBtnClick={handleCareInformClick}
        showInfoModal={isVisible}
        infoModalOnClose={() => setIsVisible(false)}
        startQuestionDurationTracking={startQuestionDurationTracking}
        caseNoteEventTracking={caseNoteEventTracking}
        showTags={showTags}
        riskBorder={showTags}
        showSOSSupport={
          !showTags &&
          [
            RiskAssesment.HighRiskCritical,
            RiskAssesment.HighRiskNonCritical,
          ].includes(Number(watchRiskCategory?.value))
        }
        handleSOSClick={onSOSBtnClick}
      />
      <section className="care-history-textarea">
        <section className="header-info-container extra-padding">
          <label className="form-label" htmlFor="detailsOfPresentingIssues">
            {
              caseNoteQuestionsData[
                CaseNoteQuestionTypes.DetailsOfPresentingIssues
              ]?.question
            }
          </label>
        </section>
        <Controller
          name="detailsOfPresentingIssues"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.DetailsOfPresentingIssues
                      ]?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={5000}
                showMaxCharText={false}
                placeHolderText={t(
                  `HISTORY_PROMPT_${CaseNoteQuestionTypes.DetailsOfPresentingIssues}`,
                )}
                className="custom-editor-style"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.DetailsOfPresentingIssues
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
      <section className="care-history-textarea">
        <section className="header-info-container extra-padding">
          <label className="form-label" htmlFor="presentingIssuesHistory">
            {caseNoteQuestionsData[CaseNoteQuestionTypes.HistoryOfPI]?.question}
          </label>
        </section>
        <Controller
          name="presentingIssuesHistory"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[CaseNoteQuestionTypes.HistoryOfPI]
                        ?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={5000}
                showMaxCharText={false}
                placeHolderText={t(
                  `HISTORY_PROMPT_${CaseNoteQuestionTypes.HistoryOfPI}`,
                )}
                className="custom-editor-style"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.HistoryOfPI
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
      <section className="care-history-textarea">
        <section className="header-info-container extra-padding">
          <label className="form-label" htmlFor="medicalMentalHistory">
            {
              caseNoteQuestionsData[
                CaseNoteQuestionTypes.MedicalAndMentalHistory
              ].question
            }
          </label>
        </section>
        <Controller
          name="medicalMentalHistory"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.MedicalAndMentalHistory
                      ]?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={5000}
                showMaxCharText={false}
                placeHolderText={t(
                  `HISTORY_PROMPT_${CaseNoteQuestionTypes.MedicalAndMentalHistory}`,
                )}
                className="custom-editor-style"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.MedicalAndMentalHistory
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
      <section className="care-history-textarea">
        <section className="header-info-container extra-padding">
          <label className="form-label" htmlFor="familyAndRelationshipHistory">
            {
              caseNoteQuestionsData[
                CaseNoteQuestionTypes.FamilyAndRelationshipHistory
              ].question
            }
          </label>
        </section>
        <Controller
          name="familyAndRelationshipHistory"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.FamilyAndRelationshipHistory
                      ]?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={5000}
                showMaxCharText={false}
                placeHolderText={t(
                  `HISTORY_PROMPT_${CaseNoteQuestionTypes.FamilyAndRelationshipHistory}`,
                )}
                className="custom-editor-style"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.FamilyAndRelationshipHistory
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
      <section className="care-history-textarea">
        <section className="header-info-container extra-padding">
          <label className="form-label" htmlFor="childhoodExperiences">
            {
              caseNoteQuestionsData[CaseNoteQuestionTypes.ChildhoodExperiences]
                .question
            }
          </label>
        </section>
        <Controller
          name="childhoodExperiences"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.ChildhoodExperiences
                      ]?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={5000}
                showMaxCharText={false}
                placeHolderText={t(
                  `HISTORY_PROMPT_${CaseNoteQuestionTypes.ChildhoodExperiences}`,
                )}
                className="custom-editor-style"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.ChildhoodExperiences
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
      <section className="care-history-textarea">
        <section className="header-info-container extra-padding">
          <label className="form-label" htmlFor="socialHistory">
            {
              caseNoteQuestionsData[CaseNoteQuestionTypes.SocialHistory]
                .question
            }
          </label>
        </section>
        <Controller
          name="socialHistory"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[CaseNoteQuestionTypes.SocialHistory]
                        ?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={5000}
                showMaxCharText={false}
                placeHolderText={t(
                  `HISTORY_PROMPT_${CaseNoteQuestionTypes.SocialHistory}`,
                )}
                className="custom-editor-style"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.SocialHistory
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
      <section className="care-history-textarea">
        <section className="header-info-container extra-padding">
          <label className="form-label" htmlFor="workAndEducationalHistory">
            {
              caseNoteQuestionsData[
                CaseNoteQuestionTypes.WorkAndEducationalHistory
              ].question
            }
          </label>
        </section>
        <Controller
          name="workAndEducationalHistory"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.WorkAndEducationalHistory
                      ]?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={5000}
                showMaxCharText={false}
                placeHolderText={t(
                  `HISTORY_PROMPT_${CaseNoteQuestionTypes.WorkAndEducationalHistory}`,
                )}
                className="custom-editor-style"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.WorkAndEducationalHistory
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
      <section className="care-history-textarea">
        <section className="header-info-container extra-padding">
          <label className="form-label" htmlFor="substanceUseHistory">
            {
              caseNoteQuestionsData[CaseNoteQuestionTypes.SubstanceUseHistory]
                .question
            }
          </label>
        </section>
        <Controller
          name="substanceUseHistory"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.SubstanceUseHistory
                      ]?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={5000}
                showMaxCharText={false}
                placeHolderText={t(
                  `HISTORY_PROMPT_${CaseNoteQuestionTypes.SubstanceUseHistory}`,
                )}
                className="custom-editor-style"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.SubstanceUseHistory
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
      <section className="care-history-textarea">
        <section className="header-info-container extra-padding">
          <label className="form-label" htmlFor="currentCopingHabits">
            {
              caseNoteQuestionsData[CaseNoteQuestionTypes.CurrentCopingHabits]
                .question
            }
          </label>
        </section>
        <Controller
          name="currentCopingHabits"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.CurrentCopingHabits
                      ]?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={5000}
                showMaxCharText={false}
                placeHolderText={t(
                  `HISTORY_PROMPT_${CaseNoteQuestionTypes.CurrentCopingHabits}`,
                )}
                className="custom-editor-style"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.CurrentCopingHabits
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
      {!!caseNoteQuestionsData[CaseNoteQuestionTypes.Others] && (
        <section className="care-history-textarea">
          <section className="header-info-container extra-padding">
            <label className="form-label" htmlFor="others">
              {caseNoteQuestionsData[CaseNoteQuestionTypes.Others].question}
            </label>
          </section>
          <Controller
            name="others"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <section className="editor-wrapper">
                <TextEditor
                  {...field}
                  onTextValueChange={(note) =>
                    field.onChange({
                      id: note,
                      questionId:
                        caseNoteQuestionsData[CaseNoteQuestionTypes.Others]?.id,
                    })
                  }
                  defaultTemplate={field.value?.id || ''}
                  parametersValue={[]}
                  maxCharacterLimit={5000}
                  showMaxCharText={false}
                  placeHolderText={t(
                    `HISTORY_PROMPT_${CaseNoteQuestionTypes.Others}`,
                  )}
                  className="custom-editor-style"
                  onFocus={startQuestionDurationTracking}
                  onBlur={() =>
                    caseNoteEventTracking?.(
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.Others
                      ].id.toString(),
                      EventCategories.questionId,
                    )
                  }
                />
              </section>
            )}
          />
        </section>
      )}
      {sosModalStatus.showSOSConfirmation && (
        <SendSOSPopup
          onCancel={(isSOSSuccessful) => onSOSClose(isSOSSuccessful)}
          clientData={{
            ...selectedClientData,
            userId: decodeCode(selectedClientData.userId.toString()),
          }}
          showCloseIcon={false}
          trackingData={{ source: EventSource.session, eventValue: meetingId }}
        />
      )}
    </article>
  );
}

export default FormPart1;
