import { useMutation } from 'react-query';
import { toast } from 'sonner';
import { sosRequestLogger } from '../../../shared/service/API/sosTracking';

function sosRequestTracker() {
  const { mutate: logSOSEvent, isLoading: isSOSLogLoading } = useMutation(
    (body: any) => sosRequestLogger(body),
    {
      onSuccess: (data) => {
        console.log('sos tracking', data);
      },
      onError: (errorData: Error) => {
        toast.error(errorData.message);
      },
    },
  );
  return {
    logSOSEvent,
    isSOSLogLoading,
  };
}

export default sosRequestTracker;
