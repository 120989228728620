import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './PHQScoreInfo.scss';
import Popup from '../../../../view/components/Popup';
import { PHQModalProps } from './types';
import Typography from '../../../../view/components/Typography';
import { PHQQuestions } from '../../../../shared/constant/Goals';

function PhqScoreInfo({ onClose, currentScore, title }: PHQModalProps) {
  const { t } = useTranslation();
  return (
    <Popup
      withBackdrop
      useDefaultPadding={false}
      backdropMode="glass"
      onClose={onClose}
    >
      <article className="phq-modal-container w-[35vw] h-[64vh]">
        <section className="phq-modal-header">
          <Typography size={24} weight="600" color="swamp">
            {title}
          </Typography>
        </section>
        <section className="phq-score-container">
          <section className="phq-score">
            <Typography size={16} weight="500" color="secondary">
              Score
            </Typography>
            <Typography size={24} weight="600">
              {currentScore} / 12
            </Typography>
          </section>
          <section className="phq-score-category">
            <section>
              <Typography size={14} color="secondary">
                Low
              </Typography>
              <Typography size={14} weight="600" withColor="#0AA65D">
                0-2
              </Typography>
            </section>
            <section>
              <Typography size={14} color="secondary">
                Mild
              </Typography>
              <Typography size={14} weight="600" withColor="#FFA958">
                3-5
              </Typography>
            </section>
            <section>
              <Typography size={14} color="secondary">
                Moderate
              </Typography>
              <Typography size={14} weight="600" withColor="#FFA958">
                6-8
              </Typography>
            </section>
            <section>
              <Typography size={14} color="secondary">
                High
              </Typography>
              <Typography size={14} weight="600" withColor="#E25C3A">
                9-12
              </Typography>
            </section>
          </section>
        </section>
        <section className="questainnaire-container">
          <section className="questainnaire-heading">
            <Typography size={16} weight="600">
              {t('QUESTION_HEADER')}
            </Typography>
          </section>
          <section className="questainnaire-content">
            <Typography size={16} color="secondary">
              {t('QUESTION_SUBHEADER')}
            </Typography>
          </section>
          <section className="question-list-wrapper">
            <ol>
              {PHQQuestions.map((question) => (
                <li key={question}>
                  <Typography size={16} color="secondary">
                    {t(question)}
                  </Typography>
                </li>
              ))}
            </ol>
          </section>
        </section>
      </article>
    </Popup>
  );
}

export default PhqScoreInfo;
