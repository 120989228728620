import * as React from 'react';
import Typography from '../../../../view/components/Typography';
import './ChatAvatarV2.scss';

interface IChatAvatarProps {
  name: string;
  profileImage?: string;
}

function ChatAvatar({ name = '', profileImage }: IChatAvatarProps) {
  return (
    <div className="profile-image-container">
      {profileImage ? (
        <img src={profileImage} alt={name} />
      ) : (
        <Typography size={24} weight="600" color="primary">
          {name.charAt(0).toUpperCase()}
        </Typography>
      )}
    </div>
  );
}

export default ChatAvatar;
