import { useMutation } from 'react-query';
import { cancelMeeting } from '../../../shared/service/API/meeting';

function useCancellation({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: Error) => void;
}) {
  return useMutation(
    ({
      meetingId,
      note,
      type,
      otherReason,
    }: {
      meetingId: string;
      note: string;
      otherReason?: string;
      type?: string;
    }) => cancelMeeting(meetingId, note, type, otherReason),
    {
      onSuccess,
      onError,
    },
  );
}

export default useCancellation;
