import moment from 'moment';
import { useContext, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import InfoTile from '../../components/InfoTile';
import { Button } from '../../components/Button';
import { ClientProfileDateFormat } from '../../../utilities/common/Date';
import AddNotesModal from './AddNotesModal';
import { useClientNotes } from './hooks/UseClientNotes';
import ClientDetailsContext from './context/ClientDetailsContext';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';
// import {
//   EventActions,
//   EventCategories,
//   EventNames,
// } from '../../../../shared/constant/Analytics';
// import useTracking from '../../../../utilities/hooks/useTracking';
import { getDisplayDate } from '../../../../utilities/common/Date';
import { getCurrentLocale } from '../../../../i18n';

export default function UserNotesCard() {
  // const { t } = useTranslation();
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const { decodedClientId, serviceType, isLoading } =
    useContext(ClientDetailsContext);

  const { t } = useTranslation();
  // const { track } = useTracking();
  const currentLocale = getCurrentLocale();
  const {
    data: clientNotes,
    isLoading: isNotesLoading,
    refetch: refetchNotes,
  } = useClientNotes(decodedClientId, serviceType);

  // picking recent note
  const existingNote = clientNotes?.[0];

  return isLoading || isNotesLoading ? (
    <ClientOverviewCardLoader variant="card" />
  ) : (
    <>
      <BasicCardTemplate heading={t('PROVIDER_NOTES')} showToolTip={false}>
        {!existingNote ? (
          <InfoTile
            lineClamp={2}
            variant="gray"
            contentClassName="text-gray-900"
            content={t('NO_NOTES_STATE')}
          />
        ) : (
          <InfoTile
            lineClamp={2}
            variant="gray"
            className="text-justify whitespace-pre-line"
            contentClassName="text-gray-900"
            subContentClassName="text-gray-500"
            key={existingNote?.updatedAt}
            content={existingNote?.note}
            subContent={getDisplayDate({
              locale: currentLocale,
              translatorResource: { ja: 'DISPLAY_DATE_WITH_TIME' },
              format: { ja: 'MM,DD,YYYY,HH,mm', en: ClientProfileDateFormat },
              selectedDate: moment(existingNote?.updatedAt),
              translate: t,
            })}
            onReadMoreClick={() => {
              // track(EventNames.clientView, {
              //   eventAction: EventActions.click,
              //   eventCategory: EventCategories.providerNote,
              //   eventLabel: 'read_more',
              // });
            }}
            expandable
          />
        )}

        <Button
          className="w-full"
          variant="secondary"
          onClick={() => setShowNotesModal(true)}
        >
          {existingNote ? t('EDIT_NOTE_CTA') : t('ADD_NOTE_CTA')}
        </Button>
      </BasicCardTemplate>
      <AddNotesModal
        open={showNotesModal}
        existingNote={existingNote}
        handleClose={() => setShowNotesModal(false)}
        handleSuccess={() => refetchNotes()}
      />
    </>
  );
}
