import React from 'react';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as ScrollIcon } from '../../assets/ChevronLeftV2.svg';
import { useIsOverflowing } from '../../utilities/hooks/useIsOverflowing';
import { Button } from './Button';

type CarouselProps<T> = {
  data: T[];
  /**
   * @param {T} itemData An item from the 'data' array.
   * @note Don't forget the 'key' prop when creating the item to render!
   */
  renderItem: (itemData: T) => JSX.Element;
  className?: string;
  rightArrowClass?: string;
};

function Carousel<T>({
  data,
  renderItem,
  className,
  rightArrowClass,
}: CarouselProps<T>) {
  const [isOverflowing, setIsOverflowing] = React.useState<boolean>(false);
  const [currentScroll, setCurrentScroll] = React.useState<number>(0);

  const carouselRef = React.useRef<HTMLDivElement>(null);

  const handleScroll = (backward?: boolean) => {
    const { current } = carouselRef;

    if (current) {
      current.scrollBy(current.clientWidth * (backward ? -1 : 1) * 1.2, 0);
    }
  };

  const onScroll = () => {
    setCurrentScroll(Math.round(carouselRef.current?.scrollLeft || 0));
  };

  useIsOverflowing('width', carouselRef, (hasOverflowed) =>
    setIsOverflowing(hasOverflowed),
  );

  return (
    <div className="relative flex flex-row">
      {isOverflowing && currentScroll > 0 && (
        <div
          className="absolute left-0 z-10 flex h-full w-24 bg-gradient-to-l from-[transparent] to-amber-200"
          style={{
            background:
              'linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 0%, 0) 100%)',
          }}
        >
          <Button
            variant="ghost"
            mini
            onClick={() => handleScroll(true)}
            className="h-5 w-5 my-auto p-0"
          >
            <ScrollIcon className="h-5 w-5 text-gray-500" />
          </Button>
        </div>
      )}
      <div
        onScroll={onScroll}
        // style={{
        //   background:
        //     'linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)',
        // }}
        className={twMerge(
          'flex gap-4 overflow-x-auto scroll-smooth py-2 pr-6',
          className,
        )}
        ref={carouselRef}
      >
        {data.map((carouselItem) => renderItem(carouselItem))}
      </div>
      {isOverflowing &&
        currentScroll <
          (carouselRef.current?.scrollWidth || 0) -
            (carouselRef.current?.clientWidth || 0) && (
          <div
            className="absolute right-0 z-0 flex h-full w-8 bg-gradient-to-r from-[transparent] to-amber-200"
            style={{
              background:
                'linear-gradient(90deg, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 100%, 1) 100%)',
            }}
          >
            <Button
              variant="ghost"
              mini
              onClick={() => handleScroll()}
              className={twMerge(
                'rotate-180 h-5 w-5 my-auto p-0',
                rightArrowClass,
              )}
            >
              <ScrollIcon className="h-5 w-5 text-gray-500" />
            </Button>
          </div>
        )}
    </div>
  );
}

export default Carousel;
