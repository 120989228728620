import React from 'react';
import { IFollowUpSessionsFormSections } from '../../FollowUpSession.type';
import Typography from '../../../../components/Typography';
import FollowUpSessionCaseNotesControls from '../../FollowUpSessionCaseNotes.controls';
import './ResponseToIntervention.scss';
import { IOptions } from '../../../../../shared/types/CaseNotes';
import Heading from '../../Heading';

interface IResponseToIntervention {
  headerContent?: React.ReactNode;
  responseToInterventionOption: IOptions[];
}

function ResponseToIntervention({
  watch,
  setValue,
  headerContent,
  responseToInterventionOption,
  question,
  trackOnBlur,
  trackOnFocus,
}: IFollowUpSessionsFormSections & IResponseToIntervention) {
  const selectedOptions: Array<IOptions> = watch('response') || [];
  const selectedOpt = React.useMemo(
    () => selectedOptions.map(({ id }) => Number(id)),
    [watch('response')],
  );
  const mappedOpt = React.useMemo(
    () =>
      FollowUpSessionCaseNotesControls.getMappedOptionsWithId(
        responseToInterventionOption,
      ),
    [responseToInterventionOption],
  );
  const handleChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const isCheckBoxChecked = e.target.checked;
      if (setValue) {
        if (isCheckBoxChecked) {
          if (JSON.stringify(selectedOptions[0]) !== '{}') {
            setValue('response', [...selectedOptions, mappedOpt[key]]);
          } else {
            setValue('response', [mappedOpt[key]]);
          }
        } else {
          const filteredData = (selectedOptions || []).filter(
            ({ id }) => id !== key,
          );
          setValue('response', filteredData);
        }
      }
    };
  return (
    <div className="responseToIntervention">
      <section className="primary">
        {headerContent || (
          <Heading headerText={question?.question} toolTipID="7" />
        )}
        <div
          className="check-list"
          onFocus={trackOnFocus}
          onBlur={() => trackOnBlur?.(question?.id)}
        >
          {responseToInterventionOption.map(({ label, value, id }) => (
            <div key={`${label}-${value}`} className="checkboxField">
              <input
                type="checkbox"
                checked={selectedOpt.includes(Number(id))}
                onChange={handleChange(id)}
                id={id}
                value={label}
              />
              <label htmlFor={value}>
                <Typography size={14} weight="500" withColor="#636C73">
                  {label}
                </Typography>
              </label>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default ResponseToIntervention;
