import * as React from 'react';
import './InformationModal.scss';
import Popup from '../../components/Popup';
import Typography from '../../components/Typography';
import { ISOSNumber } from './types';
import Button from '../../components/Button';

function InformationModal({
  headerText,
  bodyText,
  onClose,
  show,
  icon,
  leftBtnLabel,
  rightBtnLabel,
  leftBtnClick,
  cursor,
  leftBtnVariant = 'blue',
  iconHeight = '16px',
  iconWidth = '16px',
  rightBtnClick,
  rightBtnVariant = 'blue',
  customBtnContainerStyle = '',
  borderRadius = '10px',
  fontSize = 18,
  fontWeight = '700',
  width = '100%',
  height = '50px',
  headerFontSize = 20,
  contentFontSize = 14,
  modalVariant,
  noteText,
  imgSrc,
  textAlignment,
  padding = '16px',
}: ISOSNumber) {
  return (
    <Popup withBackdrop show={show} useDefaultPadding={false} onClose={onClose}>
      <article className="information-wrapper" style={{ padding }}>
        {imgSrc && <img src={imgSrc} alt="illustration" />}
        <section className="info-header">
          <Typography
            size={headerFontSize}
            weight="600"
            textAlign={textAlignment}
          >
            {/* eslint-disable-next-line */}
            <div dangerouslySetInnerHTML={{ __html: headerText }} />
          </Typography>
        </section>
        <section
          className={`info-body ${customBtnContainerStyle} ${
            modalVariant && 'single-cta'
          }`}
        >
          {icon && (
            <section className="info-modal-icon ">
              <img
                height={iconHeight}
                width={iconWidth}
                src={icon}
                className="sos-icon"
                alt="information-icon"
              />
            </section>
          )}
          <Typography
            size={contentFontSize}
            withColor="#484F54"
            textAlign={textAlignment}
          >
            {bodyText}
          </Typography>
        </section>
        {noteText && (
          <Typography size={contentFontSize} withColor="#484F54">
            {/* eslint-disable-next-line */}
            <div dangerouslySetInnerHTML={{ __html: noteText }} />
          </Typography>
        )}
        <section className={`info-modal-footer ${customBtnContainerStyle}`}>
          {!(modalVariant === 'single-cta') && (
            <Button
              height={height}
              width={width}
              variant={leftBtnVariant}
              label={leftBtnLabel}
              onClick={() => leftBtnClick?.()}
              style={{ borderRadius }}
              disableAnimation={!leftBtnClick}
              fontSize={fontSize}
              fontWeight={fontWeight}
              cursor={cursor}
            />
          )}
          <Button
            height={height}
            width={width}
            variant={rightBtnVariant}
            label={rightBtnLabel}
            onClick={() => rightBtnClick?.()}
            style={{ borderRadius }}
            disableAnimation={!rightBtnClick}
            fontSize={fontSize}
            fontWeight={fontWeight}
            cursor={cursor}
          />
        </section>
      </article>
    </Popup>
  );
}

export default InformationModal;
