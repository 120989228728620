import { Row } from '@tanstack/react-table';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IClientSummary } from '../../../../shared/types/response/provider';
import useTracking from '../../../../utilities/hooks/useTracking';
import Typography from '../../../../view/components/Typography';
import { ReactComponent as SearchIcon } from '../../../assets/search.svg';
import './ClientsV2.scss';
import noSearchResults from '../../../../assets/noSerach.svg';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';
import { encodeCode } from '../../../../utilities/common/Encryption';
import useDebounce from '../../../../utilities/hooks/useDebounce';
import NoResultsFound from '../../../../view/app-components/NoResultsFound';
import Pagination from '../../components/ClientPagination/ClientPagination';
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';
import Table from '../../components/Table';
import { clientsColDefinition } from './ClientsColumnDef';
import {
  sortFieldNameFromColumnId,
  statusOptionsV2,
  unMatchedStatusCode,
} from './constants';
import { useClientsList } from './hooks/useClientsList';
import decodeCode from '../../../utilities/common/Encryption';

function Clients() {
  const { track } = useTracking();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchText, setSearchText] = React.useState<string>('');
  const [caseIdText, setCaseIdText] = React.useState<string>('');
  const [decodedCaseIdText, setDecodedCaseIdText] = React.useState<string>('');

  const debouncedSearchText = useDebounce(searchText, 500);
  const debouncedUserIdText = useDebounce(decodedCaseIdText, 500);

  const [statusFilter, setStatusFilter] = React.useState<string[]>([]);

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [sort, setSort] = React.useState<Record<string, string>>({});

  const { data, isLoading: clientsListLoading } = useClientsList({
    filters: {
      q: debouncedSearchText,
      clientId: debouncedUserIdText,
      status: statusFilter.length ? statusFilter : ['1', '2', '3'],
    },
    currentPage,
    sort,
  });

  React.useEffect(() => {
    track(EventNames.providerWebClients, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.viewClients,
      eventLabel: 'view_clients',
    });
  }, []);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [statusFilter, debouncedSearchText, decodedCaseIdText]);

  const onTableRowClick = (rowData: Row<IClientSummary>) => {
    const clientData = rowData.original;
    const { userId, providerRole } = clientData;
    navigate(`/clients/${providerRole}/${encodeCode(userId)}`);
  };

  const onSortClick = (colName: string, sortDir: string | false) => {
    track(EventNames.providerWebClients, {
      eventAction: EventActions.click,
      eventCategory: colName,
      eventLabel: sortDir || 'default',
      featureVersion: 'v1',
    });

    const fieldName =
      sortFieldNameFromColumnId[
        colName as keyof typeof sortFieldNameFromColumnId
      ];

    if (sortDir === false) {
      setSort({});
    } else {
      setSort({ [fieldName]: sortDir.toUpperCase() });
    }
  };

  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const onSelectStatus = (selectedItems: string[]) => {
    setStatusFilter(selectedItems);

    track(EventNames.providerWebClients, {
      eventAction: EventActions.click,
      eventLabel: 'status',
      featureVersion: 'v1',
      eventValue:
        selectedItems.length === statusOptionsV2.length
          ? 'All status'
          : statusOptionsV2
              .filter((val) => selectedItems.includes(val.value))
              .map((filtered) => filtered.label),
    });
  };

  React.useEffect(() => {
    if (caseIdText.length === 6) {
      setDecodedCaseIdText(decodeCode(caseIdText.toUpperCase()).toString());
    } else {
      setDecodedCaseIdText('');
    }
  }, [caseIdText]);

  const noClient = ![]?.length && !debouncedSearchText && !debouncedUserIdText;
  const tStatusOptionsV2 = statusOptionsV2.map(({ label, value }) => ({
    label: t(label),
    value,
  }));
  return (
    <article
      className="flex flex-col m-[36px] gap-[1rem]"
      style={{ width: 'calc(100% - 72px)', fontFamily: 'Inter, sans-serif' }} // TODO temporary
    >
      <section className="p-0 flex justify-between">
        <Typography weight="600" color="primary" size={24}>
          {t('clients')}
        </Typography>
      </section>
      <section className="flex items-center w-full justify-between gap-[1rem]">
        <Input
          containerClassName="w-[60%]"
          startIcon={<SearchIcon className="w-4 text-gray-400" />}
          placeholder={t('SEARCH_ORG_NAME_PLACEHOLDER')}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Input
          containerClassName="w-[60%]"
          startIcon={<SearchIcon className="w-4 text-gray-400" />}
          placeholder={t('SEARCH_CASEID')}
          value={caseIdText}
          onChange={(e) => setCaseIdText(e.target.value)}
          maxLength={6}
        />
        <Select
          multiple
          options={tStatusOptionsV2}
          selected={statusFilter}
          onSelect={(val) => {
            const newValue = val[val.length - 1];
            if (newValue === unMatchedStatusCode) {
              return onSelectStatus([unMatchedStatusCode]);
            }

            if (val.includes(unMatchedStatusCode)) {
              const filteredStatus = val?.filter(
                (item) => item !== unMatchedStatusCode,
              );

              return onSelectStatus(filteredStatus);
            }

            return onSelectStatus(val);
          }}
          placeholder={t('status')}
        />
      </section>
      <section>
        <Table
          isLoading={clientsListLoading}
          data={data?.conversations || []}
          columns={clientsColDefinition}
          onTableRowClick={onTableRowClick}
          onSortClick={onSortClick}
          noDataComponent={
            <NoResultsFound
              captionCopy={!noClient ? t('NO_RESULTS_FOUND') : t('NO_CLIENTS')}
              imageSrc={noSearchResults}
              description={
                !noClient ? t('NO_RESULTS_ERR') : t('NO_CLIENTS_MSG')
              }
              width="100%"
            />
          }
          manualSorting
        />
      </section>
      {data?.conversations.length ? (
        <Pagination
          siblingCount={1}
          currentPage={data.page}
          onPageChange={onPageChange}
          pageSize={data.pageSize}
          totalCount={data.total}
          className=""
        />
      ) : null}
    </article>
  );
}

export default Clients;
