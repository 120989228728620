import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import useCaseNotesDetails, {
  getAllCaseNoteOptions,
} from '../../../utilities/hooks/useCaseNotesDetails/useCaseNotesDetails';
import Loader from '../../components/Loader';
import { CaseNoteFormType } from '../../../shared/constant/CoachingCaseNotes';
import FollowupSessionCaseNotes from '../FollowupSessions';
import NotFound from '../../screens/NotFound';
import CareIntake from '../CareIntake';
import OngoingSession from '../FollowupSessions/FollowupSessionOngoing';
import { getClients as _getClients } from '../../../shared/redux/actions/provider';
import { IClientSummary } from '../../../shared/types/response/provider';
import decodeCode from '../../../utilities/common/Encryption';

interface ICareCaseNotes {
  formType?: CaseNoteFormType;
  // clientDetail?: IProviderClient;
  showDrawer?: boolean;
  selectedClientId?: string;
  onClose?: () => void;
  selectedMeetingId?: string;
  inSession?: boolean;
  clientList: IClientSummary[];
  getClients: () => Promise<Array<IClientSummary>>;
  role?: string;
}
function CareCaseNotes({
  formType,
  showDrawer,
  selectedClientId,
  onClose,
  selectedMeetingId,
  inSession = false,
  clientList,
  getClients,
  role,
}: ICareCaseNotes) {
  let { meetingId } = useParams();
  const { serviceType } = useParams();
  meetingId = inSession ? selectedMeetingId : meetingId;
  const caseNotesData = useCaseNotesDetails({
    meetingId: meetingId as string,
    formType: formType as CaseNoteFormType,
    afterAddingCaseNotes: onClose,
  });
  const followupCaseNotesOptions = getAllCaseNoteOptions(
    caseNotesData?.newCaseNoteAnswers?.form as string,
    inSession ? (role as string) : (serviceType as string),
  );
  const { isQuestionaireLoading, isCaseNoteAnswersLoading } = caseNotesData;
  const { isOptionsLoading } = followupCaseNotesOptions;
  React.useEffect(() => {
    if (inSession) getClients();
  }, [inSession]);
  const selectedClientData = React.useMemo(
    () =>
      clientList?.filter(
        (item) =>
          item.userId ===
          Number(decodeCode(selectedClientId?.toString() || '')),
      )?.[0],
    [inSession, clientList],
  );
  if (isQuestionaireLoading || isCaseNoteAnswersLoading || isOptionsLoading)
    return <Loader withBackdrop={false} useFullPage />;

  if (
    caseNotesData.newCaseNoteAnswers?.form
      .toString()
      .includes(CaseNoteFormType.V4.toString())
  )
    return (
      <FollowupSessionCaseNotes
        caseNotesData={caseNotesData}
        caseNotesQuestionOptions={followupCaseNotesOptions}
        formType={CaseNoteFormType.V4}
      />
    );

  if (
    caseNotesData.newCaseNoteAnswers?.form
      .toString()
      .includes(CaseNoteFormType.V3.toString())
  )
    return <CareIntake formType={CaseNoteFormType.V3} />;

  if (
    caseNotesData.newCaseNoteAnswers?.form
      .toString()
      .includes(CaseNoteFormType.V5.toString()) ||
    caseNotesData.newCaseNoteAnswers?.form
      .toString()
      .includes(CaseNoteFormType.V7.toString())
  ) {
    return (
      <OngoingSession
        caseNotesData={caseNotesData}
        caseNotesQuestionOptions={followupCaseNotesOptions}
        show={showDrawer as boolean}
        clientId={selectedClientId as string}
        onClose={onClose}
        meetingId={meetingId as string}
        selectedClientData={selectedClientData}
      />
    );
  }
  return <NotFound />;
}

const mapStateToProps = (state: any) => ({
  clientData: state.provider.selectedClient,
  clientList: state.provider.clients,
});
const mapDispatchToProps = (dispatch: Function) => ({
  getClients: () => dispatch(_getClients()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CareCaseNotes);
