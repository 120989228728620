import Config from '../../../../shared/Config';
import NetworkClient from '../../../../shared/service/Network';
import { beautifyUrl } from '../../../../utilities/common/Path';

const client = new NetworkClient();

const errorMessages = {
  generic: 'Unable to fetch client sessions, please try again!',
};

export const getSessionsWithClient = async (
  queryParams: {
    role?: string;
    page: number;
    pageSize: number;
  },
  clientId: string,
) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.sessions.sessionsWithClient, [clientId]),
    {},
    queryParams,
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};
