import { getCurrentLocale } from '../../../i18n';
import {
  getInsightsDetails,
  getInsightAssignmentDetails,
} from '../../../shared/service/API/insights';
import {
  InDepthCardDataType,
  BaseCardDataType,
  GoalsDataType,
  InsightAPIResponseType,
  InsightAssignmentsAPIResponseType,
} from '../../../view/screens/Insights/Insights.d';
import { useFetch } from '../useFetch';

// Data manipulation for overview section
function createOverviewData({
  clients,
  averageMeetings,
  activeClientsIn90Days,
  rated5,
}: {
  clients?: Number;
  averageMeetings?: string;
  activeClientsIn90Days?: string;
  rated5?: string;
}) {
  const baseCardData: BaseCardDataType[] = [
    {
      id: '1',
      cardValue: clients?.toString() || '-',
      cardDescription: 'clients',
    },
    {
      id: '2',
      cardValue: activeClientsIn90Days ? `${activeClientsIn90Days}%` : '-',
      cardDescription: 'CLIENT_90D_SESSION',
    },
    {
      id: '3',
      cardValue: averageMeetings || '-',
      cardDescription: 'AVG_SESSIONS',
    },
    {
      id: '4',
      cardValue: rated5 ? `${rated5}%` : '-',
      cardDescription: 'SESSIONS_5STAR',
    },
  ];

  return baseCardData;
}

// Data manipulation for In depth look section
const currentLang = getCurrentLocale();
const assignmentLocalizationKeys = <any>{
  course: 'LEARNING_PATH',
  rescue: 'RESCUE_SESSIONS',
  journal: 'JOURNALS',
  meditation: 'MEDITATIONS',
  questionnaire: 'PERSONAL_INSIGHTS',
  custom: 'EXTERNAL_RESOURCES',
};
function createInDepthLookData(inDepthData: any, insightAssignmentData: any) {
  const goalData = inDepthData?.goals?.map((goal: GoalsDataType) => ({
    id: goal.id,
    description:
      currentLang === 'en' ? goal.areaExpertise : goal.areaExpertiseTranslation,
    count: goal.count || '-',
  }));
  const assignmentData =
    insightAssignmentData?.data &&
    Object.keys(insightAssignmentData?.data)
      .filter((assignment) => assignment !== 'isNewProvider')
      .map((assignment: any) => ({
        id: assignment,
        description: assignmentLocalizationKeys[assignment],
        count: `${`${
          insightAssignmentData.data[assignment]?.completed >= 0
            ? insightAssignmentData.data[assignment]?.completed
            : '-'
        }/${
          insightAssignmentData.data[assignment]?.total >= 0
            ? insightAssignmentData.data[assignment]?.total
            : '-'
        }`} `,
      }));
  const inDepthCardData: InDepthCardDataType[] = [
    {
      id: '1',
      header: '',
      insightInfo: 'DORMANT_EXPLANATION',
      cardValue: inDepthData?.clients?.total || '-',
      cardDescription: 'TOTAL_CLIENTS',
      tableData: [
        {
          id: 1,
          description: 'ACTIVE_CLIENTS',
          count: inDepthData?.clients?.active || '-',
        },
        {
          id: 2,
          description: 'DORMANT_CLIENTS',
          count: inDepthData?.clients?.dormant || '-',
        },
        {
          id: 3,
          description: 'UNMATCHED_CLIENTS',
          count: inDepthData?.clients?.unmatched || '-',
        },
      ],
    },
    {
      id: '2',
      header: 'TOP_GOALS',
      insightInfo: 'GOALS_EXPLANATION',
      cardValue: inDepthData?.hoursSpent || '-',
      cardDescription: 'HOURS_SPENT',

      tableData: goalData,
    },
    {
      id: '3',
      header:
        assignmentData?.length > 1 ? 'ASSIGNMENT_COMPLETED' : 'NO_ASSIGNMENT',
      insightInfo: 'ASSIGNMENT_EXPLANATION',
      cardValue: insightAssignmentData?.data?.total?.total || '0',
      cardDescription: 'ASSIGNMENT_SENT',
      tableData: assignmentData?.slice(1, assignmentData.length),
    },
    {
      id: '4',
      header: '',
      insightInfo: 'RATING_EXPLANATION',
      cardValue: inDepthData?.rated5?.total || '-',
      cardDescription: 'SESSIONS_5STAR',
      tableData: [
        {
          id: 1,
          description: 'FELT_SUPPORTED',
          count: inDepthData?.rated5?.feltSupported || '-',
        },
        {
          id: 2,
          description: 'GAINED_NEW_SKILL',
          count: inDepthData?.rated5?.gainedInsight || '-',
        },
        {
          id: 3,
          description: 'FEEL_CLOSER_GOAL',
          count: inDepthData?.rated5?.closerToGoal || '-',
        },
      ],
    },
    {
      id: '5',
      header: '',
      insightInfo: '',
      cardValue: inDepthData?.visibility || '',
      cardDescription: 'PROVIDER_VISIBILITY',
      tableData: [],
      visibilityCriteria: 'VISIBILITY_CALC',
    },
  ];
  return inDepthCardData;
}

function useInsights() {
  const {
    data: insightData,
    isLoading,
    refetch,
  } = useFetch<InsightAPIResponseType>(
    ['getInsightsDetails'],
    getInsightsDetails,
  );
  const { data: insightAssignmentData, isLoading: insightAssignmentLoading } =
    useFetch<InsightAssignmentsAPIResponseType>(
      ['getInsightAssignmentDetails'],
      getInsightAssignmentDetails,
    );
  const isNewProvider: boolean | undefined =
    insightData?.data?.isNewProvider ||
    insightAssignmentData?.data?.isNewProvider;

  if (!isLoading && !insightAssignmentLoading && !isNewProvider) {
    const lifeTimeData: BaseCardDataType[] = createOverviewData(
      insightData?.data?.overview?.lifeTime,
    );
    const YTDData: BaseCardDataType[] = createOverviewData(
      insightData?.data?.overview?.yearToDate,
    );
    const inDepthCardData: InDepthCardDataType[] = createInDepthLookData(
      insightData?.data?.inDepth,
      insightAssignmentData,
    );
    return {
      insightData: { YTDData, lifeTimeData, inDepthCardData },
      isLoading,
      refetch,
      isNewProvider,
    };
  }
  return { insightData, isLoading, refetch, isNewProvider };
}

export default useInsights;
