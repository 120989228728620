import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import InfoTile from '../../components/InfoTile';
import { Tag } from '../../components/Tag';
import Tooltip from '../../components/Tooltip';
import ClientDetailsContext from './context/ClientDetailsContext';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';

export default function UserGoalsCard() {
  const { clientDetailData, isLoading } = useContext(ClientDetailsContext);
  const { t } = useTranslation();

  const clientGoals = clientDetailData?.goals ?? [];

  if (isLoading) {
    return <ClientOverviewCardLoader variant="card" />;
  }

  return (
    <BasicCardTemplate heading={t('GOALS')} showToolTip={false}>
      {!clientGoals.length ? (
        <InfoTile
          lineClamp={2}
          variant="gray"
          showIcon
          content={t('NO_GOALS_STATE')}
        />
      ) : (
        <section className="flex flex-wrap gap-2">
          {clientGoals?.map((goal, index) => (
            <>
              <Tag
                key={goal}
                className="truncate max-w-[165px] text-xs text-center"
                variant="primary"
                tooltipId={`${goal}-${index}`}
              >
                {goal}
              </Tag>
              <Tooltip id={`${goal}-${index}`} content={goal} />
            </>
          ))}
        </section>
      )}
    </BasicCardTemplate>
  );
}
