/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import Typography from '../../components/Typography';
import IntroductionScreen from '../Introduction';
import './ResetPasswordScreen.scss';
import useResetPassword from '../../../utilities/hooks/useResetPassword/useResetPassword';
import Loader from '../../components/Loader';
import eyeSlash from '../../../assets/eye-off.svg';
import eyeOpen from '../../../assets/eye-open.svg';
import PasswordStrengthValidator from '../../components/PasswordStrengthValidator';

interface IResetFormInput {
  email: string;
  otp: string;
  newPassword: string;
  confirmPassword: string;
}

function ResetPasswordScreen() {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { isValid, errors },
  } = useForm<IResetFormInput>({
    defaultValues: {},
  });
  const [showPasswordField, setShowPasswordField] =
    React.useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [isValidPassword, setIsValidPassword] = React.useState<boolean>(false);

  const {
    isPasswordResetCodeLoading,
    isrequestResetPasswordLoading,
    requestResetPassword,
    getPasswordResetCode,
  } = useResetPassword({
    ongetPasswordResetCodeSucess: (status: boolean) => {
      setShowPasswordField(status);
      if (!status) {
        toast.error(t('UNREGISTERED_EMAIL_ERR'));
      }
    },
  });
  const onContinue = (data: IResetFormInput) => {
    if (showPasswordField) {
      requestResetPassword({
        email: data.email,
        otp: data.otp,
        password: data.newPassword,
      });
    } else {
      getPasswordResetCode(data.email);
    }
  };
  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <section className="reset-container">
      {(isPasswordResetCodeLoading || isrequestResetPasswordLoading) && (
        <Loader />
      )}
      <section className="reset-action-container">
        <section className="reset-body">
          <section className="reset-header-container">
            <Typography weight="600" size={16} textAlign="center">
              {showPasswordField
                ? t('RESET_PWD_HEADING')
                : t('RESET_YOUR_PASSWORD')}
            </Typography>
            {!showPasswordField && (
              <Typography size={14} withColor="#213547">
                {t('RESET_PASSWORD_SUBHEADING')}
              </Typography>
            )}
          </section>
          <form onSubmit={handleSubmit(onContinue)}>
            <input
              type="email"
              id="email"
              {...register('email', { required: 'Email is required' })}
              placeholder={t('emailAddress')}
            />
            {showPasswordField && (
              <>
                <input
                  type="text"
                  id="otp"
                  {...register('otp', { required: 'OTP is required' })}
                  placeholder={t('ENTER_OTP_PLACEHOLDER')}
                  className="magic-code-input"
                />
                <section className="password-wrapper">
                  <input
                    type={showNewPassword ? 'text' : 'password'}
                    id="newPassword"
                    {...register('newPassword', {
                      required: 'Password is required',
                    })}
                    placeholder={t('ENTER_NEW_PASSWORD')}
                    autoComplete="new-password"
                  />
                  <img
                    src={showNewPassword ? eyeOpen : eyeSlash}
                    alt="show-password -icon"
                    onClick={toggleShowNewPassword}
                  />
                </section>

                <PasswordStrengthValidator
                  onChange={(isValidPw) => setIsValidPassword(isValidPw)}
                  password={watch('newPassword')}
                />

                <section className="password-wrapper">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    {...register('confirmPassword', {
                      required: 'Password is required',
                      validate: (value) => {
                        if (value !== getValues('newPassword')) {
                          return t('PWD_UNMATCH_ERR');
                        }
                        return undefined;
                      },
                    })}
                    placeholder={t('CONFIRM_NEW_PASSWORD')}
                  />
                  <img
                    src={showConfirmPassword ? eyeOpen : eyeSlash}
                    alt="show-password -icon"
                    onClick={toggleShowConfirmPassword}
                  />
                </section>
              </>
            )}
            {errors.confirmPassword && (
              <Typography size={12} withColor="red">
                {errors.confirmPassword.message}
              </Typography>
            )}
            <section className="btn-container">
              <motion.input
                whileHover={{ scale: 1.025 }}
                whileTap={{ scale: 0.975 }}
                className="continue-button"
                value={t('continue')}
                type="submit"
                disabled={!isValid || (showPasswordField && !isValidPassword)}
              />
            </section>
          </form>
        </section>
      </section>
      <IntroductionScreen />
    </section>
  );
}

export default ResetPasswordScreen;
