import * as React from 'react';
import Backdrop from '../Backdrop';
import './SideDrawer.scss';
import closeIcon from '../../../assets/close.svg';
import useSideDrawer from '../../../utilities/hooks/useSideDrawer/useSideDrawer';

interface ISideDrawer {
  children: React.ReactNode;
  show: boolean;
  hideHandler?: () => void;
  onCloseHandler?: () => void;
  className?: string;
}
// TODO future - implement stacking logic
function SideDrawer({
  children,
  show,
  hideHandler,
  className,
  onCloseHandler,
}: ISideDrawer) {
  const { beforeHideHandler } = useSideDrawer();
  const [isDrawerOpen, setDrawerOpen] = React.useState(true);

  const hideDrawer = () => {
    const blockExecution = beforeHideHandler?.();
    if (blockExecution) {
      return;
    }
    setDrawerOpen(false);
    hideHandler?.();
  };

  React.useLayoutEffect(() => {
    if (show) {
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  }, [show]);

  return (
    <>
      {isDrawerOpen && <Backdrop />}
      <div
        className={`side-drawer-container ${isDrawerOpen ? 'open' : ''} ${
          isDrawerOpen ? className : ''
        }`}
      >
        {isDrawerOpen && (
          <div
            className="control"
            onClick={onCloseHandler || hideDrawer}
            role="button"
            tabIndex={0}
          >
            <img src={closeIcon} alt="close" />
          </div>
        )}
        {children}
      </div>
    </>
  );
}

export default SideDrawer;
