import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal';
import MultilineInputWithCounter from '../../components/MultilineInputWithCounter';
import ClientDetailsContext from './context/ClientDetailsContext';
import { useAddClientNote, useEditClientNote } from './hooks/UseClientNotes';
import { IClientNote } from './types/client';
// import {
//   EventActions,
//   EventCategories,
//   EventNames,
// } from '../../../../shared/constant/Analytics';
// import useTracking from '../../../../utilities/hooks/useTracking';

interface AddNotesModalProps {
  open: boolean;
  existingNote?: IClientNote;
  handleClose: () => void;
  handleSuccess: () => void;
}
function AddNotesModal({
  open,
  handleClose,
  existingNote,
  handleSuccess,
}: AddNotesModalProps) {
  const { decodedClientId, serviceType } = useContext(ClientDetailsContext);

  const { t } = useTranslation();
  // const { track } = useTracking();

  const [newNote, setNewNote] = useState(existingNote?.note ?? '');

  useEffect(() => {
    setNewNote(existingNote?.note ?? '');
  }, [open, existingNote?.note]);

  const onClose = () => {
    handleClose();
  };

  const handleApiSuccess = () => {
    onClose();
    handleSuccess();
  };

  const { mutate: addNote, isLoading: isAddNotesLoading } = useAddClientNote(
    handleApiSuccess,
    decodedClientId,
  );

  const { mutate: editNote, isLoading: isEditNotesLoading } = useEditClientNote(
    handleApiSuccess,
    decodedClientId,
    existingNote?.id,
  );

  const handleSubmit = () => {
    // track(EventNames.clientView, {
    //   eventAction: EventActions.click,
    //   eventCategory: EventCategories.providerNote,
    //   eventLabel: 'save',
    // });
    if (existingNote?.id) {
      editNote({ note: newNote.toString().trim() });
    } else {
      addNote({
        note: newNote.toString().trim(),
        userId: decodedClientId ? +decodedClientId : undefined,
        role: serviceType,
      });
    }

    handleClose();
  };

  if (!open) return null;

  return (
    <Modal
      open={open}
      handleClose={onClose}
      title={existingNote?.id ? t('EDIT_NOTE_CTA') : t('ADD_NOTE_CTA')}
      className="w-5/12"
    >
      <MultilineInputWithCounter
        inputData={newNote}
        setInputData={setNewNote}
        handleSubmit={handleSubmit}
        loading={isAddNotesLoading || isEditNotesLoading}
      />
    </Modal>
  );
}

export default AddNotesModal;
