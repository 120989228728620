import * as React from 'react';
import './Sessions.scss';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import CalenderView from '../../app-components/CalenderView';
import ScheduledSessions from '../../app-components/ScheduledSessions';
import Typography from '../../components/Typography';
import {
  getProviderSessions as _getProviderSessions,
  getCaseNoteQuestions as _getCaseNoteQuestions,
} from '../../../shared/redux/actions/provider';
import { getChatToken as _getChatToken } from '../../../shared/redux/actions/chat';
import {
  ClientMeetingData,
  LeaveData,
  SessionData,
} from '../../../shared/types/response/provider';
import SideDrawer from '../../components/SideDrawer';
import CaseNotes from '../../app-components/CaseNotes';
import withTracking from '../../../utilities/hoc/withTracking';
import { TrackFunction } from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';
import { getCurrentPath } from '../../../utilities/common/Path';

export interface ISessionsProps {
  getProviderSessions: Function;
  provider: {
    sessions: SessionData[];
    holidays: LeaveData[];
  };
  getCaseNoteQuestions: () => void;
  getChatToken: () => void;
  track: TrackFunction;
  t: TFunction<'translation', undefined>;
}

export interface ISessionsState {
  selectedDay: string;
  showCaseNoteDrawer: boolean;
  selectedClient: ClientMeetingData;
}

class Sessions extends React.Component<ISessionsProps, ISessionsState> {
  constructor(props: ISessionsProps) {
    super(props);

    this.state = {
      selectedDay: moment().toISOString(),
      showCaseNoteDrawer: false,
      selectedClient: { clientId: '', meetingId: '', meetingDate: '' },
    };
  }

  componentDidMount() {
    const { getProviderSessions, getCaseNoteQuestions, getChatToken, track } =
      this.props;
    getProviderSessions();
    getCaseNoteQuestions();
    getChatToken();
    if (getCurrentPath() === '/') {
      track(EventNames.upcomingSessions, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.upcomingSessions,
        eventLabel: 'upcoming_sessions',
        eventSource: 'provider_click',
      });
    }
  }

  onMonthChange = (month: string) => {
    const { getProviderSessions } = this.props;
    getProviderSessions(month);
  };

  onCalenderDayClick = (day: string) => {
    this.setState({ selectedDay: day });
  };

  onCloseClientCaseNote = () => {
    this.setState({ showCaseNoteDrawer: false });
  };

  refetchProviderSessions = () => {
    const { getProviderSessions } = this.props;
    const { selectedDay } = this.state;

    getProviderSessions(selectedDay);
  };

  public render() {
    const { selectedDay, showCaseNoteDrawer, selectedClient } = this.state;
    const { provider, t } = this.props;
    const { sessions, holidays } = provider;
    return (
      <div className="sessions-container">
        <SideDrawer
          show={showCaseNoteDrawer}
          hideHandler={this.onCloseClientCaseNote}
        >
          {showCaseNoteDrawer && (
            <CaseNotes mode="edit" client={selectedClient} />
          )}
        </SideDrawer>
        <div className="heading-container">
          <Typography weight="600" color="primary" size={24}>
            {t('upcomingSessions')}
          </Typography>
        </div>
        <div className="interactions-container">
          <CalenderView
            onMonthChange={this.onMonthChange}
            onCalenderDayClick={this.onCalenderDayClick}
            sessions={sessions}
            leaves={holidays}
          />
          <ScheduledSessions
            leaves={holidays}
            selectedDay={selectedDay}
            sessions={sessions}
            refetchSessions={this.refetchProviderSessions}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({ provider: state.provider });

const mapDispatchToProps = (dispatch: Function) => ({
  getProviderSessions: (month: string) => dispatch(_getProviderSessions(month)),
  getCaseNoteQuestions: () => dispatch(_getCaseNoteQuestions()),
  getChatToken: () => dispatch(_getChatToken()),
});

const sessionsWithTranslations = withTranslation()(Sessions);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTracking(sessionsWithTranslations));
