/* eslint-disable */
import * as React from 'react';
import {
  CaseNoteQuestionTypes,
  Recommendations,
  RiskAssesment,
} from '../../../shared/constant/CoachingCaseNotes';
import {
  ICaseNoteFormInputs,
  ICaseNoteItem,
  ICaseNoteQuestionResp,
  ICaseNoteResp,
  IOptionWithQuestionId,
  IOptions,
  IRecommendations,
} from './types';
import { ICareIntakeFormInputs } from '../CareIntake/FormParts/types';

interface OptionswithChildren extends IOptions {
  children?: IOptions[];
}
const getNoteDataByType = (
  type: CaseNoteQuestionTypes,
  answerBasedOnId: { [key: string]: ICaseNoteItem },
  questionsBasedOnType: { [key: string]: { question: string; id: number } },
  options?: OptionswithChildren[],
) => {
  let savedData = answerBasedOnId?.[questionsBasedOnType?.[type]?.id]?.note;
  if (!savedData) {
    return;
  }
  savedData = JSON.parse(savedData);
  if (options) {
    if (Array.isArray(savedData)) {
      return {
        id: savedData,
        questionId: questionsBasedOnType[type].id.toString(),
      };
    }
    let selected = {} as IOptions;
    options.forEach((option) => {
      if (option?.children) {
        const [selectedChild] = option.children.filter(
          (child) => child.id === savedData,
        );
        if (selectedChild) {
          selected = selectedChild;
        }
      }
      if (option.id === savedData) {
        selected = option;
      }
    });
    return {
      ...selected,
      questionId: questionsBasedOnType[type].id.toString(),
    };
  }
  return {
    id: savedData,
    questionId: questionsBasedOnType[type].id.toString(),
  };
};

const getAdditionalSupportData = (
  answerBasedOnId: { [key: string]: ICaseNoteItem },
  questionsBasedOnType: { [key: string]: { question: string; id: number } },
) => {
  let savedData =
    answerBasedOnId?.[
      questionsBasedOnType?.[CaseNoteQuestionTypes.Recommendations]?.id
    ]?.note;
  if (!savedData) {
    return;
  }
  savedData = JSON.parse(savedData);
  return (
    Array.isArray(savedData) &&
    savedData?.[
      savedData?.findIndex(
        (item: { value: number }) =>
          item.value === Recommendations.NeedsAdditionalSupport,
      )
    ]?.additionalSupport
  );
};

export function createCoachingDefaultFormData<T>(
  caseNoteAnswerData: ICaseNoteResp,
  caseNoteQuestionData: ICaseNoteQuestionResp[],
  riskCategoriesOption: IOptions[],
  techniqueCategoriesOption: IOptions[],
  progressCategoriesOption: IOptions[],
  recommendationCategoriesOption: IOptions[],
  sharedFollowUpCategories: IOptions[],
  issuesOption: IOptions[],
) {
  const questionsByType: { [key: string]: { question: string; id: number } } =
    {};
  caseNoteQuestionData?.forEach((question: any) => {
    questionsByType[question.type] = {
      question: question.question,
      id: question.id,
    };
  });
  const answerById = {} as { [key: string]: ICaseNoteItem };
  caseNoteAnswerData.caseNotes?.forEach((caseItem: any) => {
    answerById[caseItem.questionId] = caseItem;
  });

  const defaultFormData = {
    additonalSessionRecommended: getNoteDataByType(
      CaseNoteQuestionTypes.AdditonalSessionRecommended,
      answerById,
      questionsByType,
    ),
    additionalSupport: getAdditionalSupportData(answerById, questionsByType),
    followUpPlan: getNoteDataByType(
      CaseNoteQuestionTypes.FollowUpPlan,
      answerById,
      questionsByType,
    ),
    primaryGoal: getNoteDataByType(
      CaseNoteQuestionTypes.PrimaryGoal,
      answerById,
      questionsByType,
      issuesOption,
    ),
    progressTowardsGoals: getNoteDataByType(
      CaseNoteQuestionTypes.ProgressTowardsGoals,
      answerById,
      questionsByType,
      progressCategoriesOption,
    ),
    recommendations: getNoteDataByType(
      CaseNoteQuestionTypes.Recommendations,
      answerById,
      questionsByType,
      recommendationCategoriesOption,
    ),
    riskAssessment: getNoteDataByType(
      CaseNoteQuestionTypes.RiskAssesment,
      answerById,
      questionsByType,
      riskCategoriesOption,
    ),
    secondaryGoal: getNoteDataByType(
      CaseNoteQuestionTypes.SecondaryGoal,
      answerById,
      questionsByType,
    ),
    sessionNotes: getNoteDataByType(
      CaseNoteQuestionTypes.SessionNotes,
      answerById,
      questionsByType,
    ),
    sharedFollowUpPlan: getNoteDataByType(
      CaseNoteQuestionTypes.SharedFollowUpPlan,
      answerById,
      questionsByType,
      sharedFollowUpCategories,
    ),
    interventionsAndModality: getNoteDataByType(
      CaseNoteQuestionTypes.InterventionsAndModality,
      answerById,
      questionsByType,
      techniqueCategoriesOption,
    ),
    suicidalAssessment: getNoteDataByType(
      CaseNoteQuestionTypes.SuicidalAssesment,
      answerById,
      questionsByType,
    ),
  };

  return defaultFormData as T;
}

export function createCareIntakeDefaultFormData<T>(
  caseNoteAnswerData: ICaseNoteResp,
  caseNoteQuestionData: ICaseNoteQuestionResp[],
  riskCategoriesOption: IOptions[],
  recommendationCategoriesOption: IOptions[],
  sharedFollowUpCategories: IOptions[],
  issuesOption: IOptions[],
) {
  const questionsByType: { [key: string]: { question: string; id: number } } =
    {};
  caseNoteQuestionData?.forEach((question: any) => {
    questionsByType[question.type] = {
      question: question.question,
      id: question.id,
    };
  });
  const answerById = {} as { [key: string]: ICaseNoteItem };
  caseNoteAnswerData.caseNotes?.forEach((caseItem: any) => {
    answerById[caseItem.questionId] = caseItem;
  });

  const defaultFormData = {
    additonalSessionRecommended: getNoteDataByType(
      CaseNoteQuestionTypes.AdditonalSessionRecommended,
      answerById,
      questionsByType,
    ),
    additionalSupport: getAdditionalSupportData(answerById, questionsByType),
    followUpPlan: getNoteDataByType(
      CaseNoteQuestionTypes.FollowUpPlan,
      answerById,
      questionsByType,
    ),
    primaryGoal: getNoteDataByType(
      CaseNoteQuestionTypes.PrimaryGoal,
      answerById,
      questionsByType,
      issuesOption,
    ),
    recommendations: getNoteDataByType(
      CaseNoteQuestionTypes.Recommendations,
      answerById,
      questionsByType,
      recommendationCategoriesOption,
    ),
    riskAssessment: getNoteDataByType(
      CaseNoteQuestionTypes.RiskAssesment,
      answerById,
      questionsByType,
      riskCategoriesOption,
    ),
    secondaryGoal: getNoteDataByType(
      CaseNoteQuestionTypes.SecondaryGoal,
      answerById,
      questionsByType,
    ),
    sessionNotes: getNoteDataByType(
      CaseNoteQuestionTypes.SessionNotes,
      answerById,
      questionsByType,
    ),
    sharedFollowUpPlan: getNoteDataByType(
      CaseNoteQuestionTypes.SharedFollowUpPlan,
      answerById,
      questionsByType,
      sharedFollowUpCategories,
    ),
    suicidalAssessment: getNoteDataByType(
      CaseNoteQuestionTypes.SuicidalAssesment,
      answerById,
      questionsByType,
    ),
    presentingIssuesHistory: getNoteDataByType(
      CaseNoteQuestionTypes.HistoryOfPI,
      answerById,
      questionsByType,
    ),
    medicalMentalHistory: getNoteDataByType(
      CaseNoteQuestionTypes.MedicalAndMentalHistory,
      answerById,
      questionsByType,
    ),
    familyAndRelationshipHistory: getNoteDataByType(
      CaseNoteQuestionTypes.FamilyAndRelationshipHistory,
      answerById,
      questionsByType,
    ),
    childhoodExperiences: getNoteDataByType(
      CaseNoteQuestionTypes.ChildhoodExperiences,
      answerById,
      questionsByType,
    ),
    socialHistory: getNoteDataByType(
      CaseNoteQuestionTypes.SocialHistory,
      answerById,
      questionsByType,
    ),
    workAndEducationalHistory: getNoteDataByType(
      CaseNoteQuestionTypes.WorkAndEducationalHistory,
      answerById,
      questionsByType,
    ),
    substanceUseHistory: getNoteDataByType(
      CaseNoteQuestionTypes.SubstanceUseHistory,
      answerById,
      questionsByType,
    ),
    currentCopingHabits: getNoteDataByType(
      CaseNoteQuestionTypes.CurrentCopingHabits,
      answerById,
      questionsByType,
    ),
    others: getNoteDataByType(
      CaseNoteQuestionTypes.Others,
      answerById,
      questionsByType,
    ),
    clientGoalsForTherapy: getNoteDataByType(
      CaseNoteQuestionTypes.ClientGoalsForTherapy,
      answerById,
      questionsByType,
    ),
    formulationAndTreatment: getNoteDataByType(
      CaseNoteQuestionTypes.FormulationAndTreatment,
      answerById,
      questionsByType,
    ),
    detailsOfPresentingIssues: getNoteDataByType(
      CaseNoteQuestionTypes.DetailsOfPresentingIssues,
      answerById,
      questionsByType,
    ),
  };

  return defaultFormData as T;
}

export const displayAdditionalSupportEditor = (
  watchSelectedRecommendation: IRecommendations,
) =>
  React.useMemo(
    () =>
      watchSelectedRecommendation?.id.find(
        (item) => item.value === Recommendations.NeedsAdditionalSupport,
      ),
    [watchSelectedRecommendation],
  );

export const showSucidalAssessmentEditors = (
  watchSelectedRisk: IOptionWithQuestionId,
) =>
  React.useMemo(
    () =>
      watchSelectedRisk?.value?.toString() ===
        RiskAssesment.HighRiskNonCritical.toString() ||
      watchSelectedRisk?.value?.toString() ===
        RiskAssesment.HighRiskCritical.toString(),
    [watchSelectedRisk],
  );

export const handleSaveCaseNoteData = (
  data: ICaseNoteFormInputs | ICareIntakeFormInputs,
  showAdditionalSupportEditor: boolean,
  watchSelectedRisk: IOptionWithQuestionId,
  meetingId: string,
  clientId: string,
  saveNotes: Function,
) => {
  const pureData = {} as { [key: string]: any };
  Object.entries(data).forEach(([key, val]) => {
    if (val) pureData[key] = val;
  });
  // TODO: commented below check to allow empty case note submission because of new PI feature
  if (JSON.stringify(pureData) === '{}') {
    return;
  }
  if (showAdditionalSupportEditor) {
    pureData.recommendations.id[
      pureData.recommendations.id.findIndex(
        (item: { value: number }) =>
          item.value === Recommendations.NeedsAdditionalSupport,
      )
    ].additionalSupport = pureData?.additionalSupport;
  }
  delete pureData?.additionalSupport;
  if (
    ![
      RiskAssesment.HighRiskNonCritical.toString(),
      RiskAssesment.HighRiskCritical.toString(),
    ].includes(watchSelectedRisk?.value?.toString())
  ) {
    delete pureData?.suicidalAssessment;
  }
  const caseNote = Object.keys(pureData).map((key) => ({
    questionId: pureData[key].questionId,
    note: JSON.stringify(pureData[key].id),
  }));
  const caseNotePayload = {
    meetingId,
    userId: clientId,
    caseNotes: caseNote,
  };
  saveNotes(caseNotePayload);
};
