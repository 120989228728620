import moment from 'moment';

interface DateBadgeProps {
  timeStamp: number;
}
export default function DateBadge({ timeStamp }: DateBadgeProps) {
  return (
    <section className="box-border w-[54px] h-[54px] rounded-lg border-[.5px] border-solid border-gray-300 text-center flex flex-col items-center justify-start">
      <section className="bg-red-500 h-[19px] leading-[19px] rounded-t-lg  w-full text-white text-[10px] font-medium bg-error-500">
        {moment.unix(timeStamp).format('MMM')}
      </section>
      <section className="w-full h-[35px] leading-[34px] rounded-b-lg leading-full bg-gray-50 text-2xl font-bold">
        {moment.unix(timeStamp).format('DD')}
      </section>
    </section>
  );
}
