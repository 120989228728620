import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useMutation } from '../useFetch';
import {
  requestMagicCode,
  resetPassword,
} from '../../../shared/redux/actions/user';

function useResetPassword(props?: {
  ongetPasswordResetCodeSucess?: (status: boolean) => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    mutate: getPasswordResetCode,
    isLoading: isPasswordResetCodeLoading,
  } = useMutation((email: string) => requestMagicCode(email), {
    onSuccess: (data: { status: boolean }) => {
      props?.ongetPasswordResetCodeSucess?.(data.status);
    },
    onError: (errorData) => {
      toast.error(errorData.message);
    },
  });

  const {
    mutate: requestResetPassword,
    isLoading: isrequestResetPasswordLoading,
  } = useMutation(
    (data: { email: string; otp: string; password: string }) =>
      resetPassword(data),
    {
      onSuccess: () => {
        toast.success(t('PWD_RESET_SUCCESSFUL'));
        navigate('/login');
      },
      onError: (errorData) => {
        toast.error(errorData.message);
      },
    },
  );

  return {
    getPasswordResetCode,
    isPasswordResetCodeLoading,
    requestResetPassword,
    isrequestResetPasswordLoading,
  };
}

export default useResetPassword;
