import { PropsWithChildren } from 'react';
import {
  Tab,
  TabGroup,
  TabList,
  TabPanels,
  TabPanel as HeadlessTabPanel,
} from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  className?: string;

  /** This index *must* match one of the indexes in the
   * tabs list you've defined above.
   */
  index: number;
}

export function TabPanel({
  index,
  className,
  children,
}: PropsWithChildren<TabPanelProps>) {
  return (
    <HeadlessTabPanel
      key={index}
      className={twMerge('bg-gray-25 p-3', className)}
    >
      {children}
    </HeadlessTabPanel>
  );
}

interface TabProps {
  list: { key: number; label: string }[];
  // className for the Tab container, not the tabs themselves.
  className?: {
    tabGroupClass?: string;
    tabClass?: string;
    selectedClass?: string;
    deselectedClass?: string;
  };

  selectedIndex?: number;
  setSelectedIndex?: (newIndex: number) => void;
}

export function Tabs({
  list,
  children,
  className,
  selectedIndex,
  setSelectedIndex,
}: PropsWithChildren<TabProps>) {
  const {
    tabGroupClass,
    tabClass,
    selectedClass = 'text-primary-600 font-semibold border-b-2 border-primary-600',
    deselectedClass = 'text-gray-500 hover:bg-gray-100 border-b border-gray-200',
  } = className ?? {};
  const { t } = useTranslation();
  return (
    <TabGroup
      as="div"
      className={twMerge('w-full', tabGroupClass)}
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
    >
      <TabList className="flex p-1">
        {list.map((tabItem) => (
          <Tab
            key={tabItem.key}
            className={({ selected }) =>
              twMerge(
                'w-full bg-gray-25 py-2 text-sm font-medium leading-5 border-b transition-colors',
                tabClass,
                selected ? selectedClass : deselectedClass,
              )
            }
          >
            {t(tabItem.label)}
          </Tab>
        ))}
      </TabList>
      <TabPanels className="mt-2">{children}</TabPanels>
    </TabGroup>
  );
}
