import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './NoInsightResults.scss';
import NoResultsFound from '../../app-components/NoResultsFound';
import { INoInsightResults } from './types';

function NoInsightResults({
  quizAssignedAndNotTaken,
  onEmptyStateCtaClick,
  disableEmptyStateAction,
  friendlyName,
  isUnmatched,
}: INoInsightResults) {
  const { t } = useTranslation();

  const getCTALabel = () => {
    if (isUnmatched) {
      return undefined;
    }
    if (quizAssignedAndNotTaken) {
      return t('SEND_REMINDER');
    }
    return t('assign');
  };
  return (
    <article className="no-insights-wrapper">
      <NoResultsFound
        width="500px"
        captionCopy={
          quizAssignedAndNotTaken
            ? t('CLIENT_NOT_ATTEMPTED', { name: friendlyName })
            : t('EWOOS_EMPTY_STATE_NOT_ASSIGNED_TITLE')
        }
        description={
          quizAssignedAndNotTaken
            ? t('CLIENT_NOT_ATTEMPTED_PROMPT')
            : t('EWOOS_EMPTY_STATE_NOT_ASSIGNED_DESC')
        }
        ctaWidth="260px"
        ctaLabel={getCTALabel()}
        ctaCallback={onEmptyStateCtaClick}
        ctaDisabled={disableEmptyStateAction}
      />
    </article>
  );
}

export default NoInsightResults;
