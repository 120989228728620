import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IFollowUpSessionsForm,
  IFollowUpSessionsFormSections,
} from '../../FollowUpSession.type';
import SelectBoxV2 from '../../../../components/SelectBoxV2';
import FollowUpSessionCaseNotesControls from '../../FollowUpSessionCaseNotes.controls';
import './InterventionModality.scss';
import { IOptions } from '../../../../../shared/types/CaseNotes';
import Heading from '../../Heading';

interface IInterventionModality {
  headerContent?: React.ReactNode;
  interventionsModalityOptions: IOptions[];
}

function InterventionModality({
  watch,
  setValue,
  headerContent,
  question,
  trackOnBlur,
  trackOnFocus,
  interventionsModalityOptions,
}: IFollowUpSessionsFormSections & IInterventionModality) {
  const { t } = useTranslation();
  const mappedOpt = React.useMemo(
    () =>
      FollowUpSessionCaseNotesControls.getMappedOptionsWithId(
        interventionsModalityOptions,
      ),
    [interventionsModalityOptions],
  );
  const handleChange =
    (key: keyof IFollowUpSessionsForm) => (value: string) => {
      if (setValue) setValue(key, mappedOpt[value]);
    };
  return (
    <div className="interventionModality">
      <section className="primary">
        {headerContent || (
          <Heading headerText={question?.question} toolTipID="5" />
        )}
        <div>
          <SelectBoxV2
            values={interventionsModalityOptions}
            selectedValue={watch('interventionsModality')?.id?.toString()}
            onChange={handleChange('interventionsModality')}
            placeHolderText={t('SELECT')}
            onBlur={() => trackOnBlur?.(question?.id)}
            onFocus={() => trackOnFocus?.()}
          />
        </div>
      </section>
    </div>
  );
}

export default InterventionModality;
