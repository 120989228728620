import { ToasterProps } from 'sonner';
import { ReactComponent as ToastSuccessIcon } from '../assets/toastSuccess.svg';
import { ReactComponent as ToastErrorIcon } from '../assets/toastError.svg';

export const ToastConfig = {
  unstyled: true,
  classNames: {
    toast:
      'min-w-[23rem] py-3 px-4 flex gap-3 items-center rounded-lg shadow text-semibold border',
    error: 'bg-error-25 border-error-300',
    success: 'bg-success-25 border-success-300',
    warning: 'bg-warning-25 border-warning-300',
    info: 'bg-primary-25 border-primary-300',
  },
} satisfies ToasterProps['toastOptions'];

export const ToastIconConfig = {
  error: <ToastErrorIcon />,
  success: <ToastSuccessIcon />,
} satisfies ToasterProps['icons'];
