import moment from 'moment';
import { getCurrentLocale } from '../../i18n';
import { IChatItem } from '../../shared/types/Chat';
import { IClientSummary } from '../../shared/types/response/provider';
import { getLastMessagedTime } from './Chat';

export const lastMessagedTimeComparator = (
  first: IChatItem,
  second: IChatItem,
) => {
  const lastMessagedTimeFirst = moment(getLastMessagedTime(first));
  const lastMessagedTimeSecond = moment(getLastMessagedTime(second));

  if (lastMessagedTimeFirst > lastMessagedTimeSecond) return -1;
  if (lastMessagedTimeFirst < lastMessagedTimeSecond) return 1;

  return 0;
};

export const clientsListingComparator = (
  first: IClientSummary,
  second: IClientSummary,
) => {
  const { user: firstUser } = first;
  const { user: secondUser } = second;

  return (firstUser.friendlyName || '').localeCompare(
    secondUser.friendlyName || '',
    getCurrentLocale(),
    { ignorePunctuation: true },
  );
};
