import { useMutation } from 'react-query';
import { useFetch } from '../../../../../utilities/hooks/useFetch';
import {
  addHelplineRecordComment,
  getHelplineDetails,
  getHelplineRecords,
} from '../../../../shared/service/API/client';
import { HelplineRecord, HelplineRecordDetails } from '../types/helpline';

export function useHelplineRecords(clientId?: string) {
  return useFetch<HelplineRecord[]>(
    ['client-details-v2-helpline-records', clientId],
    () => (clientId ? getHelplineRecords(clientId) : undefined),
  );
}

export const useHelplineRecordDetails = (sessionId: string) =>
  useFetch<HelplineRecordDetails>(
    ['client-details-v2-helpline-record', sessionId],
    () => (sessionId ? getHelplineDetails(sessionId) : undefined),
    {
      refetchOnWindowFocus: false,
    },
  );

export const useHelplineNotes = (options: {
  sessionId: string;
  onSuccess: () => void;
}) => {
  const { sessionId, onSuccess } = options;
  return useMutation(
    (comment: string) => addHelplineRecordComment(sessionId, comment),
    {
      onSuccess,
    },
  );
};
