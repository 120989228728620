import { getConversationsDetails } from '../../../chat-module/services/API/CommonConversation';
import {
  IClientSummary,
  IProviderConversations,
} from '../../../shared/types/response/provider';
import { useFetch } from '../useFetch';

function useClientDetails({
  clientId,
  serviceType,
}: {
  clientId?: string;
  serviceType?: string;
}) {
  const { data: clientProfiledata, isLoading: clientProfileLoading } =
    useFetch<IProviderConversations>(
      ['individual-client-profile-data', clientId, serviceType],
      () => getConversationsDetails({ clientId, serviceType }),
      {
        refetchOnWindowFocus: false,
      },
    );
  return {
    clientProfiledata: clientProfiledata?.data
      ?.conversations?.[0] as unknown as IClientSummary,
    clientProfileLoading,
  };
}

export default useClientDetails;
