import { motion } from 'framer-motion';
import * as React from 'react';
import useOutsideClickHandler from '../../../../../utilities/hooks/useOutsideClickHandler';
import Typography from '../../../Typography';
import { KebabMenuItem } from '../../types';
import './MenuContents.scss';

interface MenuContentsProps {
  attachRef: React.RefObject<HTMLDivElement>;
  menuItems: KebabMenuItem[];
  hideMenuContents: () => void;
}

function MenuContents({
  attachRef,
  menuItems,
  hideMenuContents,
}: MenuContentsProps) {
  const contentsRef = React.useRef<HTMLDivElement>(null);
  const [attachElementRect, setAttachElementRect] = React.useState<
    DOMRect | undefined
  >();
  const [contentElementWidth, setContentElementWidth] =
    React.useState<number>(0);

  const containerTopMargin = 16;
  const { bottom } = attachElementRect || {};
  const contentsContainerPosition = {
    top: (bottom || 0) + containerTopMargin,
    left: (attachElementRect?.left || 0) - contentElementWidth,
  };

  React.useEffect(() => {
    if (attachRef.current) {
      const rect = attachRef.current?.getBoundingClientRect();
      setAttachElementRect(rect);
    }
    if (contentsRef.current) {
      const contentWidth = contentsRef.current.offsetWidth;
      setContentElementWidth(contentWidth);
    }
  }, [attachRef.current, contentsRef.current]);

  useOutsideClickHandler(contentsRef, hideMenuContents);

  return (
    <motion.div
      initial={{ scaleY: 0, opacity: 0, x: '100px' }}
      animate={{ scaleY: 1, opacity: 1, x: '0px' }}
      exit={{ scaleY: 0, opacity: 0, x: '100px' }}
      ref={contentsRef}
      style={{
        top: contentsContainerPosition.top,
        left: contentsContainerPosition.left,
      }}
      className="menu-contents"
    >
      {menuItems.map(({ label, value, onClick, disabled }, index) => (
        <div
          role="button"
          tabIndex={index}
          className="menu-item"
          key={value}
          onClick={() => {
            if (!disabled) {
              onClick();
            }
          }}
        >
          <Typography
            size={12}
            weight="500"
            color={disabled ? 'disabled' : 'primary'}
          >
            {label}
          </Typography>
        </div>
      ))}
    </motion.div>
  );
}

export default MenuContents;
