import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ColumnDef, Row } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
// import Typography from '../../../../view/components/Typography';

import TableLoader from './TableLoader';

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  onTableRowClick?: (data: Row<T>) => void;
  isLoading: boolean;
  noDataComponent?: React.ReactNode | string;
  height?: string;
  onSortClick?: (colName: string, sortDir: string | false) => void;
  manualSorting?: boolean;
}

function CustomTable<T extends object>({
  data,
  columns,
  onTableRowClick,
  isLoading,
  noDataComponent = 'no data found',
  height = 'auto',
  onSortClick,
  manualSorting = false,
}: ReactTableProps<T>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting,
  });
  const { t } = useTranslation();
  const ref = React.useRef<HTMLElement>(null);

  return (
    <article className="w-full" style={{ height }} ref={ref}>
      <table
        width={table.getTotalSize()}
        className="w-full border-separate border-spacing-y-3"
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="rounded-none sticky top-0 z-1">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="h-[36px] border-b border-gray-200 px-4"
                >
                  <span className="text-gray-700 text-xs font-bold">
                    {/* Note: header img cursor pointer class added in column def */}
                    <span className="flex gap-[5px] items-center justify-start">
                      {typeof header.column.columnDef.header === 'function'
                        ? header.column.columnDef?.header(header.getContext())
                        : t(header.column.columnDef.header as string)}
                      {header.column.getCanSort() && (
                        <span
                          onClick={(e) => {
                            header.column.getToggleSortingHandler()?.(e);
                            onSortClick?.(
                              header.id as string,
                              header.column.getNextSortingOrder(),
                            );
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            color={
                              header.column.getIsSorted() === 'asc'
                                ? '#1A2631'
                                : '#98A2B3'
                            }
                          />
                          <FontAwesomeIcon
                            icon={faArrowDown}
                            color={
                              header.column.getIsSorted() === 'desc'
                                ? '#1A2631'
                                : '#98A2B3'
                            }
                          />
                        </span>
                      )}
                    </span>
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const rowData = row.getVisibleCells();

            return (
              <tr
                key={row.id}
                className={twMerge(
                  'bg-white outline outline-1 outline-gray-200 rounded-lg hover:bg-gray-200 transition-all',
                  onTableRowClick && 'cursor-pointer',
                )}
                onClick={() => onTableRowClick?.(row)}
              >
                {rowData.map((cell, index) => (
                  <td
                    key={cell.id}
                    className={twMerge(
                      'h-[72px] py-0 px-4',
                      index === 0 && 'rounded-l-lg',
                      index === rowData.length - 1 && 'rounded-r-lg',
                    )}
                  >
                    <span className="text-gray-600 text-xs font-normal">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </span>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!isLoading && !table.getRowModel().rows.length && (
        <section className="table-error-wrapper">{noDataComponent}</section>
      )}
      {isLoading && <TableLoader />}
    </article>
  );
}

export default CustomTable;
