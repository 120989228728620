import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  RawAxiosRequestHeaders,
} from 'axios';
import Config from '../../Config';
import {
  onRequestFulfilled,
  onRequestRejected,
} from '../../../utilities/interceptors/Request';
import {
  onResponseFulfilled,
  onResponseRejected,
} from '../../../utilities/interceptors/Response';
import { IUser } from '../../types/response/user';
import { getItemFromLocalStorage } from '../../../utilities/common/Storage';
import { getTimeZone } from '../../../utilities/common/Date';
import { APP_CONTEXT, APP_NAME, StorageItems } from '../../constant/App';
import { getCurrentLocale } from '../../../i18n';

class NetworkClient {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: Config.base.admin,
      timeout: 30000,
      withCredentials: false,
    });
    this.attachCommonHeaders();
    this.attachInterceptors();
  }

  attachCommonHeaders = () => {
    // TODO ? can be this achieved via withAuth param which distinguish APIs which requires auth?

    this.client.defaults.headers.common.timezone = getTimeZone();
    this.client.defaults.headers.common.context = APP_CONTEXT;
    this.client.defaults.headers.common.language = getCurrentLocale();
    this.client.defaults.headers.common.source = APP_NAME;
    this.client.defaults.headers.common['Content-Type'] =
      'application/json; charset=utf-8';
  };

  attachInterceptors = () => {
    this.client.interceptors.request.use(onRequestFulfilled, onRequestRejected);
    this.client.interceptors.response.use(
      onResponseFulfilled,
      onResponseRejected,
    );
  };

  attachAuthToken = () => {
    // TODO to enhance performance - create custom event for listening local storage changes and put user object in memory cache rather than storage cache.
    const user: IUser = getItemFromLocalStorage(
      StorageItems.USER_INFO,
      'object',
    ) as IUser;
    if (user && user.token) {
      this.client.defaults.headers.common.Authorization = user.token;
    }
  };

  doGet = (
    url: string,
    headers?: RawAxiosRequestHeaders,
    params?: {},
    abortSignal?: AbortSignal,
  ) => {
    this.attachAuthToken();
    const axiosConfig = {
      headers: { ...headers, language: getCurrentLocale() },
      params,
      abortSignal,
    };
    return this.client
      .get(url, axiosConfig)
      .then((response) => response.data)
      .catch((error) => error);
  };

  // TODO make params as an object instead of func args
  doPost = (
    url: string,
    body: Record<string, any> | FormData | string,
    headers?: RawAxiosRequestHeaders,
    params?: {},
    progressCallback?: (progress: number) => void,
  ) => {
    this.attachAuthToken();
    const axiosConfig: AxiosRequestConfig = {
      params,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / (progressEvent?.total || 1),
        );
        progressCallback?.(percentCompleted);
      },
      headers: { ...headers, language: getCurrentLocale() },
    };

    return this.client
      .post(url, body, axiosConfig)
      .then((response) => response.data)
      .catch((error) => error.data);
  };

  doPut = (
    url: string,
    body: Record<string, any> | FormData | string,
    headers?: AxiosRequestHeaders,
    params?: {},
    progressCallback?: (progress: number) => void,
  ) => {
    this.attachAuthToken();
    const axiosConfig: AxiosRequestConfig = {
      params,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / (progressEvent?.total || 1),
        );
        progressCallback?.(percentCompleted);
      },
      headers: { ...headers, language: getCurrentLocale() },
    };

    return this.client
      .put(url, body, axiosConfig)
      .then((response) => response.data)
      .catch((error) => error.data);
  };

  doPatch = (
    url: string,
    body: Record<string, any> | FormData | string,
    headers?: AxiosRequestHeaders,
    params?: {},
    progressCallback?: (progress: number) => void,
  ) => {
    this.attachAuthToken();
    const axiosConfig: AxiosRequestConfig = {
      params,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / (progressEvent?.total || 1),
        );
        progressCallback?.(percentCompleted);
      },
      headers: { ...headers, language: getCurrentLocale() },
    };

    return this.client
      .patch(url, body, axiosConfig)
      .then((response) => response.data)
      .catch((error) => error.data);
  };

  doDelete = (
    url: string,
    body: Record<string, any> | FormData | string,
    headers?: AxiosRequestHeaders,
    params?: {},
    progressCallback?: (progress: number) => void,
  ) => {
    this.attachAuthToken();
    const axiosConfig: AxiosRequestConfig = {
      params,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / (progressEvent?.total || 1),
        );
        progressCallback?.(percentCompleted);
      },
      headers: { ...headers, language: getCurrentLocale() },
      data: body,
    };

    return this.client
      .delete(url, axiosConfig)
      .then((response) => response.data)
      .catch((error) => error.data);
  };
}

export default NetworkClient;
