import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IClientSummary } from '../../../../../shared/types/response/provider';
import CheckBox from '../../../../../view/components/CheckBox';

interface AssignClientListItemProps {
  clientDetails: IClientSummary;
  onClientCheckBoxChange: (client: IClientSummary, isChecked: boolean) => void;
  isChecked: boolean;
  index: number;
  isCheckBoxDisable: boolean;
}

const profilePlaceholderColors = [
  '#4C8387',
  '#955D29',
  '#DB6421',
  '#252525',
  '#A00000',
];

function AssignClientListItem({
  clientDetails,
  onClientCheckBoxChange,
  index,
  isChecked,
  isCheckBoxDisable,
}: AssignClientListItemProps) {
  const { t } = useTranslation();
  const { user, language } = clientDetails;
  const { friendlyName, companyName } = user;
  const profilePlaceholderColor =
    profilePlaceholderColors[index % profilePlaceholderColors.length];
  return (
    <div className="flex gap-3 w-full px-2 py-2 text-sm items-center border-b border-gray-300">
      <div
        className="w-8 h-8 rounded-full font-medium text-white flex justify-center items-center"
        style={{ backgroundColor: profilePlaceholderColor }}
      >
        {friendlyName?.charAt(0)}
      </div>
      <div className="grow">
        <div className="font-semibold text-md">{friendlyName}</div>
        <div>{`${companyName} ${language ? ` | ${t(language)}` : ''}`}</div>
      </div>
      <CheckBox
        isChecked={isChecked}
        onChange={(checkStatus) =>
          onClientCheckBoxChange(clientDetails, checkStatus)
        }
        isDisabled={isCheckBoxDisable}
      />
    </div>
  );
}

export default AssignClientListItem;
