export const clientTableHeadings = [
  {
    label: 'name',
    className: 'name',
  },
  {
    label: 'organisation',
    className: 'organisation',
  },
  {
    label: 'lastSessionOn',
    className: 'client-tags',
  },
  {
    label: 'type',
    className: 'status',
  },
  {
    label: 'tags',
    className: 'client-tags',
  },
  {
    label: 'status',
    className: 'status',
  },
];

export const noOrganisation = {
  label: '--No organisation--',
  key: 'No organisation',
};

export const unMatchedStatusCode = '4';

export const statusOptions = [
  {
    label: 'active',
    key: '1',
  },
  {
    label: 'matched',
    key: '2',
  },
  {
    label: 'booked',
    key: '3',
  },
  {
    label: 'Unmatched',
    key: unMatchedStatusCode,
  },
];

export const statusOptionsV2 = [
  {
    label: 'ACTIVE',
    value: '1',
  },
  {
    label: 'MATCHED',
    value: '2',
  },
  {
    label: 'BOOKED',
    value: '3',
  },
  {
    label: 'UNMATCHED',
    value: unMatchedStatusCode,
  },
];

export const sortFieldNameFromColumnId = {
  user_friendlyName: 'name',
  createdAt: 'createdAt',
};
