import { TriageLevel } from '../../../../shared/constant/Helpline';

export interface HelplineRecord {
  id: string;
  startTime: Date;
  endTime?: Date;
  providerId: number;
  risk: TriageLevel;
}

export enum IrisSocketEventTypes {
  NEW_MESSAGE = 'new_message',
  DELETED_MESSAGE = 'message_deleted',
  NEW_CLIENT = 'new_client',
  CLIENT_UPGRADE = 'client_upgrade',
}

export enum Listeners {
  CONNECT = 'connect',
  ERROR = 'connect_error',
  NEW_REWARDS = 'new_rewards',
}

export enum HelplineRecordSessionType {
  RESPONDER = 0,
  CARE_NAV = 1,
}

export interface HelplineRecordDetails extends HelplineRecord {
  clientId: string;
  meetingId: string | null;
  issue: string | null;
  risk: TriageLevel;
  sessionType: HelplineRecordSessionType;
  meeting: {
    id: string;
    type: string;
    scheduledDate: string;
    scheduledStartTime: string;
    scheduledEndTime: string;
    duration: string;
    providerName: string;
  } | null;
  client: {
    name: string;
    organisation: string;
    email: string;
    location: string;
    phone: string;
    organisationName: {
      id: number;
      name: string;
      eapNote: [];
      domain: string[];
      credits: Record<string, any>[];
    };
  };
  caseNotes: {
    id: string;
    note: string;
    createdAt: string;
    updatedAt: string;
    addedBy: number;
    commentBy: string;
  }[];
}
