import * as React from 'react';
import './AssignmentCard.scss';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { getImageUri } from '../../../utilities/common/Display';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import Tags from '../Tags';
import { listVariants } from '../../../configs/animations/variants';
import { isCustomAssignment } from '../../../shared/types/guards';
import {
  Assignment,
  CustomAssignment,
  AssignmentTypes,
} from '../../../shared/types/response/assignment';
import { ColorSet } from '../../../shared/types/App';

interface AssignmentCardProps<T> {
  index: number;
  assignmentData: T;
  onAssignClick: (assignment: T) => void;
}

const tagColors: Record<AssignmentTypes | 'default', ColorSet> = {
  course: {
    fontColor: '#2A68B6',
    backgroundColor: '#D7E8FC',
  },
  questionnaire: {
    fontColor: '#663B22',
    backgroundColor: '#FEDDAD',
  },
  rescue: {
    fontColor: '#008044',
    backgroundColor: '#CCEEDE',
  },
  default: {
    fontColor: '#2A68B6',
    backgroundColor: '#D7E8FC',
  },
};

function AssignmentCard<T extends Assignment | CustomAssignment>({
  index,
  assignmentData,
  onAssignClick,
}: AssignmentCardProps<T>) {
  const { t } = useTranslation();
  if (!isCustomAssignment(assignmentData)) {
    const { title, imageUrl, description, assignmentCategory, type } =
      assignmentData as Assignment;
    const tagColorSet = tagColors[type] || tagColors.default;
    const { fontColor, backgroundColor } = tagColorSet;

    return (
      <motion.div
        className="assignment-card"
        variants={listVariants}
        initial="hidden"
        animate="visible"
        custom={index}
      >
        <div className="image-container">
          <img src={getImageUri(imageUrl)} alt={title} />
        </div>
        <div className="assignment-tags">
          <Tags
            tags={assignmentCategory}
            textColor={fontColor}
            backgroundColor={backgroundColor}
          />
        </div>
        <Typography size={16} weight="500">
          {title}
        </Typography>
        <Typography color="secondary" size={12} weight="400">
          {description}
        </Typography>
        <div className="assignment-action">
          <Button
            width="100%"
            variant="blue"
            fontSize={14}
            fontWeight="400"
            label={t('assign')}
            onClick={() => {
              onAssignClick(assignmentData);
            }}
            height="2.5rem"
          />
        </div>
      </motion.div>
    );
  }

  const { title, mainImage } = assignmentData as CustomAssignment;
  return (
    <motion.div
      className="assignment-card custom"
      variants={listVariants}
      initial="hidden"
      animate="visible"
      custom={index}
    >
      <div className="image-container">
        <img src={getImageUri(mainImage)} alt={title} />
      </div>
      <Typography size={16} weight="500">
        {title}
      </Typography>
      <div className="assignment-action">
        <Button
          width="100%"
          variant="blue"
          fontSize={14}
          fontWeight="400"
          label={t('assign')}
          onClick={() => {
            onAssignClick(assignmentData);
          }}
          height="2.5rem"
        />
      </div>
    </motion.div>
  );
}

export default AssignmentCard;
