export enum EventActions {
  click = 'click',
  popup = 'popup',
  load = 'load',
}

export enum EventNames {
  providerDashboard = 'provider_dashboard',
  providerWebAssignment = 'provider_web_assignment',
  providerWebClients = 'provider_web_clients',
  providerWebCustomAssignment = 'provider_web_CustomAssignment',
  providerWebDailyOverride = 'provider_dashboard_daily_override',
  providerWebChat = 'provider_web_chat',
  providerBookSession = 'provider_book_session', // vl be used in new instrumentation
  providerCancelSession = 'provider_cancel_session', // vl be used in new instrumentation
  providerWebInsights = 'provider_web_insights',
  settings = 'settings', // new instrumentation begins here
  automated_messages = 'automated_messages',
  client_personal_insights = 'client_personal_insights',
  view_session_case_notes = 'view_session_case_notes',
  clientView = 'client_view',
  messages = 'messages',
  viewFullSessionCaseNotes = 'view_full_session_case_notes',
  viewIntakeForm = 'view_intake_form',
  sosHelpLine = 'sos_helpline',
  workingHours = 'working_hours',
  upcomingSessions = 'upcoming_sessions',
  inPersonSession = 'inperson_session',
  inSession = 'in-session',
  timezone = 'timezone',
  providerLogin = 'provider_login',
  onsiteSession = 'onsite_session',
  creditInfo = 'credit_info',
}

export enum EventCategories {
  upcomingSessions = 'upcoming_sessions',
  schedule = 'schedule',
  clients = 'clients',
  billingInfo = 'billing_info',
  viewAssignments = 'view_assignments',
  cancelledAssignment = 'cancelled_assignment',
  selectLanguage = 'select_language',
  selectCategory = 'select_category',
  selectTopics = 'select_topics',
  meetingEnter = 'meeting_enter',
  assignmentAssigned = 'assignment_assigned',
  viewClients = 'view_clients',
  clientView = 'client_view',
  sendReminder = 'send_reminder',
  customAssignmentButton = 'custom_assignment_button',
  preferUsingLink = 'prefer_using_link',
  continueToSelectClients = 'continue_to_select_clients',
  selectClients = 'select_clients',
  sendAssignment = 'send_assignment_button',
  uploadFile = 'upload_a_file',
  noExternalResources = 'ES_no_external_resource',
  // messageSent = 'message_sent_web', // todo remove with old chat components
  saveButton = 'save_button',
  dailyOverrideTab = 'daily_override_tab',
  newSlotButton = 'new_slot_button',
  audioSentWeb = 'audio_sent_web', // todo remove in new chat widget code
  bookSession = 'book_session',
  cancelSession = 'provider_cancel_session',
  confirmSession = 'provider_confirm_session',
  cancelConfirm = 'provider_confirm_cancel',
  providerWebInsights = 'provider_web_insights',
  attachmentSentWeb = 'attachment_sent_web',
  settings = 'settings', // new instrumentation begins here
  trigger = 'trigger',
  message_template = 'message_template',
  translations = 'translations',
  client_personal_insights = 'client_personal_insights',
  assign_personal_insights = 'assign_personal_insights',
  reassign_quiz = 'reassign_quiz',
  riskAssessment = 'risk_assessment',
  dimension = 'dimension',
  recommendedAssignments = 'recommended_assignments',
  allDimensions = 'all_dimensions',
  view_session_case_notes = 'view_session_case_notes',
  close = 'close',
  save = 'save',
  viewPersonalInsightResults = 'view_personal_insights_results',
  viewMessages = 'view_messages',
  sosHelpLine = 'sos_helpline',
  showMessages = 'show_messages',
  minimize = 'minimize',
  messageSent = 'message_sent',
  sendRecommendedAssignment = 'send_assignment',
  confirmCancellation = 'confirm_cancellation',
  cancellationReason = 'cancellation_reason',
  providerConfirmCancel = 'provider_confirm_cancel',
  providerBookSession = 'provider_book_session',
  providerDropNote = 'provider_drop_note',
  viewFullSessionCaseNotes = 'view_full_session_case_notes',
  viewIntakeForm = 'view_intake_form',
  questionId = 'questionId',
  confirm = 'confirm',
  workingHours = 'working_hours',
  day = 'day',
  addTimeSlot = 'add_time_slot',
  removeTimeSlot = 'remove_time_slot',
  onLeave = 'on_leave',
  confirmLeave = 'confirm_leave',
  markAvailable = 'mark_available',
  confirmMarkAvailable = 'confirm_mark_available',
  removeSlotBtn = 'remove_slot_button',
  checkScheduledSessions = 'check_scheduled_sessions',
  endSessionConfirm = 'end_session_confirm',
  viewUserChat = 'view_user_chat',
  clickAttachment = 'click_attachment',
  hardwareSetup = 'hardware_setup',
  joinCall = 'join_call',
  backButton = 'back_button',
  meeting = 'meeting',
  meetingFinish = 'meeting_finish',
  organisation = 'organisation',
  status = 'status',
  caseIdCopied = 'case_id_copied',
  changeTimeZone = 'change_timezone',
  doNotChange = 'do_not_change',
  timezone = 'timezone',
  page = 'page',
  providerLogin = 'provider_login',
  onsiteCancelReminder = 'onsite_cancel_reminder',
  beginSession = 'begin_session',
  completeSession = 'complete_session',
  completeSessionConfirm = 'complete_session_confirm',
  markNoShow = 'mark_no_show',
  markNoShowConfirm = 'mark_no_show_confirm',
  retry = 'retry',
  providerNote = 'provider_note',
  helplineLog = 'helpline_log',
  riskLevel = 'risk_level',
  emergencyContacts = 'emergency_contacts',
  creditInfo = 'credit_info',
}

export enum EventSource {
  scheduleSession = 'scheduled_sessions',
  providerWebClients = 'provider_web_clients',
  clientView = 'client_view',
  meeting = 'meeting',
  chatButton = 'chat_button',
  caseNoteAssessment = 'assessment',
  caseNoteRecommendation = 'recommendation',
  quickTab = 'quick_tab',
  session = 'session',
  onLeave = 'on_leave',
  upcomingSessions = 'upcoming_sessions',
  caseNotes = 'case_notes',
}

export const EventProperties = {
  eventAction: 'eventAction',
  eventCategory: 'eventCategory',
  eventLabel: 'eventLabel',
  featureVersion: 'featureVersion',
  eventValue: 'eventValue',
  eventSource: 'eventSource',
};

export const assignmentsFilterAnalyticsValuesMap = {
  languages: EventCategories.selectLanguage,
  categories: EventCategories.selectCategory,
  topics: EventCategories.selectTopics,
};
