import * as React from 'react';
import './NoResultsFound.scss';
import noResultsImage from '../../../assets/no-results.png';
import Typography from '../../components/Typography';
import Button from '../../components/Button';

interface NoResultsFoundProps {
  captionCopy: string;
  description?: string;
  ctaLabel?: string;
  ctaCallback?: () => void;
  width?: string;
  ctaWidth?: string;
  ctaDisabled?: boolean;
  imageSrc?: string;
  showImage?: boolean;
}

function NoResultsFound({
  captionCopy,
  description,
  ctaLabel,
  ctaCallback,
  width = '184px',
  ctaWidth = '90%',
  ctaDisabled = false,
  imageSrc = noResultsImage,
  showImage = true,
}: NoResultsFoundProps) {
  return (
    <div className="no-results" style={{ width }}>
      {showImage && (
        <div className="image-container">
          <img src={imageSrc} alt="no results found" />
        </div>
      )}
      <Typography size={16} weight="600">
        {captionCopy}
      </Typography>
      <Typography color="secondary" size={14} weight="400">
        {description}
      </Typography>
      {ctaLabel && (
        <div className="no-results-action">
          <Button
            label={ctaLabel}
            variant="primary"
            height="40px"
            width={ctaWidth}
            onClick={() => ctaCallback?.()}
            disabled={ctaDisabled}
          />
        </div>
      )}
    </div>
  );
}

export default NoResultsFound;
