import {
  Menu as HeadlessMenu,
  MenuButton,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { Fragment, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface MenuProps {
  render: JSX.Element;
  /** className of the button that will open the menu. */
  className?: string;
}

export function Menu({
  render,
  className,
  children,
}: PropsWithChildren<MenuProps>) {
  return (
    <HeadlessMenu as="div" className="relative">
      <MenuButton
        className={twMerge('transition-all hover:brightness-90', className)}
      >
        {render}
      </MenuButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="bg-gray-25 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-lg border border-gray-200 shadow-md ring-1 ring-black/5 focus:outline-none">
          {children}
        </MenuItems>
      </Transition>
    </HeadlessMenu>
  );
}

interface MenuItemProps {
  className?: string;
  icon?: JSX.Element;
  label: string;
  onClick?: () => void;
}

export function MenuItem({ className, icon, label, onClick }: MenuItemProps) {
  return (
    <HeadlessMenu.Item>
      {({ active }) => (
        <button
          type="button"
          onClick={onClick}
          className={twMerge(
            'group flex gap-2 transition-colors font-medium w-full items-center text-left first:rounded-t-lg last:rounded-b-lg px-3 py-3 text-sm border-none',
            active ? 'bg-gray-100' : 'bg-gray-25',
            className,
          )}
        >
          {icon}
          <div className="flex-grow">{label}</div>
        </button>
      )}
    </HeadlessMenu.Item>
  );
}
