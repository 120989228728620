import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './shared/redux/store/configureStore';
import { initializeMonitoring } from './utilities/monitoring';
import Analytics from './shared/service/Analytics';
import { initializeLocalization, setAppLanguage } from './i18n';
import { MSWWorker } from './mocks/browser';
import { FetchProvider } from './utilities/hooks/useFetch';

initializeLocalization();
setAppLanguage();
initializeMonitoring();
Analytics.initialize();

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.debug = () => {};
} else {
  MSWWorker.start();
}
const store = configureStore();
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <FetchProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </FetchProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
