import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const APP_DSN =
  'https://df9bb4b8a58440148f4d8051bc68b285@o318413.ingest.sentry.io/6368932';

export const initializeMonitoring = () => {
    Sentry.init({
      dsn: APP_DSN,
      integrations: [
        Sentry.replayIntegration({
          maskAllText: true,
          maskAllInputs: true,
        }),
        Sentry.feedbackIntegration({
          // Additional SDK configuration goes in here, for example:
          colorScheme: 'system',
          isEmailRequired: true,
          isNameRequired: true,
        }),
        Sentry.replayCanvasIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      debug: false,
      environment:process.env.REACT_APP_ENV,
    });
};

export const setUserId = (id: string) => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.setUser({ id });
  }
};
