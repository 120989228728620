import { t } from 'i18next';
import { VideoRoomSDKs } from '../../../shared/constant/App';
import { MeetingSDKType } from '../../../shared/types/App';
import { ProviderRole } from '../../../shared/constant/Common';

export const getVideoTagStyle = (
  providerRole: string,
  sessionType: MeetingSDKType,
) => {
  if (sessionType === VideoRoomSDKs.f2f) {
    if (providerRole === ProviderRole.ONSITE) {
      return { name: t('ONSITE'), backgroundColor: '#f9ef9680' };
    }

    return { name: t('INPERSON'), backgroundColor: '#d1e6fa' };
  }
  return { name: t('VIRTUAL'), backgroundColor: '#dbbfe5' };
};
