import * as React from 'react';
import { QueryClientProvider } from 'react-query';
import fetchClient from './fetchClient';

type FetchProviderProps = React.PropsWithChildren<{}>;

function FetchProvider({ children }: FetchProviderProps) {
  return (
    <QueryClientProvider client={fetchClient}>{children}</QueryClientProvider>
  );
}

export { FetchProvider };
