import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../../../../i18n/utilities';
import { getImageUri } from '../../../../../utilities/common/Display';
import {
  ClientAssignmentCategoryMapping,
  ClientAssignmentStatusEnum,
  ClientAssignmentStatusMappings,
} from '../../../../shared/constant/Clients';
import { variants } from '../../../../shared/constant/Colors';
import { getCustomAssignmentImgIcon } from '../../../../utilities/common/Clients';
import { Button } from '../../../components/Button';
import { Tag } from '../../../components/Tag';
import { IClientAssignment } from '../types/client';
import { ReactComponent as NoImageIcon } from '../../../../assets/NoImage.svg';
import { IUser } from '../../../../../shared/types/response/user';

export function AssignmentCard({
  assignment,
  isClientUnmatched,
  sendReminder,
  user,
  reminderSentAssignments,
}: {
  assignment: IClientAssignment;
  isClientUnmatched: boolean;
  sendReminder: Function;
  user: IUser;
  reminderSentAssignments: string[];
}) {
  const { t } = useTranslation();
  const {
    _id: assignmentId,
    status: assignmentStatus = 'assigned',
    assignedBy,
    title,
    description,
    translationKeys,
    mainImage,
    referenceType,
    assignmentCategory,
    filename,
  } = assignment;

  const isCustomAssignment = referenceType === 'custom';
  const isAssignmentCompleted =
    assignmentStatus === ClientAssignmentStatusEnum.COMPLETED;

  const CustomAssignmentIcon = getCustomAssignmentImgIcon(filename ?? '');

  const { label, variant } =
    ClientAssignmentStatusMappings?.[assignmentStatus] ?? {};

  const renderAssignmentImage = () => {
    if (isCustomAssignment && CustomAssignmentIcon) {
      return (
        <div className="h-[67px] w-[73px]">
          <CustomAssignmentIcon />
        </div>
      );
    }
    if (mainImage) {
      return (
        <div className="h-[67px] w-[73px]">
          <img
            src={getImageUri(mainImage)}
            alt="assignment"
            className="rounded-lg h-[67px] w-[73px]"
          />
        </div>
      );
    }
    return (
      <div className="bg-gray-50 rounded-lg h-[67px] w-[73px] flex justify-center items-center">
        <NoImageIcon />
      </div>
    );
  };

  return (
    <section className="col-span-6 flex flex-col justify-between bg-white  box-border shadow border border-solid border-gray-200 rounded-lg overflow-hidden">
      <section className="grid grid-cols-12 p-4 gap-2">
        <section className="col-span-12 text-xs flex gap-2">
          <div>{renderAssignmentImage()}</div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 line-clamp-1">
              {!isCustomAssignment && assignmentCategory ? (
                <Tag variant="gray" className="text-xs font-medium">
                  {ClientAssignmentCategoryMapping?.[assignmentCategory] ??
                    assignmentCategory}
                </Tag>
              ) : null}
              {isClientUnmatched ? null : (
                <Tag
                  variant={variant as keyof typeof variants}
                  className="text-xs font-medium"
                >
                  {t(label)}
                </Tag>
              )}
            </div>
            <div className="text-gray-900 font-semibold line-clamp-1">
              {!isCustomAssignment
                ? getTranslation(title, translationKeys)
                : title}
            </div>
          </div>
        </section>
        <section className="col-span-12 text-xs text-gray-500 line-clamp-2 text-justify whitespace-pre-line min-h-[2.25rem]">
          {getTranslation(description, translationKeys)}
        </section>
        <section className="col-span-12">
          {!isClientUnmatched ? (
            <Button
              className="w-full"
              variant="secondary"
              disabled={
                reminderSentAssignments.includes(assignmentId) ||
                isAssignmentCompleted
              }
              onClick={() => sendReminder(assignmentId)}
            >
              {t(`${isAssignmentCompleted ? 'completed' : 'remindCTA'}`)}
            </Button>
          ) : null}
        </section>
      </section>
      <section className="w-full px-4 py-2 text-xs bg-gray-50 inline-block border-t border-gray-200">
        {assignedBy === +(user?.id ?? 0)
          ? t('EXTERNAL_RESOURCE_TAG', { provider_name: user.name })
          : t('INTERNAL_RESOURCE_TAG')}
      </section>
    </section>
  );
}
