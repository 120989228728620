import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import { BaseCardContainer } from './BaseCard.styled';
import { BaseCardProps } from './BaseCard.d';
import { getCapitalizedCopy } from '../../../utilities/common/locales';

function BaseCard({
  cardValue,
  cardDescription,
  width = '190px',
  height = '117px',
  fontSize,
  fontWeight,
  color,
  backgroundColor = '#D9D9D9',
  textAlign,
  cardValueFontSize,
  padding = '10px',
  showCapitalizedDescription = false,
  useTranslationForDescription = false,
}: BaseCardProps) {
  const { t } = useTranslation();
  const capitalizedDescription = showCapitalizedDescription
    ? getCapitalizedCopy(cardDescription)
    : cardDescription;
  return (
    <BaseCardContainer
      width={width}
      height={height}
      background={backgroundColor}
      padding={padding}
    >
      <Typography
        size={cardValueFontSize}
        weight={fontWeight}
        color={color}
        textAlign={textAlign}
      >
        {cardValue}
      </Typography>
      <Typography
        size={fontSize}
        color={color}
        textAlign={textAlign}
        weight="400"
      >
        {useTranslationForDescription
          ? t(`${capitalizedDescription}`)
          : capitalizedDescription}
      </Typography>
    </BaseCardContainer>
  );
}

export default BaseCard;
