import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { PageAnimationDuration } from '../../../../../configs/animations/constants';
import {
  ClientInsightsReport,
  InsightsReportItem,
} from '../../../../../shared/types/response/assignment';
import { useFetch } from '../../../../../utilities/hooks/useFetch';
import { getInsightsReport } from '../../../../../shared/redux/actions/assignment';
import Typography from '../../../../../view/components/Typography';

import PHQWidget from '../../../components/PHQ';
import GoalsCard from '../../../components/GoalsCard';
import RecommendedAssignmentCard from '../../../../../view/components/RecommendedAssignmentCard';
import Carousel from '../../../../../view/components/Carousel';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../../../shared/constant/Analytics';
import {
  getImageUri,
  piDisplayDateFormat,
} from '../../../../../utilities/common/Display';
import { GoalsTraitMapping } from '../../../../../shared/constant/Goals';
import { IGoalsCard } from '../../../../../view/components/GoalsCard/types';
import NoInsightResults from '../../../../../view/components/NoInsightResults';
import usePersonalInsights from '../../../../../utilities/hooks/usePersonalInsights';
import GraphDataContext from '../../../../../shared/context/GraphDataContext';
import useTracking from '../../../../../utilities/hooks/useTracking';
import Pagination from '../../../components/Pagination';
import TraitList from '../../../app-components/TraitList';
import useClientDetails from '../../../../../utilities/hooks/useClientDetails/useClientDetails';
import ClientOverviewCardLoader from '../ClientOverviewCardLoader';
import { Button } from '../../../components/Button';
import { unMatchedStatusCode } from '../../Clients/constants';

function PersonalInsights({
  clientId,
  serviceType,
}: {
  clientId: string;
  serviceType: string;
}) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { clientProfiledata, clientProfileLoading } = useClientDetails({
    clientId,
    serviceType,
  });
  const {
    getTraits,
    traitData,
    sendRecommendedAssignment,
    sendReminder,
    disableEmptyStateAction,
  } = usePersonalInsights(clientId.toString());

  const clientGoals: { areaExpertise: string; id: number }[] =
    clientProfiledata?.user?.concernArea ?? [];

  const {
    data: insightsReportData,
  }: { data: ClientInsightsReport | undefined; refetch: Function } = useFetch(
    ['client-reports', clientId || ''],
    () => dispatch(getInsightsReport(clientId?.toString() || '')),
  );

  const { report, assignment } = insightsReportData || {};
  const { attemptedOn, attemptedAt } = report || {};

  const quizAssignedAndNotTaken =
    assignment?.userAssignmentId && report === null;
  const quizNeverGotAssigned =
    assignment?.questionnaireAssignmentId &&
    report === null &&
    !quizAssignedAndNotTaken;

  const quizCompletedAtLeastOnce =
    assignment?.questionnaireAssignmentId && report;

  const onAssignQuiz = () => {
    navigate('/assignments');
  };

  const onEmptyStateCtaClick = () => {
    if (quizAssignedAndNotTaken) {
      sendReminder({
        assignmentId: assignment.userAssignmentId,
        clientId,
      });
    }
    if (quizNeverGotAssigned) {
      onAssignQuiz();
      track(EventNames.client_personal_insights, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.assign_personal_insights,
        eventLabel: 'assign_personal_insights',
        featureVersion: 'v1',
      });
    }
  };

  const handleReassign = () => {
    onAssignQuiz();
    track(EventNames.client_personal_insights, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.reassign_quiz,
      eventLabel: 'reassign_quiz',
      featureVersion: 'v1',
    });
  };

  const handleLearnMore = (traitTitle: string) => {
    const syncDate = moment(attemptedAt).format('YYYY-MM-DD HH:mm:ss.SSS Z');

    getTraits({
      payload: {
        trait: traitTitle,
        etlLastSync: syncDate,
      },
      userId: clientId,
    });
  };
  // goals card & dimension data
  const goalsData = React.useMemo(() => {
    const data: { [key: string]: InsightsReportItem[] } = {};
    clientGoals?.forEach((goal) => {
      data[goal.areaExpertise] =
        report?.current.filter((dimension) =>
          GoalsTraitMapping[goal?.areaExpertise]?.includes(dimension?.traitId),
        ) || [];
    });
    return data;
  }, [clientGoals, report?.current]);

  const recommendedCardData = React.useMemo(() => {
    const tempCardData = _.flatten(Object.values(goalsData)).map(
      (assignmentData) => assignmentData.recommendations?.[0],
    );
    return tempCardData?.filter((data) => !!data);
  }, [goalsData]);

  const carouselContent = React.useMemo(
    () =>
      recommendedCardData?.map((data) => (
        <RecommendedAssignmentCard
          imgSrc={getImageUri(data.image)}
          tags={[data.type]}
          handleAssign={() => {
            sendRecommendedAssignment({
              assignmentType: 'internal',
              assignment: {
                entityId: data?.deepLink.split('/').slice(-1)[0],
                type: data?.contentType,
              },
              clientId,
              note: '',
            });
            track(EventNames.providerWebAssignment, {
              eventAction: EventActions.click,
              eventCategory: EventCategories.sendRecommendedAssignment,
              eventLabel: 'send_assignment',
              eventValue: data.title,
              featureVersion: 'v1',
              eventSource: 'recommended_assignment',
            });
          }}
          title={data.translationKeys[data.title]}
          type={data.contentType}
        />
      )) || [],
    [recommendedCardData],
  );
  const paginationContent = React.useMemo(
    () =>
      report?.current.map((data) => (
        <TraitList
          traitTitle={data?.title}
          traitDescription={data?.description}
          traitCurrentScore={data?.score}
          traitPrevScore={data?.previousScore}
          key={data?.traitId}
          scoreMeaning={data?.scoreMeaning}
          traitId={data?.traitId}
          onClickLearnMore={handleLearnMore}
          recommendedAssignments={data?.recommendations}
        />
      )),
    [goalsData],
  );

  const GraphContextValue = React.useMemo(
    () => ({
      traitData,
      onAssignClick: sendRecommendedAssignment,
      userId: clientId,
    }),
    [traitData, clientId],
  );

  if (clientProfileLoading) {
    return <ClientOverviewCardLoader variant="personal-insights" />;
  }

  return (
    <motion.article
      className="w-full box-border py-7 px-10 min-h-[100vh]"
      initial={{ y: 100, opacity: 0, position: 'absolute' }}
      animate={{ y: 0, opacity: 1, position: 'relative' }}
      exit={{ y: 100, opacity: 0, position: 'absolute' }}
      transition={{
        delay: PageAnimationDuration,
        duration: PageAnimationDuration,
      }}
    >
      <section className="flex justify-between items-center">
        <section>
          <Typography size={24} weight="700">
            {t('PERSONAL_INSIGHTS_QUIZ_RESULTS')}
          </Typography>
          {attemptedOn && (
            <Typography size={16} weight="500" color="secondary">
              {t('LAST_ATTEMPTED', {
                date: moment(attemptedAt).format(piDisplayDateFormat),
              })}
            </Typography>
          )}
        </section>
        <section>
          {quizCompletedAtLeastOnce &&
          clientProfiledata.status !== unMatchedStatusCode ? (
            <Button
              variant="secondary"
              onClick={() => handleReassign()}
              className="rounded-3xl w-48 text-xs"
            >
              {t('REASSIGN_QUIZ')}
            </Button>
          ) : null}
        </section>
      </section>
      <section className="personal-insights-body">
        {!report ? (
          <NoInsightResults
            quizAssignedAndNotTaken={quizAssignedAndNotTaken}
            onEmptyStateCtaClick={onEmptyStateCtaClick}
            disableEmptyStateAction={disableEmptyStateAction}
            friendlyName={clientProfiledata?.user?.friendlyName ?? ''}
            isUnmatched={clientProfiledata.status === unMatchedStatusCode}
          />
        ) : (
          <>
            <section className="flex py-6 flex-col items-center">
              <PHQWidget userId={clientId.toString()} />

              {report && (
                <GraphDataContext.Provider value={GraphContextValue}>
                  <GoalsCard
                    width="100%"
                    goalData={goalsData as IGoalsCard['goalData']}
                    onClickLearnMore={handleLearnMore}
                  />
                </GraphDataContext.Provider>
              )}
            </section>
            {!!recommendedCardData.length && (
              <section className="p-6 rounded-lg border bg-white">
                <section className="pb-5">
                  <Typography size={16} weight="500" color="primary">
                    {t('RECOMMENDED_ASSIGNMENTS')}
                  </Typography>
                </section>
                <Carousel
                  carouselItem={carouselContent}
                  slidesToScroll={3}
                  slidesToShow={3}
                />
              </section>
            )}

            {report && (
              <GraphDataContext.Provider value={GraphContextValue}>
                <Pagination paginationList={paginationContent} />
              </GraphDataContext.Provider>
            )}
          </>
        )}
      </section>
    </motion.article>
  );
}

export default PersonalInsights;
