import * as React from 'react';
import './MessageInputAudioPreview.scss';
import closeIcon from '../../../../assets/close-white.svg';
import AudioMessagePlayer from '../AudioMessagePlayerV2';

interface IMessageInputAudioPreviewProps {
  audioStream: Blob;
  onClose: () => void;
}

function MessageInputAudioPreview({
  audioStream,
  onClose,
}: IMessageInputAudioPreviewProps) {
  return (
    <div className="audio-preview">
      <AudioMessagePlayer
        source="outbound"
        url={URL.createObjectURL(audioStream)}
      />
      <img
        src={closeIcon}
        className="discard-audio"
        alt="stop recording"
        onClick={onClose}
      />
    </div>
  );
}

export default MessageInputAudioPreview;
