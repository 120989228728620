import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Popup from '../../components/Popup';
import Typography from '../../components/Typography';
import './CancelAppointmentsPopup.scss';
import timeIcon from '../../../assets/time.svg';
import closeIcon from '../../../assets/close.svg';
import { listVariants } from '../../../configs/animations/variants';
import { IProviderDailyData } from '../../../shared/types/response/provider';
import boardIcon from '../../../assets/board.png';
import { getCurrentLocale } from '../../../i18n';

interface CancelAppointmentsPopupProps {
  show: boolean; // using show prop to retain meeting cancellation states on unmount without a refetch
  onClose: (hasAllMeetingsCancelled: boolean) => void;
  meetings: IProviderDailyData['meetings'];
  setSelectedMeetingId: (id: string) => void;
}

function CancelAppointmentsPopup({
  show,
  onClose,
  meetings,
  setSelectedMeetingId,
}: CancelAppointmentsPopupProps) {
  const { t } = useTranslation();

  if (!show) {
    return null;
  }

  const handleSelectMeeting = (id: string) => () => {
    setSelectedMeetingId(id);
  };

  const handleClose = () => {
    onClose(!meetings.length);
  };
  return (
    <Popup
      withBackdrop
      showCloseIcon={false}
      useDefaultPadding={false}
      onClose={handleClose}
    >
      <div className="cancel-appointments-container">
        <img
          className="close-popup"
          src={closeIcon}
          onClick={handleClose}
          alt="close"
        />
        <AnimatePresence>
          <div className="cancel-appointments-contents">
            <Typography size={24} weight="500">
              {t('CANCEL_SESSIONS')}
            </Typography>
            <Typography color="secondary" size={16} weight="400">
              {t('CANCEL_SESSIONS_SUBTITLE')}
            </Typography>
            <div className="appointment-listing">
              {!meetings.length && (
                <div className="empty-sessions">
                  <img src={boardIcon} alt="empty" />
                  <Typography
                    size={16}
                    weight="400"
                    withColor="rgba(102, 112, 133, 0.46)"
                  >
                    {t('NO_SESSIONS_BODY')}
                  </Typography>
                </div>
              )}
              <AnimatePresence>
                {meetings.map(
                  (
                    {
                      id,
                      participants,
                      displayTime,
                      displayTimeEnd,
                      scheduledStartTime,
                      scheduledEndTime,
                    },
                    index,
                  ) => {
                    const dispTime: { [key: string]: string } = {
                      ja: `${moment(+scheduledStartTime * 1000).format(
                        'HH:mm',
                      )} - ${moment(+scheduledEndTime * 1000).format('HH:mm')}`,
                      en: `${displayTime} - ${displayTimeEnd}`,
                    };
                    const tenure = dispTime[getCurrentLocale()];
                    return (
                      <motion.div
                        key={`${participants[0].userId}-${displayTime}-${displayTimeEnd}`}
                        className="appointment-item"
                        variants={listVariants}
                        initial="hidden"
                        animate="visible"
                        custom={index}
                      >
                        <div className="left">
                          <Typography size={16} weight="600">
                            {participants[0].friendlyName}
                          </Typography>
                          <div className="time-labels">
                            <img src={timeIcon} alt="" />
                            <Typography
                              color="secondary"
                              size={12}
                              weight="500"
                            >
                              {tenure}
                            </Typography>
                          </div>
                        </div>
                        <Button
                          backgroundColor="transparent"
                          label={t('CANCEL_CTA')}
                          onClick={handleSelectMeeting(id)}
                          variant="caution"
                        />
                      </motion.div>
                    );
                  },
                )}
              </AnimatePresence>
            </div>
          </div>
        </AnimatePresence>
      </div>
    </Popup>
  );
}

export default CancelAppointmentsPopup;
