import * as React from 'react';
import './RadioGroup.scss';

interface RadioGroupProps {
  name: string;
  values: {
    value: string;
    label: string;
    id?: string;
  }[];
  selectedValue: string;
  onChange: (
    value: string,
    obj?: {
      value: string;
      label: string;
      id?: string;
    },
  ) => void;
  customCss?: boolean;
  duration?: boolean;
  labelClass?: string;
  highlightOnSelect?: boolean;
  highlightColor?: React.CSSProperties['color'];
  onBlur?: (selectedValue?: string) => void;
  onFocus?: (selectedValue?: String) => void;
}

function RadioGroup({
  name,
  values,
  onChange,
  selectedValue,
  customCss,
  duration,
  labelClass,
  highlightColor,
  highlightOnSelect,
  onBlur,
  onFocus,
}: RadioGroupProps) {
  const getStyle = (value: string): React.CSSProperties | {} => {
    if (highlightOnSelect && value === selectedValue) {
      return {
        backgroundColor: highlightColor,
        transition: 'background 130ms ease-out',
      };
    }
    return {};
  };
  return (
    <div
      className={`${customCss ? 'custom-radio-groups' : 'radio-groups'} `}
      onBlur={() => onBlur?.(selectedValue)}
      onFocus={() => onFocus?.(selectedValue)}
    >
      {values.map(({ value, label, id }) => (
        <div
          className={`radio-item ${
            !customCss && 'first:border-b border-[#dde4f0] border-solid px-4'
          }`}
          key={value}
          style={getStyle(value)}
        >
          <input
            key={value}
            type="radio"
            value={value}
            name={name}
            id={`${name}-${value}`}
            onChange={() => onChange(value, { value, label, id })}
            checked={value === selectedValue}
            disabled={value !== selectedValue && duration}
          />
          {value !== selectedValue && duration ? (
            <span className="deselected-label">{label}</span>
          ) : (
            <label
              className={selectedValue === value ? labelClass || '' : ''}
              htmlFor={`${name}-${value}`}
            >
              {label}
            </label>
          )}
        </div>
      ))}
    </div>
  );
}

export default RadioGroup;
