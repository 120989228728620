import Config from '../../Config';
import NetworkClient from '../Network';

const client = new NetworkClient();

export const errorMessage = {
  generic: 'something went wrong, please try again!',
};

export const sosRequestLogger = async (body: any) => {
  const response = await client.doPost(Config.api.sos.track, body);
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.error?.message || errorMessage.generic);
};
