import * as React from 'react';
import './ChatFloatingButton.scss';
import { motion } from 'framer-motion';
import messageIcon from '../../../assets/message.svg';
import Typography from '../../components/Typography';
import { getUnreadMessagesCountForFloatingChatButton } from '../../../utilities/common/Display';
import useLayout from '../../../utilities/hooks/useLayout';
import { useChatBroker } from '../../../chat-module/broker/useChatBroker';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../shared/constant/Analytics';

// TODO unread messages counter jumping animation when new message arrives
function ChatFloatingButton() {
  const { updateChatWindowSettings } = useLayout();
  const { track } = useTracking();
  const { unreadCount } = useChatBroker();

  return (
    <motion.div
      className="floating-chat-button"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={() => {
        updateChatWindowSettings({ show: true });
        track(EventNames.messages, {
          eventAction: EventActions.click,
          eventCategory: EventCategories.showMessages,
          eventLabel: 'show_messages',
          featureVersion: 'v1',
          eventSource: EventSource.chatButton,
        });
      }}
    >
      <div className="floating-contents-container">
        <img src={messageIcon} alt="messages" />
        <div className="message-dots">
          <div className="message-dot" />
          <div className="message-dot" />
          <div className="message-dot" />
        </div>
        {!!unreadCount && (
          <motion.div
            className="unread-messages-count"
            animate={{ scale: 1.1 }}
            transition={{ delay: 2, yoyo: Infinity }}
          >
            <Typography weight="600" size={10} color="light">
              {getUnreadMessagesCountForFloatingChatButton(unreadCount)}
            </Typography>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
}

export default ChatFloatingButton;
