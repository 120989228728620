import { isArrayEmpty } from './Array';

type IKeyValuePair = { [key: string]: any };

/**
 * @param obj accepts an object / any
 * @returns true if obj is not undefined or null
 */
export const isObjectDefined = (obj: IKeyValuePair) => Boolean(obj);
/**
 * @param obj accepts an object / any
 * @returns true if and only if obj is a type of object
 */
export const isObject = (obj: IKeyValuePair) =>
  isObjectDefined(obj) && obj.constructor === Object;
/**
 * @param obj accepts an object / any
 * @returns true if and only if it is an object and that has zero property
 */
export const isObjectEmpty = (obj: IKeyValuePair) =>
  isObject(obj) && isArrayEmpty(Object.keys(obj));
