import * as React from 'react';
import './LiveChatV2.scss';
import { UseMutateFunction } from 'react-query';
import MediaPreviewContext from '../../../../shared/context/MediaPreviewContext';
import MediaViewer from '../MediaViewerV2';
import {
  AllConversationListType,
  IMessageSendType,
} from '../../../data-types/ChatBrokerTypes';
import useLayout from '../../../../utilities/hooks/useLayout';
import MessageInputBox from '../MessageInputBoxV2';
import { SelectedUserMessagesType } from '../../../data-types/ClientTypes';
import ChatMessage from '../ChatMessageV2';
import useInfiniteScroll from '../../../utils/hooks/useInfiniteScroll';
import {
  MessageChannelTypes,
  MessageSendType,
} from '../../../constants/CommonConstants';
import NoConversations from '../NoConversation';
import ChatHeader from '../ChatHeader';
import { useChatBroker } from '../../../broker/useChatBroker';
import { scrollToBottom } from '../../../../utilities/common/UserExperience';
import useTracking from '../../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';

export interface LiveChatV2Props {
  userMetaData: AllConversationListType;
  selectedUserMsg: SelectedUserMessagesType;
  isLoading: boolean;
  pageNoRef: React.MutableRefObject<number>;
  getUnReadCount: (selectedConversationId: string) => number;
  updateMessageConsumptionInfo: ({
    userId,
    channelType,
  }: {
    userId: string;
    channelType: MessageChannelTypes;
  }) => void;
  isNextMessageLoaded: React.MutableRefObject<boolean>;
  getSelectedUserMessages: UseMutateFunction<
    SelectedUserMessagesType,
    unknown,
    {
      selectedConversationId: string;
      channelId: string;
      pageNo: number;
      channelType: MessageChannelTypes;
    },
    unknown
  >;
}

function LiveChat({
  userMetaData,
  selectedUserMsg,
  getUnReadCount, // eslint-disable-line
  pageNoRef,
  isNextMessageLoaded,
  getSelectedUserMessages,
  updateMessageConsumptionInfo,
  isLoading, // eslint-disable-line
}: LiveChatV2Props) {
  const { updateChatWindowSettings } = useLayout();
  const { sendMessage } = useChatBroker();
  const messageRefList = React.useRef<HTMLDivElement[]>([]);
  const bottomRef = React.useRef<HTMLDivElement>(null);
  const prevMessageLength = React.useRef(0);
  const { track } = useTracking();
  const textAreaRef = React.useRef(null);

  function onIntersect() {
    if (!selectedUserMsg.messages?.length || !selectedUserMsg.isNext) return;
    pageNoRef.current += 1; // eslint-disable-line
    getSelectedUserMessages({
      selectedConversationId: userMetaData.conversationId,
      channelId: userMetaData.channelId,
      pageNo: pageNoRef.current,
      channelType: userMetaData.channelType,
    });
  }

  React.useLayoutEffect(() => {
    if (isNextMessageLoaded.current) {
      const newMessageLength = selectedUserMsg.messages.length;
      const currentViewIndex = newMessageLength - prevMessageLength.current;
      messageRefList.current[currentViewIndex]?.scrollIntoView(true);
      prevMessageLength.current = newMessageLength;
      isNextMessageLoaded.current = false; // eslint-disable-line
    }
  }, [isNextMessageLoaded.current]);

  const { chatContainerRef, topMessageElementRef } = useInfiniteScroll(
    onIntersect,
    [userMetaData],
    [userMetaData, selectedUserMsg.messages],
  );

  const [selectedMediaFile, setSelectedMediaFile] = React.useState<
    string | null
  >(null);
  const [typedMessage, setTypedMessage] = React.useState<string>('');
  const [selectedAttachment, setSelectedAttachment] =
    React.useState<IMessageSendType | null>(null);

  const onMediaSelect = (media: string | null) => {
    setSelectedMediaFile(media);
  };

  const mediaPreviewContextValue = React.useMemo(
    () => ({ showPreview: onMediaSelect }),
    [onMediaSelect],
  );

  const onSend = () => {
    if (selectedAttachment) {
      sendMessage({
        contentToSend: { ...selectedAttachment, body: typedMessage },
        conversationId: userMetaData.conversationId,
        channelType: userMetaData.channelType,
      });
      setSelectedAttachment(null);
      setTypedMessage('');
      track(EventNames.messages, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.messageSent,
        eventLabel: selectedAttachment.type.toLowerCase(),
        featureVersion: 'v1',
      });
    } else if (typedMessage) {
      const data: IMessageSendType = {
        data: typedMessage,
        type: MessageSendType.TEXT,
      };
      sendMessage({
        contentToSend: data,
        conversationId: userMetaData.conversationId,
        channelType: userMetaData.channelType,
      });
      setTypedMessage('');
      track(EventNames.messages, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.messageSent,
        eventLabel: 'message_sent',
        featureVersion: 'v1',
      });
    }
  };

  React.useLayoutEffect(() => {
    if (selectedUserMsg.messages?.length) {
      const lastMessage = selectedUserMsg.messages[0];
      if (lastMessage && userMetaData?.providerId !== lastMessage.senderId) {
        updateMessageConsumptionInfo({
          userId: userMetaData.conversationId,
          channelType: userMetaData.channelType,
        });
      }
      if (
        pageNoRef.current === 1 ||
        userMetaData?.providerId === lastMessage.senderId
      ) {
        scrollToBottom(chatContainerRef, 'auto');
      }
    }
  }, [selectedUserMsg.messages]);

  const onTypedTextKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    /* press enter -> to send a message
    press enter + shift -> add new line */
    if (
      (e.code === 'Enter' || e.code === 'NumpadEnter') &&
      !e.shiftKey &&
      !e.nativeEvent.isComposing
    ) {
      onSend();
      // @ts-ignore
      // eslint-disable-next-line
      textAreaRef.current.style.height = '40px';
    }
  };
  const selectedUserMsgRev = selectedUserMsg.messages?.slice().reverse();

  const getMessageListContainerHeight = (
    maxHeight: number,
    offset?: number,
  ) => {
    let heightToSub = Number(
      // @ts-ignore
      textAreaRef?.current?.style?.height?.slice(0, -2) || 0,
    );
    if (offset && heightToSub) {
      heightToSub -= offset;
    }
    return `${maxHeight - heightToSub}px`;
  };

  return (
    <MediaPreviewContext.Provider value={mediaPreviewContextValue}>
      {selectedMediaFile && <MediaViewer mediaUrl={selectedMediaFile} />}
      <section className="live-chat-container">
        <ChatHeader
          userMetaData={userMetaData}
          isUserInfoVisible={JSON.stringify(userMetaData) === '{}'}
        />

        {JSON.stringify(userMetaData) === '{}' ? (
          <NoConversations />
        ) : (
          <>
            <div
              className="chat-messages-container"
              ref={chatContainerRef}
              style={{ height: getMessageListContainerHeight(376, 40) }}
            >
              <section ref={topMessageElementRef} />
              {selectedUserMsgRev?.map((msg, index) => (
                <ChatMessage
                  key={msg.id}
                  message={msg}
                  source={
                    msg.senderId === userMetaData.providerId
                      ? 'outbound'
                      : 'inbound'
                  }
                  ref={(el) => {
                    messageRefList.current[index] = el as HTMLDivElement;
                  }}
                  clientId={userMetaData.userId}
                  updateChatWindowSettings={updateChatWindowSettings}
                  hasAppAccess={userMetaData?.hasAppAccess}
                  // groupedMessages={groupedMessages}
                />
              ))}
              <section ref={bottomRef} />
            </div>
            <MessageInputBox
              textAreaRef={textAreaRef}
              onAttachmentChange={setSelectedAttachment}
              file={
                selectedAttachment?.type === MessageSendType.IMAGE ||
                selectedAttachment?.type === MessageSendType.PDF
                  ? selectedAttachment
                  : null
              }
              value={typedMessage}
              onTextValueChange={(value) => setTypedMessage(value)}
              onTypedTextKeyPress={onTypedTextKeyPress}
              onSendMessage={onSend}
              onRecordAudio={setSelectedAttachment}
              recordedVoice={
                selectedAttachment?.type === MessageSendType.AUDIO
                  ? (selectedAttachment.data as File)
                  : null
              }
            />
          </>
        )}
      </section>
    </MediaPreviewContext.Provider>
  );
}

export default LiveChat;
