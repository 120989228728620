import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClientOverviewCardLoader from '../ClientOverviewCardLoader';
import { useSessionsList } from '../hooks/UseSessionsList';
import SessionCard from './SessionCard';
import { Button } from '../../../components/Button';
import PastSessionsModal from './PastSessionsModal';
import NoDataFound from '../../../components/NoDataFound';

export function SessionsList({ clientId }: { clientId: string }) {
  const { t } = useTranslation();
  const { serviceType } = useParams();

  const [showPastSessionsModal, setShowPastSessionsModal] = useState(false);

  const { data: sessionsList, isLoading: isSessionsListLoading } =
    useSessionsList(clientId, serviceType);

  if (!sessionsList || isSessionsListLoading) {
    return (
      <>
        <ClientOverviewCardLoader variant="card" />
        <ClientOverviewCardLoader variant="card" />
        <ClientOverviewCardLoader variant="card" />
        <ClientOverviewCardLoader variant="card" />
      </>
    );
  }

  return (
    <div className="flex flex-col gap-3">
      {sessionsList.pages?.[0].total ? (
        <>
          {sessionsList?.pages?.[0].data.map((session) => (
            <SessionCard data={session} />
          ))}
          {sessionsList?.pages?.[0].pages > 1 ? (
            <Button
              variant="secondary"
              className="w-full"
              onClick={() => setShowPastSessionsModal(true)}
            >
              {t('VIEW_ALL')}
            </Button>
          ) : null}
        </>
      ) : (
        <NoDataFound
          title={t('NO_SESSIONS_BODY')}
          description={t('EMPTY_STATE_SESSIONS')}
        />
      )}
      <PastSessionsModal
        clientId={clientId}
        role={serviceType}
        open={showPastSessionsModal}
        handleClose={() => setShowPastSessionsModal(false)}
      />
    </div>
  );
}
