import { useFetch } from '../../../../../utilities/hooks/useFetch';
import { getClientEmergencyContacts } from '../../../../shared/service/API/client';
import { IClientEmergencyContact } from '../types/client';

export function useClientEmergencyContacts(
  shouldFetch: boolean,
  clientId?: string,
) {
  return useFetch<{ ecp: IClientEmergencyContact[] }>(
    ['client-details-v2-emergency-contacts', clientId],
    () => (clientId ? getClientEmergencyContacts(clientId) : undefined),
    {
      enabled: Boolean(clientId && shouldFetch),
      refetchOnWindowFocus: false,
    },
  );
}
