import mixpanel from 'mixpanel-browser';
import { isProduction } from '../../../utilities/common/Path';

const projectKeys = {
  production: '4be54f24950d5025330b5ecf99e5f772',
  development: 'd93980ffe4384221600c6a92b7ac8b78',
  test: 'd93980ffe4384221600c6a92b7ac8b78',
};

class Analytics {
  static initialize() {
    mixpanel.init(
      isProduction() ? projectKeys.production : projectKeys.development,
      {
        debug: process.env.NODE_ENV !== 'production',
      },
    );
  }

  static send(event: string, properties: Record<string, string>) {
    mixpanel.track(event, properties);
  }
}

export default Analytics;
