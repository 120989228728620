// import { MessageChannelTypes } from './Chat';

export const APP_NAME = 'buddy';
export const APP_CONTEXT = 'provider';
export const APP_DEFAULT_LANGUAGE = 'en';
export enum StorageItems {
  USER_INFO = 'userInfo',
  CHAT_TOKEN = 'chatToken',
  ROOT_STATE = 'rootState',
  APP_LANGUAGE = 'appLanguage',
  IRIS_ACCESS_TOKEN = 'irisAccessToken',
}

export const VideoRoomSDKs = {
  twilio: '0',
  agora: '1',
  ms: '2',
  f2f: '3',
  zoom: '4', //* SDK type other than defined type will fall back to type 4 ie., twilio
  dailyCo: '5',
} as const;

export const VirtualRoomSDKTypes = ['0', '1', '2', '4', '5'];

export const PlatformCode = '5';

export const VideoPlatformCodes: {
  [key: string]: 'twilio' | 'dailyco' | 'zoom';
} = {
  '0': 'twilio',
  '5': 'dailyco',
  '6': 'zoom',
};
