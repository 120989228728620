import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Popup from '../../components/Popup';
import Typography from '../../components/Typography';

import './MarkNoShowModal.scss';

export function MarkNoShowModal({
  open,
  handleClose,
  handleSubmit,
}: {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}) {
  const { t } = useTranslation();

  const [noShowButtonTimer, setNoShowButtonTimer] = React.useState<number>(0);

  React.useEffect(() => {
    if (open) {
      setNoShowButtonTimer(5);
      const timer = setInterval(() => {
        setNoShowButtonTimer((time) => {
          if (time === 0) {
            clearInterval(timer);
            return 0;
          }
          return time - 1;
        });
      }, 1000);
    }
  }, [open]);

  return (
    <Popup
      withBackdrop
      show={open}
      useDefaultPadding={false}
      onClose={() => handleClose()}
    >
      <div className="no-show-confirmation">
        <Typography size={24} weight="600">
          {t('ONSITE_MARK_NO_SHOW_POPUP_TITLE')}
        </Typography>
        <Typography size={16} withColor="#636C73" textAlign="center">
          {t('ONSITE_MARK_NO_SHOW_POPUP_BODY')}
        </Typography>
        <button
          type="button"
          className={`mark-noshow-modal-progress-button ${
            noShowButtonTimer === 0 ? '' : 'progress'
          }`}
          onClick={() => handleSubmit()}
          disabled={noShowButtonTimer !== 0}
        >
          <div className="noshow-text">
            {t('ONSITE_MARK_NO_SHOW_CTA', {
              count: noShowButtonTimer < 1 ? undefined : noShowButtonTimer,
            })}
            {/* {noShowButtonTimer >= 0 ? `${'  '}${noShowButtonTimer}s` : ''} */}
          </div>
        </button>
        <Typography size={14} withColor="#636C73" textAlign="center">
          {t('ONSITE_MARK_NO_SHOW_POPUP_FOOTNOTE')}
        </Typography>
      </div>
    </Popup>
  );
}
