import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import useMediaPreview from '../../../../utilities/hooks/useMediaPreview';
import Loader from '../../../../view/components/Loader';
import './ImageMessageItem.scss';
import { CssSize } from '../../../../view/components/BaseCard/BaseCard.d';
import TextMessageItem from '../TextMessageItemV2';
import useTracking from '../../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';

interface IImageMessageItemProps {
  imageUrl: string;
  disabled?: boolean;
  height?: CssSize;
  width?: CssSize;
  body?: string;
  source?: string;
}

function ImageMessageItem({
  imageUrl,
  disabled,
  height,
  width,
  body,
  source,
}: IImageMessageItemProps) {
  const { track } = useTracking();
  const { t } = useTranslation();

  const [imageFullyLoaded, setImageFullyLoaded] =
    React.useState<boolean>(false);

  const imageRef = React.useRef<HTMLImageElement>(null);
  const { showPreview } = useMediaPreview();

  const onImageLoaded = () => {
    setImageFullyLoaded(true);
  };

  const handleChatImageClick = () => {
    if (!disabled) {
      showPreview(imageUrl);
      track(EventNames.messages, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.clickAttachment,
        eventLabel: 'image',
      });
    }
  };
  const onError = () => {
    setImageFullyLoaded(true);
    toast.error(t('INTERNET_DISCONNECTED_ERROR'));
  };

  const loaderDimension = {
    height: height || '200px',
    width: width || '200px',
  } as unknown as DOMRect;
  return (
    <div className="image-content">
      {!imageFullyLoaded && (
        <Loader
          useFullPage={false}
          withBackdrop={false}
          dimension={loaderDimension}
        />
      )}
      <img
        src={imageUrl}
        alt="message"
        ref={imageRef}
        onLoad={onImageLoaded}
        onError={onError}
        style={
          imageFullyLoaded
            ? { visibility: 'visible', height, width }
            : { visibility: 'hidden' }
        }
        onClick={handleChatImageClick}
      />
      {!!body && source && (
        <section className="attached-msg">
          <TextMessageItem source={source} message={body} />
        </section>
      )}
    </div>
  );
}

export default ImageMessageItem;
