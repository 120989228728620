import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './TraitList.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faArrowDown,
  faCircleChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import Typography from '../../components/Typography';
import { ITraitList } from './types';
import SideDrawer from '../../components/SideDrawer';
import PersonalInsightsResults from '../../components/PersonalInsightsResults';
import { TraitIdMapping } from '../../../shared/constant/Goals';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';

function TraitList({
  traitTitle,
  traitDescription,
  traitCurrentScore,
  traitPrevScore,
  scoreMeaning,
  traitId,
  onClickLearnMore,
  recommendedAssignments,
}: ITraitList) {
  const { t } = useTranslation();
  const { track } = useTracking();

  const [showDimensionsDrawer, setShowDimensionsDrawer] =
    React.useState<boolean>(false);
  const scoreDiff = React.useMemo(
    () => traitCurrentScore - traitPrevScore,
    [traitCurrentScore, traitPrevScore],
  );

  const handleLearnMoreCLick = (dimensionTraitId: string) => {
    setShowDimensionsDrawer(true);
    track(EventNames.client_personal_insights, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.dimension,
      eventLabel: 'dimension_info',
      eventValue: traitTitle,
      featureVersion: 'v1',
      eventSource: 'all_dimensions',
    });
    const traitApiTitle = TraitIdMapping[dimensionTraitId];
    onClickLearnMore(traitApiTitle);
  };

  const hideGoalsDrawer = () => {
    setShowDimensionsDrawer(false);
    track(EventNames.client_personal_insights, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.dimension,
      eventLabel: 'close',
      featureVersion: 'v1',
    });
  };

  return (
    <article className="trait-wrapper">
      <section className="trait-left-content">
        <Typography size={18} weight="600">
          {traitTitle}
        </Typography>
        <section className="trait-left-desc">
          <Typography size={12} weight="400" color="secondary">
            {traitDescription}
          </Typography>
        </section>
      </section>
      <section className="trait-score-content">
        <Typography size={18} weight="600">
          {traitCurrentScore} / 100
        </Typography>
        <Typography
          size={16}
          weight="500"
          withColor="#0a0c0e99"
          textAlign="center"
        >
          <span
            className={`trait-progress-indicator-wrapper ${
              scoreDiff > 0 ? 'increasing' : 'decreasing'
            }`}
          >
            {scoreDiff !== 0 && (
              <FontAwesomeIcon icon={scoreDiff > 0 ? faArrowUp : faArrowDown} />
            )}
            {scoreDiff !== 0 && Math.abs(scoreDiff)}
          </span>
        </Typography>
      </section>
      <motion.button
        className="trait-control-content"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => handleLearnMoreCLick(traitId)}
      >
        <FontAwesomeIcon icon={faCircleChevronRight} className="icons" />
      </motion.button>
      {showDimensionsDrawer && (
        <SideDrawer show={showDimensionsDrawer} hideHandler={hideGoalsDrawer}>
          <section className="hero-dimension-wrapper">
            <PersonalInsightsResults
              dimensionHeading={traitTitle}
              dimensionDesc={traitDescription}
              goalDescription={scoreMeaning}
              score={traitCurrentScore}
              difference={scoreDiff}
              traitId={traitId}
              recommendedAssignments={recommendedAssignments}
              relevancyText={t('SCORE_MEAN_LABEL')}
              source="dimension"
            />
          </section>
        </SideDrawer>
      )}
    </article>
  );
}

export default TraitList;
