import {
  CaseNoteFormType,
  CaseNoteQuestionTypes,
  RiskAssesment,
  ICaseNotesFollowUpMap,
  Recommendations,
} from '../../../shared/constant/CoachingCaseNotes';
import {
  ICaseNoteItem,
  ICaseNoteQuestionResp,
  ICaseNoteResp,
  IOptions,
} from '../../../shared/types/CaseNotes';
import { IFollowUpSessionsForm } from './FollowUpSession.type';

interface OptionswithChildren extends IOptions {
  children?: IOptions[];
}
class FollowUpSessionCaseNotesControls {
  static getInitialData<T>({
    caseNoteAnswerData,
    caseNoteQuestionData,
    riskCategoriesOption,
    recommendationCategoriesOption,
    responseToInterventionOptions,
    primaryPresentingOptions,
    sharedFollowUpCategoriesOptions,
    interventionModalityOptions,
    fromTypes,
  }: {
    caseNoteAnswerData: ICaseNoteResp;
    caseNoteQuestionData: ICaseNoteQuestionResp[];
    riskCategoriesOption?: IOptions[];
    recommendationCategoriesOption?: IOptions[];
    responseToInterventionOptions?: IOptions[];
    primaryPresentingOptions?: IOptions[];
    sharedFollowUpCategoriesOptions?: IOptions[];
    interventionModalityOptions?: IOptions[];
    suicidalAssessmentOptions?: IOptions[];
    fromTypes: string[];
  }) {
    const questionsByType: { [key: string]: { question: string; id: number } } =
      {};
    caseNoteQuestionData?.forEach((question: any) => {
      questionsByType[question.type] = {
        question: question.question,
        id: question.id,
      };
    });
    const answerById = {} as { [key: string]: ICaseNoteItem };
    const ans =
      caseNoteAnswerData.caseNotes || caseNoteAnswerData.previousCaseNotes;
    ans?.forEach((caseItem: any) => {
      answerById[caseItem.questionId] = caseItem;
    });

    const getNoteDataByType = (
      type: CaseNoteQuestionTypes,
      options?: OptionswithChildren[],
    ) => {
      // IOptions | Array<Omit<IOptions, 'apiValue'>> | string
      let savedData: any = answerById[questionsByType[type]?.id]?.note;
      if (!savedData) {
        return '';
      }
      savedData = JSON.parse(savedData);
      savedData = savedData as IOptions | Array<Omit<IOptions, 'apiValue'>>;
      if (!savedData) {
        return '';
      }
      if (options) {
        if (Array.isArray(savedData)) {
          return savedData;
        }
        let selected = {} as IOptions;
        options.forEach((option) => {
          if (option?.children) {
            const [selectedChild] = option.children.filter(
              (child) => child.id === savedData,
            );
            if (selectedChild) {
              selected = selectedChild;
            }
          }
          if (option.id === savedData) {
            selected = option;
          }
        });
        return selected;
      }
      return savedData;
    };

    const getSuicidalAssessment = (type: CaseNoteQuestionTypes) => {
      let savedData: any = answerById[questionsByType[type]?.id]?.note;
      if (!savedData) {
        return '';
      }
      let stringifiedCopy = savedData;
      savedData = JSON.parse(savedData);
      savedData = savedData as IOptions | Array<Omit<IOptions, 'apiValue'>>;
      if (!savedData) {
        return '';
      }
      stringifiedCopy = JSON.parse(stringifiedCopy) as Array<any>;
      let modData: { [key: string]: string } = {};
      stringifiedCopy?.forEach((item: { [key: string]: string }) => {
        const keys = Object.keys(item);
        if (keys.length > 1) {
          modData = item;
        } else if (keys[0]) modData[keys[0]] = item[keys[0]];
      });
      return modData;
    };
    const getAdditionalSupportData = () => {
      let savedData =
        answerById?.[
          questionsByType?.[CaseNoteQuestionTypes.Recommendations]?.id
        ]?.note;
      if (!savedData) {
        return '';
      }
      savedData = JSON.parse(savedData);
      return (
        (Array.isArray(savedData) &&
          savedData[
            savedData?.findIndex(
              (item: { value: number }) =>
                item.value === Recommendations.NeedsAdditionalSupport,
            )
          ]?.additionalSupport) ||
        ''
      );
    };
    let defaultFormData = {};
    if (fromTypes.includes(CaseNoteFormType.V4)) {
      defaultFormData = {
        additionalSessions: getNoteDataByType(
          CaseNoteQuestionTypes.AdditonalSessionRecommended,
        ),
        recommendationText: getAdditionalSupportData(),
        followUpPlanDesc: getNoteDataByType(CaseNoteQuestionTypes.FollowUpPlan),
        primaryIssue: getNoteDataByType(
          CaseNoteQuestionTypes.PrimaryGoal,
          primaryPresentingOptions,
        ),
        recommendations: getNoteDataByType(
          CaseNoteQuestionTypes.Recommendations,
          recommendationCategoriesOption,
        ),
        riskAssessment: getNoteDataByType(
          CaseNoteQuestionTypes.RiskAssesment,
          riskCategoriesOption,
        ),
        secondaryIssue: getNoteDataByType(
          CaseNoteQuestionTypes.SecondaryGoal,
          primaryPresentingOptions,
        ),
        sessionNotes: getNoteDataByType(CaseNoteQuestionTypes.SessionNotes),
        suicidalAssessment: getSuicidalAssessment(
          CaseNoteQuestionTypes.SuicidalAssesment,
        ),
        observation: getNoteDataByType(CaseNoteQuestionTypes.Observation),
        response: getNoteDataByType(
          CaseNoteQuestionTypes.ResponseToInterventionTreatment,
          responseToInterventionOptions,
        ),
        hasSharedFollowup: getNoteDataByType(
          CaseNoteQuestionTypes.SharedFollowUpPlan,
          sharedFollowUpCategoriesOptions,
        ),
        interventionsModality: getNoteDataByType(
          CaseNoteQuestionTypes.InterventionsAndModality,
          interventionModalityOptions,
        ),
        treatmentPlan: getNoteDataByType(
          CaseNoteQuestionTypes.FormulationAndTreatment,
        ),
      };
    }
    if (
      fromTypes.includes(CaseNoteFormType.V7) ||
      fromTypes.includes(CaseNoteFormType.V5)
    ) {
      defaultFormData = {
        primaryIssue: getNoteDataByType(
          CaseNoteQuestionTypes.PrimaryGoal,
          primaryPresentingOptions,
        ),
        riskAssessment: getNoteDataByType(
          CaseNoteQuestionTypes.RiskAssesment,
          riskCategoriesOption,
        ),
        secondaryIssue: getNoteDataByType(
          CaseNoteQuestionTypes.SecondaryGoal,
          primaryPresentingOptions,
        ),
        sessionNotes: getNoteDataByType(CaseNoteQuestionTypes.SessionNotes),
        suicidalAssessment: getSuicidalAssessment(
          CaseNoteQuestionTypes.SuicidalAssesment,
        ),
      };
    }

    return defaultFormData as T;
  }

  static getFormattedOptions(options: IOptions[]) {
    return options.map(({ label, value, id }) => ({
      apiValue: value,
      value: id.toString(),
      key: id,
      label,
      id,
    }));
  }

  static getPartialFormInitialData(): Partial<IFollowUpSessionsForm> {
    return {
      sessionNotes: '',
    };
  }

  static getCaseNotesQuestions<T>(newCaseNoteQuestions?: Array<T>) {
    console.log({ newCaseNoteQuestions });
    const questions: { [key: string]: { question: string; id: number } } = {};
    newCaseNoteQuestions?.forEach((question: any) => {
      questions[question.type] = {
        question: question.question,
        id: question.id,
      };
    });
    return questions || {};
  }

  static saveCaseNoteData = (
    data: Partial<IFollowUpSessionsForm>,
    meetingId: string,
    clientId: string,
    caseNotesQuestionsData: {
      [key: string]: {
        question: string;
        id: number;
      };
    },
  ):
    | {
        meetingId: string;
        userId: string;
        caseNotes: {
          questionId: number;
          note: string;
        }[];
      }
    | {} => {
    const pureData = {} as { [key: string]: any };
    const modifyValues: Array<keyof Partial<IFollowUpSessionsForm>> = [
      'riskAssessment',
      'primaryIssue',
      'interventionsModality',
      'hasSharedFollowup',
    ];
    Object.entries(data).forEach(([key, val]) => {
      if (modifyValues.includes(key as keyof Partial<IFollowUpSessionsForm>)) {
        const { id } = val as IOptions;
        pureData[key] = id || '';
      } else {
        pureData[key] = key.includes('suicidalAssessment')
          ? Object.keys(val).map((assessment: string) => ({
              // @ts-ignore
              [assessment]: val[assessment],
            }))
          : val;
      }
    });
    // TODO: commented below check to allow empty case note submission because of new PI feature
    if (JSON.stringify(pureData) === '{}') {
      return {};
    }
    if (data.recommendationText?.length) {
      const idx = data?.recommendations?.findIndex(
        ({ value }) => Number(value) === Recommendations.NeedsAdditionalSupport,
      );
      if (idx !== undefined && idx !== -1) {
        pureData.recommendations[idx].additionalSupport =
          data.recommendationText;
      }
    }
    delete pureData.recommendationText;
    delete pureData?.additionalSupport;
    if (
      ![
        RiskAssesment.HighRiskNonCritical.toString(),
        RiskAssesment.HighRiskCritical.toString(),
      ].includes(data.riskAssessment?.value?.toString() as string)
    ) {
      delete pureData?.suicidalAssessment;
    }
    const caseNote = Object.keys(pureData).map((key) => ({
      questionId:
        caseNotesQuestionsData[
          ICaseNotesFollowUpMap[
            key as keyof typeof ICaseNotesFollowUpMap
          ].toString() as string
        ].id,
      note: pureData[key] ? JSON.stringify(pureData[key]) : '',
    }));
    const caseNotePayload = {
      meetingId,
      userId: clientId,
      caseNotes: caseNote,
    };
    return caseNotePayload;
  };

  static getMappedOptionsWithId(val: IOptions[]) {
    const mappedVal: {
      [key: string]: { label: string; value: string; id: string };
    } = {};
    val.forEach(({ label, id, apiValue }) => {
      mappedVal[id] = {
        value: apiValue as string,
        label,
        id,
      };
    });
    return mappedVal;
  }
}

export default FollowUpSessionCaseNotesControls;
