import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../components/Typography';
import infoIcon from '../../../assets/info_red_icon.svg';
import Tags from '../Tags';

function Heading({
  headerText,
  toolTipID,
  headerNode,
  hideTooltipChips = false,
}: {
  headerText?: string;
  toolTipID?: string;
  headerNode?: React.ReactNode;
  hideTooltipChips?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div className={`section-title ${headerText}`}>
      <div>
        {headerNode || (
          <Typography size={14} weight="600">
            {headerText}
          </Typography>
        )}
      </div>
      {!hideTooltipChips && (
        <Tags
          variant="disclaimer"
          tags={t('SHARED_DISCLAIMER')}
          icon={infoIcon}
          uniqueKey={`followup-tooltip-${toolTipID}`}
          toolTipText={t('DISCLAIMER_TEXT')}
          toolTipClass="customTooltip"
          hideTooltip={toolTipID?.includes('visibilityHidden')}
        />
      )}
    </div>
  );
}

export default Heading;
