import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './ChangeLanguagePopup.scss';
import Modal from '../../../v2/view/components/Modal';
import { Button } from '../../../v2/view/components/Button';

interface ChangeLanguagePopupProps {
  onClose: () => void;
  onChangeLanguage: () => void;
  selectedLang: string;
  open: boolean;
}

function ChangeLanguagePopup({
  onClose,
  selectedLang,
  onChangeLanguage,
  open,
}: ChangeLanguagePopupProps) {
  const { t } = useTranslation();
  return (
    <Modal open={open} handleClose={onClose} title={t('changeLanguage')}>
      <div className="text-gray-700 text-md mb-8">
        {t('changeLangConfirmation', { lang: selectedLang })}
      </div>

      <div className="flex gap-3 items-center justify-center">
        <Button variant="secondary" className="w-full" onClick={onClose}>
          {t('no')}
        </Button>
        <Button variant="primary" className="w-full" onClick={onChangeLanguage}>
          {t('yes')}
        </Button>
      </div>
    </Modal>
  );
}

export default ChangeLanguagePopup;
