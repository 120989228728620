import { twMerge } from 'tailwind-merge';

interface SlotSelectListProps {
  selectedValue: string;
  options: {
    label: string;
    value: string;
  }[];
  onClick: (value: string) => void;
  emptyLabel: {
    show: boolean;
    label: string;
  };
}

function SlotSelectList({
  selectedValue,
  options,
  onClick,
  emptyLabel,
}: SlotSelectListProps) {
  if (emptyLabel.show) {
    return <div className="text-gray-600 text-sm">{emptyLabel.label}</div>;
  }

  return (
    <div className="flex items-center gap-2 flex-wrap">
      {options.map(({ label, value }) => (
        <button
          type="button"
          key={value}
          onClick={() => onClick(value)}
          className={twMerge(
            'rounded-lg border border-gray-300 px-3 py-2 text-sm',
            selectedValue === value &&
              'text-primary-600 bg-primary-50 font-semibold',
          )}
        >
          {label}
        </button>
      ))}
    </div>
  );
}

export default SlotSelectList;
