import * as React from 'react';
import './Carousel.scss';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleChevronRight,
  faCircleChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { ICarousel } from './types';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';

function Carousel({
  carouselItem,
  slidesToScroll = 1,
  slidesToShow = 1,
}: ICarousel) {
  const { track } = useTracking();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + slidesToScroll >= carouselItem.length
        ? 0
        : prevIndex + slidesToScroll,
    );
    track(EventNames.client_personal_insights, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.recommendedAssignments,
      eventLabel: 'click_next',
      featureVersion: 'v1',
    });
  };
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - slidesToScroll < 0
        ? carouselItem.length - 1
        : prevIndex - slidesToScroll,
    );
    track(EventNames.client_personal_insights, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.recommendedAssignments,
      eventLabel: 'click_previous',
      featureVersion: 'v1',
    });
  };

  const isRightArrowDisabled = React.useMemo(
    () => currentIndex + slidesToShow >= carouselItem.length,
    [currentIndex, carouselItem],
  );
  return (
    <article className="carousel-wrapper">
      <motion.button
        className="control-item"
        whileHover={{ scale: currentIndex ? 1.1 : 1 }}
        whileTap={{ scale: currentIndex ? 0.9 : 1 }}
        onClick={handlePrevious}
        disabled={!currentIndex}
      >
        <FontAwesomeIcon
          icon={faCircleChevronLeft}
          className={`icons ${!currentIndex && 'disabledIcon'}`}
        />
      </motion.button>
      <section className="carousel-item">
        {carouselItem.slice(currentIndex, currentIndex + slidesToShow)}
      </section>
      <motion.button
        className="control-item"
        whileHover={{ scale: isRightArrowDisabled ? 1 : 1.1 }}
        whileTap={{ scale: isRightArrowDisabled ? 1 : 0.9 }}
        onClick={handleNext}
        disabled={isRightArrowDisabled}
      >
        <FontAwesomeIcon
          icon={faCircleChevronRight}
          className={`icons ${isRightArrowDisabled && 'disabledIcon'}`}
        />
      </motion.button>
    </article>
  );
}

export default Carousel;
