import { IProviderClient, LeaveData } from '../../types/response/provider';

const defaultState = {
  sessions: [],
  selectedClient: {} as IProviderClient,
  clients: [],
  billing: {},
  holidays: [],
  isClientsLoading: false,
};

interface IReducerState {
  sessions: Array<any>;
  selectedClient: IProviderClient;
  clients: Array<any>;
  holidays: Array<LeaveData>;
  isClientsLoading: boolean;
}

const defaultAction = {
  type: '',
  payload: '',
};

type Action = { type: string; payload: any };

export default (
  state: IReducerState = defaultState,
  action: Action = defaultAction,
) => {
  const { type, payload } = action;
  switch (type) {
    case 'ADD_SESSIONS':
      return {
        ...state,
        sessions: payload,
      };

    case 'SET_SELECTED_CLIENT':
      return {
        ...state,
        selectedClient: payload,
      };

    case 'UPDATE_CASE_NOTES':
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          page: payload.page,
          caseNotes: [...state.selectedClient.caseNotes, ...payload.caseNotes],
        },
      };

    case 'ADD_CLIENTS':
      return {
        ...state,
        clients: payload.responseData,
        isClientsLoading: payload.isLoading,
      };

    case 'ADD_BILLING_INFO':
      return {
        ...state,
        billing: payload,
      };

    case 'ADD_HOLIDAYS':
      return {
        ...state,
        holidays: payload,
      };

    default:
      return state;
  }
};
