import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import Modal from '../../../components/Modal';
import { useSessionsList } from '../hooks/UseSessionsList';
import SessionCard from './SessionCard';
import ClientOverviewCardLoader from '../ClientOverviewCardLoader';
import { ClientProfileDateFormat } from '../../../../utilities/common/Date';
import { getDisplayDate } from '../../../../../utilities/common/Date';
import { getCurrentLocale } from '../../../../../i18n';

interface PastSessionsProps {
  open: boolean;
  handleClose: () => void;
  clientId: string;
  role?: string;
}
export default function PastSessionsModal({
  clientId,
  role,
  open,
  handleClose,
}: PastSessionsProps) {
  const { ref, inView } = useInView();
  const { t } = useTranslation();
  const currentLocale = getCurrentLocale();

  const { data, fetchNextPage, isFetchingNextPage } = useSessionsList(
    clientId,
    role,
    2,
  );

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);
  const lastSessionTimeStamp = data?.pages?.[0]?.lastMeeting.scheduledStartTime;
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={t('PAST_SESSIONS')}
      subtitle={
        lastSessionTimeStamp
          ? t('lastSession', {
              date: getDisplayDate({
              locale: currentLocale,
              translatorResource: { ja: 'DISPLAY_DATE_WITH_TIME' },
              format: { ja: 'MM,DD,YYYY,HH,mm', en: ClientProfileDateFormat },
              selectedDate: moment.unix(lastSessionTimeStamp),
              translate:t,
            }) })
          : ''
      }
      className="w-6/12"
    >
      <section className="mt-8 flex flex-col gap-4 overflow-scroll">
        {data?.pages.map((page) =>
          page.data.map((session) => <SessionCard data={session} />),
        )}

        <div ref={ref} className="h-4">
          {isFetchingNextPage ? (
            <ClientOverviewCardLoader variant="card" />
          ) : null}
        </div>
      </section>
    </Modal>
  );
}
