import * as React from 'react';
import { motion } from 'framer-motion';
import useTabs from '../../../utilities/hooks/useTabs';
import './Tab.scss';
import { fade } from '../../../configs/animations/variants';

interface ITab {
  childrenFor: string;
  children: React.ReactNode;
}

function Tab({ childrenFor, children }: ITab) {
  const { selectedTab } = useTabs();
  let renderMarkup = null;
  if (selectedTab.key === childrenFor) {
    renderMarkup = (
      <motion.div
        className="tab-child-container"
        variants={fade}
        initial="out"
        animate="in"
        exit="out"
      >
        {children}
      </motion.div>
    );
  }

  return renderMarkup;
}

export default Tab;
