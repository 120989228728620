import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import queryIcon from '../../../../assets/queryIcon.svg';
import { getCurrentLocale } from '../../../../i18n';
import { IClientSummary } from '../../../../shared/types/response/provider';
import {
  APIDateFormat,
  getDisplayDate,
} from '../../../../utilities/common/Date';
import { displayDateFormat } from '../../../../utilities/common/Display';
import { getProviderRole } from '../../../../utilities/common/Provider';
// import TagsViewer from '../../../../view/app-components/TagsViewer';
import Typography from '../../../../view/components/Typography';
import { encodeCode } from '../../../utilities/common/Encryption';
import Avatar from '../../components/Avatar';
import UserStatusChip from '../ClientDetails/UserStatusChip';
import { toSentenceCaseMultipleWords } from '../../../utilities/common/Display';

const currentLocale = getCurrentLocale();

export const clientsColDefinition: ColumnDef<IClientSummary>[] = [
  {
    header: 'name',
    accessorKey: 'user.friendlyName',
    sortingFn: 'text',
    cell: (props) => {
      const caseID = encodeCode(props.row.original.userId);

      return (
        <div className="flex gap-2 max-w-40">
          <div>
            <Avatar
              clientName={(props.getValue() as string)?.trim() || caseID}
              className="w-8 h-8"
            />
          </div>
          <div className="text-ellipsis overflow-hidden whitespace-nowrap">
            <span>
              {toSentenceCaseMultipleWords(props.getValue() as string)}
            </span>
            <Typography size={10} withColor="#636C73">
              {caseID}
            </Typography>
          </div>
        </div>
      );
    },
  },
  {
    header: 'ORGANISATION',
    accessorKey: 'user.companyName',
    enableSorting: false,
  },
  {
    header: 'type',
    accessorKey: 'providerRole',
    enableSorting: false,
    cell: (props) => {
      const { t } = useTranslation();
      return <span>{t(getProviderRole(props.getValue() as string))}</span>;
    },
  },
  {
    header: 'MATCHED_ON',
    accessorKey: 'createdAt',
    cell: (props) => (
      <span>
        {getDisplayDate({
          locale: currentLocale,
          translatorResource: { ja: 'DISPLAY_DATE_WITHOUT_MENTION' },
          format: { ja: 'MM,DD,YYYY,dddd', en: displayDateFormat },
          selectedDate: moment(props.getValue() as string, APIDateFormat),
        })}
      </span>
    ),
  },
  {
    header: 'lastSessionOn',
    accessorKey: 'lastSessionOn',
    enableSorting: true,
    cell: (props) => (
      <span>
        {props.getValue()
          ? getDisplayDate({
              locale: currentLocale,
              translatorResource: { ja: 'DISPLAY_DATE_WITHOUT_MENTION' },
              format: { ja: 'MM,DD,YYYY,dddd', en: displayDateFormat },
              selectedDate: moment(props.getValue()?.toString(), APIDateFormat),
            })
          : ''}
      </span>
    ),
  },

  {
    header: 'tags',
    accessorKey: 'user.tags',
    enableSorting: false,
    cell: (props) => {
      const tags =
        props
          .getValue()
          ?.toString()
          ?.split(',')
          .filter((item) => item !== '') ?? [];

      const displayedItems = tags?.slice(0, 2);

      const additionalCount = tags?.length > 2 ? tags.length - 2 : 0;

      return displayedItems?.length ? (
        <div className="flex">
          <span className="max-w-32 truncate">
            {displayedItems?.join(', ')}
          </span>
          {additionalCount ? <span> + {additionalCount} more</span> : null}
        </div>
      ) : (
        <span />
      );
    },
  },
  {
    header: () => {
      const { t } = useTranslation();
      return (
        <>
          {t('status')}
          <span>
            <img
              className="cursor-pointer"
              data-tooltip-id="client-management-status-tooltip"
              src={queryIcon}
              alt="question-mark-icon"
              data-tooltip-html={t('STATUS_TOOLTIP_TEXT')}
            />
          </span>
          <ReactTooltip
            id="client-management-status-tooltip"
            place="right"
            data-html
            className="status-tooltip"
          />
        </>
      );
    },
    accessorKey: 'status',
    enableSorting: false,
    cell: (props) => (
      <div>
        <UserStatusChip
          clientStatus={props.getValue() as string}
          className="text-xs w-24 font-medium"
        />
      </div>
    ),
  },
];
