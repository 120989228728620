import * as React from 'react';
import './Collapsible.scss';

interface ICollapsibleProps {
  children: React.ReactNode;
  collapsedMinHeight?: string;
  initial?: 'collapsed' | 'expanded';
}

function Collapsible({
  children,
  initial = 'collapsed',
  collapsedMinHeight = '20',
}: ICollapsibleProps) {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const childRef = React.useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = React.useState(() => initial === 'expanded');
  const [expandedHeight, setExpandedHeight] = React.useState(() =>
    expanded ? parentRef.current?.scrollHeight : collapsedMinHeight,
  );

  const parentHeightResizeObserver = new ResizeObserver((entries) => {
    if (expanded) {
      setExpandedHeight(entries[0].target.scrollHeight);
    }
  });

  React.useEffect(() => {
    setExpandedHeight(parentRef.current?.scrollHeight);
  }, [parentRef.current?.scrollHeight]);

  React.useEffect(() => {
    if (!expanded) {
      setExpandedHeight(collapsedMinHeight);
    } else {
      setExpandedHeight(parentRef.current?.scrollHeight);
    }
  }, [expanded]);

  React.useEffect(() => {
    if (childRef.current) {
      parentHeightResizeObserver?.observe(childRef.current);
    }

    return () => {
      if (childRef.current) {
        parentHeightResizeObserver?.unobserve(childRef.current);
      }
    };
  }, [parentHeightResizeObserver, childRef.current]);

  const toggleCollapse = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <div
      className="collapsible-container"
      ref={parentRef}
      style={{ height: `${expandedHeight}px` }}
    >
      <div
        className="collapsible-controls"
        onClick={toggleCollapse}
        role="button"
        tabIndex={0}
      >
        <span
          className={`control-icon ${
            Number(collapsedMinHeight) > 30 ? 'large' : ''
          } ${expanded ? 'expanded' : ''}`}
        >
          {' '}
        </span>
      </div>
      <div className="collapsible-content-container" ref={childRef}>
        {children}
      </div>
    </div>
  );
}

export default Collapsible;
