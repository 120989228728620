import { ReactComponent as NoDataFoundIcon } from '../../assets/NoDataFound.svg';

interface INoDataFound {
  title: string | JSX.Element;
  description?: string | JSX.Element;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

function NoDataFound({
  title,
  description,
  Icon = NoDataFoundIcon,
}: INoDataFound) {
  return (
    <div className="flex justify-center items-center w-full flex-col gap-2">
      <div>
        <Icon />
      </div>
      <div className="text-displayXs font-semibold text-gray-900">{title}</div>
      {description && (
        <div className="max-w-[70%] text-center text-sm text-gray-500">
          {description}
        </div>
      )}
    </div>
  );
}

export default NoDataFound;
