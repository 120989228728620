export const scrollToBottom = (
  elementRef: React.RefObject<HTMLElement>,
  behavior?: ScrollBehavior,
) =>
  elementRef.current?.scrollTo({
    top: elementRef.current?.scrollHeight,
    behavior,
  });

export const scrollToTop = () => window.scrollTo(0, 0);
