import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './Assignments.scss';
import Tabs from '../../components/Tabs';
import Typography from '../../components/Typography';
import Tab from '../../components/Tab';
import InternalAssignments from '../../app-components/InternalAssignments';
import CustomAssignments from '../../app-components/CustomAssignments';
import { TabItem } from '../../../shared/context/TabsContext';
import Button from '../../components/Button';
import CreateCustomAssignmentPopup from '../../app-components/CreateCustomAssignmentPopup';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../shared/constant/Analytics';

function Assignments() {
  const { t } = useTranslation();
  const { track } = useTracking();
  const assignmentResourceTypes = [
    { key: 'internal', label: t('INTERNAL_RESOURCES') },
    { key: 'external', label: t('EXTERNAL_RESOURCES') },
  ];
  const [, setCurrentTab] = React.useState<TabItem>(assignmentResourceTypes[0]);
  const [showCustomAssignmentCreatePopup, setShowCustomAssignmentCreatePopup] =
    React.useState<boolean>(false);
  const MotionButton = motion(Button);

  const onCustomAssignmentClick = () => {
    setShowCustomAssignmentCreatePopup(true);
    track(EventNames.providerWebCustomAssignment, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.customAssignmentButton,
      eventLabel: 'custom_assignment_button',
    });
  };

  return (
    <div className="h-[100%] w-auto relative m-[36px]">
      {showCustomAssignmentCreatePopup && (
        <CreateCustomAssignmentPopup
          onClose={() => setShowCustomAssignmentCreatePopup(false)}
        />
      )}
      <div className="flex flex-row justify-between items-center gap-1rem mb-1.5rem pr-32 box-border">
        <Typography size={24} weight="500">
          {t('assignments')}
        </Typography>
        <MotionButton
          label={t('CUSTOM_ASSIGNMENT')}
          variant="secondary"
          width="max-content"
          onClick={onCustomAssignmentClick}
        />
      </div>
      <div className="assignment-tabs-sectionw-full pt-14 box-border mb-24">
        <Tabs
          tabs={assignmentResourceTypes}
          headerSectionWidth="40%"
          onTabChange={(newTab) => setCurrentTab(newTab)}
        >
          <Tab childrenFor="internal">
            <InternalAssignments
              onCreateCustomAssignmentClick={() =>
                setShowCustomAssignmentCreatePopup(true)
              }
            />
          </Tab>
          <Tab childrenFor="external">
            <CustomAssignments
              onCreateCustomAssignmentClick={() =>
                setShowCustomAssignmentCreatePopup(true)
              }
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Assignments;
